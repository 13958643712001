import React, { useState } from "react";
import './sidebar.css';
import { useNavigate, Link } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';
import useUserStore from '../../store/useUser.store';
import useSessionStore from '../../store/useSessionStore';
import useUsageStore from '../../store/useUsageStore';
import useChatGPTStore from '../../store/useChatGPT.store';
import useGlobalStore from "../../store/useGlobalStore";
/* assets */
import CloseIcon from '../../assets/icons/close.svg';
import UserIcon from '../../assets/icons/user_icon.svg';
import LogOut from '../../assets/icons/logout.svg';
import History from '../../assets/icons/watch_later.svg';
import Class from '../../assets/icons/class.svg';
import iconArrowDown from '../../assets/icons/CaretDown.svg';
import subLevel from "../../assets/icons/subdirectory_arrow_right.svg";

const Sidebar = ({ isOpen, toggle }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setLang } = useUserStore();
  const { user, setClear } = useSessionStore();
  const { setClear: setClearUseCaseUsageStore } = useUsageStore();
  const { setClear: setClearChatGTPStore } = useChatGPTStore();
  const userString = localStorage.getItem('user');
  const userObject = userString ? JSON.parse(userString) : '';
  const currentUser = user || userObject.first_name;
  const {
    isOpenSidebar,
    setIsOpenSidebar,
    isOpenHistory, 
    setIsOpenHistory,
  } = useGlobalStore();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleClassHistory = () => {
    setIsOpenHistory(!isOpenHistory);
    toggle();
  };

  const closeMenu = () => {
    mixpanel.track("SHAIA_LOGOUT", {
      "email": currentUser?.email,
    },        {
      send_immediately: true,
    },
    () => {
      mixpanel.reset();
    });
    i18n.changeLanguage('es')
    setLang('es')
    localStorage.clear();
    localStorage.setItem('lang','es');
    navigate('/login');
    toggle();
    setClear();
    setClearChatGTPStore()
    setClearUseCaseUsageStore()
  };

  const goToProfile = () => {
    navigate('/profile');
    toggle();
  };

  const blockStyle = {
    transform: isDropdownOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
  };

  const handleLinkClick = () => {
    if (isOpenSidebar) {
      setIsOpenSidebar(!isOpenSidebar);
    }
  };

  return (
    <>
      <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggle}></div>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        {/* Contenido del sidebar aquí */}
        <span onClick={toggle}><img src={CloseIcon} className='cerrar' alt='Cerrar' /></span>
        <ul className='sidebar-menu h-full'>
          <li onClick={goToProfile}>
            <span className='user-firstLetter'><img src={UserIcon} alt='' /></span>
            {t('my_profile')}
          </li>
          <li onClick={toggleClassHistory}>
            <span className='user-firstLetter'><img src={History} alt='' /></span>
            {t('historialTitulo')}
          </li>
          {/* Elemento desplegable */}
          <li className="dropdown" onClick={toggleDropdown}>
            <div className="w-full flex flex-row items-center justify-start gap-4">
              <span className='user-firstLetter'><img src={Class} alt="empty" className="max-w-full" /></span>
              <p className="text-4 leading-4 text-Grey-500 font-semibold m-0">Perfiles de curso</p>
              <span className='user-arrow ml-auto' style={blockStyle}>
                <img src={iconArrowDown} alt='' />
              </span>
            </div>
            {!isDropdownOpen && (
              <ul className="w-full pl-6"> 
                <li>
                  <Link
                    to={"/course-profile"}
                    onClick={handleLinkClick}
                    className="rounded-1 py-1 hover:bg-LightBlue-300/20 text-3.5 leading-3.5 text-Grey-500/50 font-semibold m-0 flex flex-row items-center justify-start gap-1"
                  >
                    <span className="w-6 h-6 opacity-50">
                      <img
                        src={subLevel}
                        alt="empty"
                        className="max-w-full"
                      />
                    </span>
                    Ver mis cursos
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/course-profile?create"}
                    onClick={handleLinkClick}
                    className="rounded-1 py-1 hover:bg-LightBlue-300/20 text-3.5 leading-3.5 text-Grey-500/50 font-semibold m-0 flex flex-row items-center justify-start gap-1"
                  >
                    <span className="w-6 h-6 opacity-50">
                      <img
                        src={subLevel}
                        alt="empty"
                        className="max-w-full"
                      />
                    </span>
                    Crear un curso
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
        <ul className='sidebar-menu !pt-4 self-end'>
          <li onClick={closeMenu}>
            <span className='user-firstLetter'><img src={LogOut} alt='' /></span>
            {t('logout')}
          </li>
        </ul>        
      </div>
    </>
  );
};

export default Sidebar;
