import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { InitMixPanel } from "./utils/MixPanel";
import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import enTranslation from "./config/i18n/en/translation.json";
import esTranslation from "./config/i18n/es/translation.json";
import esPeTranslation from "./config/i18n/es-pe/translation.json";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SENTRY_DNS } from './utils/constans';

if(process.env.REACT_APP_STAGE === "production" || process.env.REACT_APP_STAGE === "test"){
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0, // Ajusta esta tasa según sea necesario,
    environment: process.env.REACT_APP_STAGE
  });
}

const resources = {
  "es_pe": {
    translation: esPeTranslation,
  },
  es: {
    translation: esTranslation,
  },
  en: {
    translation: enTranslation,
  },
};


i18n.use(initReactI18next).init({
  resources,
  debug: true,
  fallbackLng: 'es',
  lng: 'es',
  interpolation: {
    escapeValue: false,
  },
});

const queryClient = new QueryClient();
InitMixPanel();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <App />
    </Router>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
