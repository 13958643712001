import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import DesignCESAClass from '../screens/custom_superpowers/cesa/designCESAClass';

const Home = lazy(() => import('../screens/home'));
const Coaching = lazy(() => import('../screens/coaching'));
const LoginPage = lazy(() => import('../screens/login'));
const Register = lazy(() => import('../screens/register'));
const DesignActivityBoard = lazy(() => import('../screens/designActivityBoard'));
const DesignClassBoard = lazy(() => import('../screens/designClassBoard'));
const DesignAssessBoard = lazy(() => import('../screens/designAssessBoard'));
const Chart = lazy(() => import('../screens/chart'));
const Profile = lazy(() => import('../screens/profile'));
const RecoveryPassword = lazy(() => import('../screens/recoveryPassword'));
const ChangePassword = lazy(() => import('../screens/changePassword'));
const DesignABP = lazy(() => import('../screens/designABP'));
const InteractionABP = lazy(() => import('../screens/interactionABP'));
const EditingABPBoard = lazy(() => import('../screens/editingABPBoard'));
const NotionPrinciplesPage = lazy(() => import('../screens/principles'));
const GoogleRegister = lazy(() => import('../screens/googleRegister'));
const ConfirmEmail = lazy(() => import('../screens/confirmEmail'));
const RegisterHubspot = lazy(() => import('../screens/registerHubspot'));
const DesignRubricBoard = lazy(() => import('../screens/designRubricBoard'));
const DesignContextBoard = lazy(() => import('../screens/designContextBoard'));
const LoginPageInstitutional = lazy(() => import('../screens/loginInst'));
const DesignWarmUpBoard = lazy(() => import('../screens/designWarmUpBoard'));
const Admin = lazy(() => import('../screens/admin'));
const Institution = lazy(() => import('../screens/admin/institution'));
const SuperPower = lazy(() => import('../screens/admin/superPower'));
const CourseProfile = lazy(() => import('../screens/courseProfiles'));
const DesignBloomPerformanceBoard = lazy(() => import('../screens/designBloomPerformanceBoard'));
const ClassProfile = lazy(() => import('../screens/admin/classProfile'));
const DesignEDAS = lazy(() => import('../screens/custom_superpowers/ensena_peru/designEDAS'));
const InteractionEDAS = lazy(() => import('../screens/custom_superpowers/ensena_peru/interactionEDAS'));
const DesignCL = lazy(() => import('../screens/custom_superpowers/ensena_peru/designCL'));
const EditingEDASBoard = lazy(() => import('../screens/custom_superpowers/ensena_peru/editingEDASBoard'));
const DesignCriticalThinkingBoard = lazy(() => import('../screens/designCriticalThinkingBoard'));
//CESA
const DesignClassSequence = lazy(() => import('../screens/custom_superpowers/cesa/designClassSequence'));
const ChatForm = lazy(() => import('../screens/custom_superpowers/cesa/Class_Sequence/chatForm'));
const Summary = lazy(() => import('../screens/custom_superpowers/cesa/Class_Sequence/summary'));
const Syllabus = lazy(() => import('../screens/custom_superpowers/cesa/syllabus'));

const Loading = () => <div></div>;

function AppRouter() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/admin" exact element={<Admin />}>
        <Route path="/admin/super-power" element={<SuperPower />} />
        <Route path="/admin/institution" element={<Institution />} />
        <Route path="/admin/class-profile" element={<ClassProfile />} />
      </Route>
      <Route path="/confirmation" exact element={<ConfirmEmail />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/onboarding-teacher" element={<LoginPageInstitutional />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register-google" element={<GoogleRegister />} />
      <Route path="/register-hubspot" element={<RegisterHubspot />} />
      <Route path="/recovery-password" element={<RecoveryPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/chart" element={<Chart />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/design-activity" element={<DesignActivityBoard />} />
      <Route path="/design-class" element={<DesignClassBoard />} />
      <Route path="/design-assess" element={<DesignAssessBoard />} />
      <Route path="/design-rubric" element={<DesignRubricBoard />} />
      <Route path="/design-context" element={<DesignContextBoard />} />
      <Route path="/design-startup-dynamic" element={<DesignWarmUpBoard />} />
      <Route
        path="/design-performances-and-evidence-bloom"
        element={<DesignBloomPerformanceBoard />}
      />
      <Route path="/design-critical-thinking" element={<DesignCriticalThinkingBoard />} />
      <Route path="/editing-abp-board" element={<EditingABPBoard />} />
      <Route path="/shaia-coaching" element={<Coaching />} />
      <Route path="/design-abp" element={<DesignABP />} />
      <Route path="/interaction-abp" element={<InteractionABP />} />
      <Route path="/principles" element={<NotionPrinciplesPage />} />
      <Route path='/course-profile' element={<CourseProfile />} />
      {/* Custom tools Emseña Perú */}
      <Route path="/design-reading-comprehension" element={<DesignCL />} />
      <Route path="/design-edas" element={<DesignEDAS />} />
      <Route path="/editing-edas-board" element={<EditingEDASBoard />} />
      <Route path="/interaction-edas" element={<InteractionEDAS />} />
      {/* Custom tools CESA */}
      <Route path='/design-class-sequence' element={<DesignClassSequence />}>
        <Route index element={<Navigate to="chat" replace />} />
        <Route path='chat' element={<ChatForm />} />
      <Route path='summary' element={<Summary />} />
      </Route>
      <Route path='/design-class-cesa' element={<DesignCESAClass />} />
      <Route path='/design-syllabus' element={<Syllabus />} />
    </Routes>
    </Suspense>
    
  );
}

export default AppRouter;
