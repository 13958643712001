import { useState, useEffect, useRef } from 'react';

function useTimer(initialCount = 0) {
    const [count, setCount] = useState(initialCount);
    const [isActive, setIsActive] = useState(false);
    const frameIdRef = useRef(null);
    const lastTimeRef = useRef(null);

    const tick = (timestamp) => {
        if (!lastTimeRef.current) {
            lastTimeRef.current = timestamp;
        }
        const deltaTime = timestamp - lastTimeRef.current;
        
        if (deltaTime >= 1000) {
            setCount(prevCount => prevCount + 1);
            lastTimeRef.current = timestamp;
        }
        
        frameIdRef.current = requestAnimationFrame(tick);
    };

    const startTimer = () => {
        if (!isActive) {
            setIsActive(true);
            frameIdRef.current = requestAnimationFrame(tick);
        }
    };

    const pauseTimer = () => {
        if (isActive) {
            cancelAnimationFrame(frameIdRef.current);
            setIsActive(false);
            lastTimeRef.current = null;
        }
    };

    const resetTimer = () => {
        cancelAnimationFrame(frameIdRef.current);
        setIsActive(false);
        setCount(0);
        lastTimeRef.current = null;
    };

    useEffect(() => {
        return () => {
            if (frameIdRef.current) {
                cancelAnimationFrame(frameIdRef.current);
            }
        };
    }, []);

    return { count, startTimer, pauseTimer, resetTimer };
}

export default useTimer