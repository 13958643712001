import 
// client,
{ 
  pythonClient,
  openAiClient
} 
from '../config/axios';
export const fetchChatGPT = async (params) => {
    let teacher_id = null;
    const localStorageUser = localStorage.getItem("user");
    if (localStorageUser) {
      const parsedUser = JSON.parse(localStorageUser);
      teacher_id = parsedUser.id;
    }
    const data = { ...params, teacher_id };

    const response = await pythonClient.post('/chatgpt', { ...data  });
    return response.data;
  }

  export const fetchAssistantChatGPT = async (params) => {
    let teacher_id = null;
    const localStorageUser = localStorage.getItem("user");
    if (localStorageUser) {
      const parsedUser = JSON.parse(localStorageUser);
      teacher_id = parsedUser.id;
    }
    const data = { ...params, teacher_id };

    const response = await openAiClient.post('/interactions', { ...data  });
    return response.data;
  }
  
  