import React, { useState, useEffect } from 'react';
import './snackbar.css';

function Snackbar({ message, duration = 6000, onClose, type = 'error' }) {
    const [visible, setVisible] = useState(true);

    const icons = {
        success: '✓',
        error: '✗'
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setVisible(false);
            if (onClose) onClose();
        }, duration);

        return () => clearTimeout(timeout);
    }, [duration, onClose]);

    if (!visible) return null;

    return (
        <div className={`snackbar ${type}`}>
            <span className="snackbar-icon">{icons[type]}</span>
            <span className="snackbar-message">{message}</span>
            <span className="snackbar-close" onClick={() => { onClose ? onClose() : setVisible(false)}}>X</span>
        </div>
    );
}

export default Snackbar;




