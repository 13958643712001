// eslint-disable-next-line
import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import {
  getAvailableAssetsByUser,
  getClassProfileById,
  storeClassProfile,
  updateClassProfile,
} from "../../services/profile-class.service";
/* Components */
import Button from "../button/button";
import { useMutation } from "react-query";
import { useMixPanel } from "../../hooks/useMixPanel";
import { useTranslation } from "react-i18next";
import useGlobalStore from "../../store/useGlobalStore";
import { FormProvider, useForm, Controller } from "react-hook-form";
import TextInput2 from "../form-elements/TexInput2/TextInput2";
import TextArea2 from "../form-elements/TextArea2/textArea2";
import { MIX_PANEL_EVENTS } from "../../utils/constans";
import AsyncSelect from "react-select/async";
import FileUploader from "../fileUploader/fileUploader";
import Popup from "../popup/popup";
import {
  createGradeLevelSingle,
  getAllGradeLevelsByUser,
} from "../../services/grade-levels.service";
import { getAllAcademicPeriod } from "../../services/academic-period.service";
import {
  createSubjectAreaSingle,
  getAllSubjectAreasByAreas,
  getAllSubjectAreasByInstitution,
  getAllSubjectAreasByUser,
} from "../../services/subject-area.service";
import { getInstitutionsByName } from "../../services/institution";
import SelectInput from "../form-elements/SelectInput/SelectInput";
import UploadFile from "../../assets/icons/upload_file.svg";
import Trash from "../../assets/icons/trash.svg";
import { useLocation } from "react-router-dom";
import useSnackbarStore from "../../store/snackbarStore";
import useSessionStore from "../../store/useSessionStore";
import Modal3 from "../modal3/modal3";
import DaySelector from "../form-elements/daySelector/daySelector";
import { getAllAreas } from "../../services/area.service";

const SelectStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: "rgb(182 182 182 / 0.25)",
    borderColor: "transparent",
    borderRadius: "4px",
    caretColor: "white",
    "&:hover": { borderColor: "transparent" }, // Borde en hover
  }),
  input: (provided) => ({
    ...provided,
    color: "#F7F5F2",
    fontWeight: "bold", // Establece el color del texto del input a blanco
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F2F2F2" : "#FEFEFE",
    color: "black",
    cursor: "pointer",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#FEFEFE", // Color del indicador
    "&:hover": {
      color: "#3FB2E3", // Color del indicador en hover
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fefefe", // Establece el color del texto de la opción seleccionada a blanco
    fontWeight: "bold",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "black", // Aquí puedes poner el color que desees
    ":hover": {
      color: "black", // Color cuando se pasa el ratón por encima (hover)
    },
  }),
};

const FormCourseProfile = ({ selectedRow, reloadList = null }) => {
  const [selectedDaysData, setSelectedDayData] = useState([]);
  const { sendEvent } = useMixPanel();
  const { t, i18n } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);

  const { gptLoading } = useGlobalStore();

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = methods;
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [subjectAreaOptions, setSubjectAreaOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [availableAssets, setAvailableAssets] = useState([]);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [subjectAreas, setSubjectAreas] = useState([]);
  const [academicPeriods, setAcademicPeriods] = useState([]);
  const [areas, setAreas] = useState([]);
  const [showAreas, setShowAreas] = useState(false);
  const [showCreateSubjectAreaButton, setShowreateSubjectAreaButton] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [, setInstitutions] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [isCesa, setIsCesa] = useState(false);
  const [errorCalendar, setErrorCalendar] = useState(false);
  const location = useLocation();
  const userString = localStorage.getItem("user");
  const userObject = userString ? JSON.parse(userString) : "";
  const editParamId = new URLSearchParams(location.search).get("edit");
  const queryParams = new URLSearchParams(location.search);
  const level = queryParams.get("level");
  const { show } = useSnackbarStore();
  const { user } = useSessionStore();
  const [isNewSubjectModalOpen, setIsNewSubjectModalOpen] = useState(false);
  const [isNewGradeLevelModalOpen, setIsNewGradeLevelModalOpen] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [, setDebouncedSearchTerm] = useState("");
  const selectedInstitution = watch("institution");
  const selectedArea = watch("area");
  const selectedGradeLevel = watch("gradeLevels");
  const cesaReferences = ["Pregrado", "Posgrado"];

  useEffect(() => {
    if (isCesa && selected && areas.length) {
      const currentArea = areas.find((e) => {
        const find = selected.subjectAreas.find((sa) => sa?.area?.id === e.id);
        return !!find;
      });
      if (currentArea) {
        setValue("area", {
          value: currentArea.id,
          label: currentArea.description,
        });
        const subjecAreasMapper = selected.subjectAreas.map((e) => ({
          label: e.name,
          value: e.id,
        }));
        setValue("subjectAreas", subjecAreasMapper);
      }
    }

    if (isCesa && selected && !areas.length && subjectAreas.length) {
      const subjecAreasMapper = selected.subjectAreas.map((e) => ({
        label: e.name,
        value: e.id,
      }));
      setValue("subjectAreas", subjecAreasMapper);
    }
    // eslint-disable-next-line
  }, [selected, areas, isCesa, subjectAreas]);

  useEffect(() => {
    if (
      isCesa &&
      selectedGradeLevel &&
      selectedGradeLevel.label === "Pregrado" &&
      user
    ) {
      setShowAreas(true);
      setValue("subjectAreas", []);
      mutationGeAreas.mutate();
      mutationGetSubjectAreasByAreas.mutate(user.email);
      if (academicPeriods.length) {
        const filteredAcademiceriod = academicPeriods.map((e) => ({
          ...e,
          show: e.level === "Terciaria o superior",
        }));
        setAcademicPeriods(filteredAcademiceriod);
      }
    } else if (
      isCesa &&
      selectedGradeLevel &&
      selectedGradeLevel.label === "Posgrado" &&
      user
    ) {
      setShowAreas(false);
      setValue("subjectAreas", []);
      mutationGetSubjectAreasByInstitution.mutate(
        user?.institutionalUser?.subscription?.institution?.id
      );
    } else {
      if (user && isCesa) {
        mutationGetAcademicPeriods.mutate();
        mutationGetSubjectAreas.mutate(user.email);
      }
    }
    const getShowCreatSubjectArea = getshowCreateSubjectAreaButton();
    setShowreateSubjectAreaButton(getShowCreatSubjectArea);
    // eslint-disable-next-line
  }, [selectedGradeLevel, user, isCesa]);

  useEffect(() => {
    if (isCesa && selectedArea) {
      // setValue("subjectAreas", "");
    }
  }, [selectedArea, isCesa]);

  // Actualiza debouncedSearchTerm después de que el usuario deja de escribir
  useEffect(() => {
    // Crea una función debounced que actualiza debouncedSearchTerm
    const debouncedUpdate = debounce((search) => {
      setDebouncedSearchTerm(search);
    }, 500); // 500 ms de espera

    if (searchTerm) {
      debouncedUpdate(searchTerm);
    }

    // Limpia el debounce si el componente se desmonta o el searchTerm cambia
    return () => debouncedUpdate.cancel();
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    if (user && user?.institutionalUser?.subscription?.institution) {
      getInstitutionsByName(
        user.institutionalUser.subscription.institution.name
      ).then((instRes) => {
        const findInstitution = instRes.find(
          (e) => e.name === user.institutionalUser.subscription.institution.name
        );
        if (findInstitution) {
          setValue("institution", {
            label: findInstitution.name,
            value: findInstitution.id,
            grade_level: findInstitution.grade_level,
          });
        }
      });
    }
    mutationGetAcademicPeriods.mutate();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    sendEvent(MIX_PANEL_EVENTS.contextUseCaseView);
    // eslint-disable-next-line
  }, []);

  const toggleNewSubjectModal = (e) => {
    e.preventDefault();
    setIsNewSubjectModalOpen(!isNewSubjectModalOpen);
    if (!isNewSubjectModalOpen) {
      setValue("new_name_subject", "");
    }
  };

  const getshowCreateSubjectAreaButton = () => {
    if (isCesa) {
      return false;
    }
    return true;
  };

  const toggleNewGradeLevelModal = (e) => {
    e.preventDefault();
    setIsNewGradeLevelModalOpen(!isNewGradeLevelModalOpen);
    if (!isNewGradeLevelModalOpen) {
      setValue("new_name_grade", "");
    }
  };

  // Funciones para manejar la creación de una nueva materia o grado
  const handleNewRegister = (event, type, field) => {
    const newName = getValues(field);
    event.preventDefault();

    const payload = {
      name: newName,
      user_email: user?.email,
      lang: i18n.language,
    };

    if (type === "grade") {
      mutationCreateGradeLevel.mutate(payload);
    }
    if (type === "subject-area") {
      mutationCreateSubjectArea.mutate(payload);
    }
  };

  const onSubmit = (formData) => {
    const formDataToSend = new FormData();

    if (showCalendar() && !formData?.calendario?.length) {
      setErrorCalendar(true);
      return;
    }
    setErrorCalendar(false);
    formDataToSend.append("user_email", userObject?.email);

    if (formData?.class_name) {
      formDataToSend.append("class_name", formData.class_name);
    }

    if (formData?.existing_asset) {
      formDataToSend.append("existing_asset", formData.existing_asset);
    }

    if (formData?.number_students) {
      formDataToSend.append("number_students", formData.number_students);
    }

    if (formData?.student_ages) {
      formDataToSend.append("student_ages", formData.student_ages);
    }

    if (formData?.session_number) {
      formDataToSend.append("session_number", formData.session_number);
    }

    if (formData?.special_considerations) {
      formDataToSend.append(
        "special_considerations",
        formData.special_considerations
      );
    }

    if (formData?.gradeLevels) {
      formDataToSend.append(
        "grade_level_ids",
        JSON.stringify([formData.gradeLevels.value])
      );
    }

    if (formData?.subjectAreas?.length) {
      formDataToSend.append(
        "subject_area_ids",
        JSON.stringify(formData.subjectAreas.map((e) => e.value))
      );
    }

    if (formData?.academicPeriod) {
      formDataToSend.append(
        "academic_period_id",
        Number(formData.academicPeriod.value)
      );
    }

    if (selectedResources.length) {
      selectedResources.forEach((element, i) => {
        if (element instanceof File) {
          formDataToSend.append(`resources[${i}]`, element);
        }
      });
    }

    if (formData?.institution && Object.values(formData?.institution)?.length) {
      formDataToSend.append("institution_id", formData.institution.value);
    }

    if (formData?.calendario?.length) {
      formDataToSend.append("calendar", JSON.stringify(formData.calendario));
    }

    if (filesToDelete.length) {
      formDataToSend.append("assets_to_delete", JSON.stringify(filesToDelete));
    }

    setLoading(true);
    const body = formDataToSend;
    if (selected) {
      mutationUpdateClassProfile.mutate({
        id: selected?.id,
        body,
      });
    } else {
      mutationStoreClassProfile.mutate(body);
    }
  };

  const filterGradeLevelOptions = (inputValue) => {
    return gradeLevelOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filterSubjectAreaOptions = (inputValue) => {
    return subjectAreaOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadGradeLevelOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterGradeLevelOptions(inputValue));
    }, 0);
  };

  const loadSubjectAreaOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterSubjectAreaOptions(inputValue));
    }, 0);
  };

  // eslint-disable-next-line
  const debouncedLoadOptions = useCallback(
    debounce((inputValue, callback) => {
      if (!inputValue) {
        callback([]);
        return;
      }

      // useCallback para memorizar la función debounced
      getInstitutionsByName(inputValue).then((institutions) => {
        const options = institutions.map((institution) => ({
          label: institution.name,
          value: institution.id,
          grade_level: institution.grade_level,
        }));
        if (options.length === 0) {
          // Si no hay opciones, muestra el modal
          setValue("newInstitution", inputValue);
        } else {
          setValue("newInstitution", "");
          // Si hay opciones, asegúrate de que el modal no se muestre
        }
        callback(options);
      });
    }, 1000),
    []
  );

  const mutationGetAvailableAssets = useMutation(getAvailableAssetsByUser, {
    onSuccess: (res) => {
      const parsedAssets = res.reduce((acc, assetItem) => {
        if (selected?.assets?.length) {
          const currentClassProfileAssets = selected?.assets.some(
            (e) => e.id === assetItem.id
          );
          if (!currentClassProfileAssets) {
            return [...acc, { ...assetItem }];
          }
          return acc;
        }
        return [...acc, { ...assetItem }];
      }, []);
      setAvailableAssets(parsedAssets);
    },
    onError: () => {},
  });

  const mutationGetAcademicPeriods = useMutation(getAllAcademicPeriod, {
    onSuccess: (res) => {
      setAcademicPeriods(res.map((e) => ({ ...e, show: true })));
    },
    onError: () => {},
  });

  const mutationStoreClassProfile = useMutation(storeClassProfile, {
    onSuccess: (res) => {
      const item = res;
      Object.keys(item).forEach((key) => {
        if (key === "subjectAreas" && Array.isArray(item[key])) {
          setValue(
            "subjectAreas",
            item[key].map((sa) => ({ label: sa.name, value: sa.id }))
          );
        } else if (key === "gradeLevels" && Array.isArray(item[key])) {
          const currentGradeLevel = item[key].length
            ? { label: item[key][0].name, value: item[key][0].id }
            : null;
          setValue("gradeLevels", currentGradeLevel);
        } else if (key === "institution") {
          getInstitutionsByName(item[key].name).then((res) => {
            setInstitutions(res);
            const findInstitution = res.find((e) => e.id === item[key].id);
            if (findInstitution) {
              setValue("institution", {
                label: findInstitution.name,
                value: findInstitution.id,
              });
            }
          });
        } else if (key === "classProfileCalendars") {
          // Establece academicPeriod si existe en el formato correcto

          setSelectedDayData(item[key]);
        } else if (key === "academicPeriod") {
          // Establece academicPeriod si existe en el formato correcto

          setValue("academicPeriod", {
            value: item[key].id,
            label: item[key].name,
          });
        } else {
          // Para todos los demás valores del formulario
          setValue(key, item[key]);
        }
      });
      show("Perfil Creado");
      setSelected(item);

      setLoading(false);
      reloadList && reloadList();
    },
    onError: () => {
      setLoading(false);
    },
  });

  const mutationUpdateClassProfile = useMutation(updateClassProfile, {
    onSuccess: (data) => {
      const item = data;
      Object.keys(item).forEach((key) => {
        if (key === "subjectAreas" && Array.isArray(item[key])) {
          // Transforma y establece subjectAreas para AsyncSelect en formato { label, value }
          setValue(
            "subjectAreas",
            item[key].map((sa) => ({ label: sa.name, value: sa.id }))
          );
        } else if (key === "gradeLevels" && Array.isArray(item[key])) {
          const currentGradeLevel = item[key].length
            ? { label: item[key][0].name, value: item[key][0].id }
            : null;
          setValue("gradeLevels", currentGradeLevel);
        } else if (key === "institution") {
          getInstitutionsByName(item[key].name).then((res) => {
            setInstitutions(res);
            const findInstitution = res.find((e) => e.id === item[key].id);
            if (findInstitution) {
              setValue("institution", {
                label: findInstitution.name,
                value: findInstitution.id,
              });
            }
          });
        } else if (key === "classProfileCalendars") {
          // Establece academicPeriod si existe en el formato correcto

          setSelectedDayData(item[key]);
        } else if (key === "academicPeriod") {
          // Establece academicPeriod si existe en el formato correcto
          setValue("academicPeriod", {
            value: item[key].id,
            label: item[key].name,
          });
        } else {
          // Para todos los demás valores del formulario
          setValue(key, item[key]);
        }
      });
      show("Perfil Actualizado");
      setSelected(item);
      setLoading(false);
      reloadList && reloadList();
    },
    onError: (error) => {},
  });

  const mutationClassProfileById = useMutation(getClassProfileById, {
    onSuccess: (data) => {
      const item = data;
      Object.keys(item).forEach((key) => {
        if (key === "subjectAreas" && Array.isArray(item[key])) {
          // Transforma y establece subjectAreas para AsyncSelect en formato { label, value }
          setValue(
            "subjectAreas",
            item[key].map((sa) => ({ label: sa.name, value: sa.id }))
          );
        } else if (key === "gradeLevels" && Array.isArray(item[key])) {
          // Transforma y establece gradeLevels para AsyncSelect en formato { label, value }
          const currentGradeLevel = item[key].length
            ? { label: item[key][0].name, value: item[key][0].id }
            : null;
          setValue("gradeLevels", currentGradeLevel);
        } else if (key === "institution") {
          getInstitutionsByName(item[key].name).then((res) => {
            setInstitutions(res);
            const findInstitution = res.find((e) => e.id === item[key].id);
            if (findInstitution) {
              setValue("institution", {
                label: findInstitution.name,
                value: findInstitution.id,
              });
            }
          });
        } else if (key === "classProfileCalendars") {
          // Establece academicPeriod si existe en el formato correcto
          setSelectedDayData(item[key]);
        } else if (key === "academicPeriod") {
          // Establece academicPeriod si existe en el formato correcto
          setValue("academicPeriod", {
            value: item[key].id,
            label: item[key].name,
          });
        } else {
          // Para todos los demás valores del formulario
          setValue(key, item[key]);
        }
      });
      setSelected(item);
      setLoading(false);
    },
    onError: (error) => {},
  });

  const mutationCreateGradeLevel = useMutation(createGradeLevelSingle, {
    onSuccess: (newGradelevel) => {
      setValue("gradeLevels", {
        label: newGradelevel.name,
        value: newGradelevel.id,
      });
      mutationGetGrades.mutate(user.email);
      setIsNewGradeLevelModalOpen(false);
    },
    onError: (error) => {},
  });

  const mutationCreateSubjectArea = useMutation(createSubjectAreaSingle, {
    onSuccess: (newSubjectArea) => {
      const subjectAreas = getValues("subjectAreas");
      const list = subjectAreas?.length ? subjectAreas : [];
      list.push({
        label: newSubjectArea.name,
        value: newSubjectArea.id,
      });
      setValue("subjectAreas", list);
      mutationGetSubjectAreas.mutate(user.email);
      setIsNewSubjectModalOpen(false);
    },
    onError: (error) => {},
  });

  const mutationGetGrades = useMutation(getAllGradeLevelsByUser, {
    onSuccess: (res) => {
      setGradeLevels(res);
    },
    onError: (error) => {},
  });

  const mutationGetSubjectAreas = useMutation(getAllSubjectAreasByUser, {
    onSuccess: (res) => {
      setSubjectAreas(res);
    },
    onError: (error) => {},
  });

  const mutationGetSubjectAreasByAreas = useMutation(
    getAllSubjectAreasByAreas,
    {
      onSuccess: (res) => {
        setSubjectAreas(res);
      },
      onError: (error) => {},
    }
  );

  const mutationGetSubjectAreasByInstitution = useMutation(
    getAllSubjectAreasByInstitution,
    {
      onSuccess: (res) => {
        setSubjectAreas(res);
      },
      onError: (error) => {},
    }
  );

  const mutationGeAreas = useMutation(getAllAreas, {
    onSuccess: (res) => {
      setAreas(res);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (gradeLevels.length && selectedInstitution) {
      const transformed = gradeLevels
        .filter((gradeItem) => {
          if (isCesa) {
            return (
              gradeItem.is_default && cesaReferences.includes(gradeItem.name)
            );
          }
          return gradeItem.level === selectedInstitution.grade_level;
        })
        .map((level) => ({
          value: level.id,
          label: level.name,
        }));
      setGradeLevelOptions(transformed);
      if(level === 'pregrado'){
        const findCurrentGrade = transformed.find(e => e.label === 'Pregrado');
        if(findCurrentGrade){
          setValue("gradeLevels ", findCurrentGrade);
        }
      }
      if(level === 'posgrado'){
        const findCurrentGrade = transformed.find(e => e.label === 'Posgrado');
        if(findCurrentGrade){
          setValue("gradeLevels", findCurrentGrade);
        }
      }
    }
    // eslint-disable-next-line
  }, [gradeLevels, selectedInstitution, isCesa, level]);

  useEffect(() => {
    if (selectedRow) {
      Object.keys(selectedRow).forEach((key) => {
        if (key === "subjectAreas" && Array.isArray(selectedRow[key])) {
          // Transforma y establece subjectAreas para AsyncSelect en formato { label, value }
          setValue(
            "subjectAreas",
            selectedRow[key].map((sa) => ({ label: sa.name, value: sa.id }))
          );
        } else if (key === "gradeLevels" && Array.isArray(selectedRow[key])) {
          // Transforma y establece gradeLevels para AsyncSelect en formato { label, value }
          const currentGradeLevel = selectedRow[key].length
            ? { label: selectedRow[key][0].name, value: selectedRow[key][0].id }
            : null;
          setValue("gradeLevels", currentGradeLevel);
        } else if (key === "academicPeriod" && selectedRow[key]?.id) {
          // Establece academicPeriod si existe en el formato correcto
          setValue("academicPeriod", {
            value: selectedRow[key].id,
            label: selectedRow[key].name,
          });
        } else {
          // Para todos los demás valores del formulario
          setValue(key, selectedRow[key]);
        }
      });
      setSelected({ ...selectedRow, id: selectedRow.classId });
    }
    // eslint-disable-next-line
  }, [selectedRow]);

  const showSubjectAreaDataByInsitution = (sa, isInstitution) => {
    if (isInstitution) {
      if (sa?.institution?.id === user.institutionalUser?.subscription?.institution?.id) return true;
      if (selectedArea && sa?.area?.id === selectedArea.value) return true;
      if (sa.person?.email === user?.email) return true;
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (subjectAreas.length && user?.institutionalUser) {
      const isCesaInst =
        user.institutionalUser.subscription.institution.name ===
        "CESA - Colegio de Estudios Superiores de Administración";
      setIsCesa(isCesaInst);
      const transformed = subjectAreas.map((sa) => ({
        value: sa.id,
        label: sa.name,
        show: showSubjectAreaDataByInsitution(sa, isCesaInst),
      }));

      setSubjectAreaOptions(transformed);
    }

    // eslint-disable-next-line
  }, [subjectAreas, user, selectedArea]);

  useEffect(() => {
    if (editParamId) {
      mutationClassProfileById.mutate({ id: editParamId });
    } else {
      setSelectedResources([]);
      setSelected(null);
      reset();
    }
    // eslint-disable-next-line
  }, [editParamId]);

  useEffect(() => {
    if (!editParamId && academicPeriods && academicPeriods.length) {
      const findDefaultAcademicPeriod = academicPeriods.find(
        (_, index) => index === 0
      );
      let defaultAcademiPeriod = findDefaultAcademicPeriod.id;
      if (isCesa) {
        const findDefaultAcademicPeriodCESA = academicPeriods.find(
          (e) => e.name === "2024 Semestre 2"
        );
        if (findDefaultAcademicPeriodCESA)
          defaultAcademiPeriod = {
            value: findDefaultAcademicPeriodCESA.id,
            label: findDefaultAcademicPeriodCESA.name,
          };
      }
      setValue("academicPeriod", defaultAcademiPeriod);
    }
    // eslint-disable-next-line
  }, [editParamId, academicPeriods, isCesa]);

  useEffect(() => {
    if (user) {
      if (!availableAssets.length) {
        mutationGetAvailableAssets.mutate({ email: user.email });
      }
      mutationGetGrades.mutate(user.email);
      mutationGetSubjectAreas.mutate(user.email);
    }
    // eslint-disable-next-line
  }, [user]);

  const togglePopup = (e) => {
    e.preventDefault();
    setIsPopupOpen(!isPopupOpen);
  };

  const handleFiles = (files) => {
    setSelectedResources(files);
    const mapperAssets = files.map((e) => ({
      ...e,
      name: e.name,
      local: true,
    }));
    if (files.length && selected) {
      setSelected({
        ...selected,
        assets: [...selected.assets, ...mapperAssets],
      });
    } else {
      setSelected({ assets: mapperAssets });
    }
  };

  // eslint-disable-next-line
  const loadInstitutionOptions = (inputValue, callback) => {
    debouncedLoadOptions(inputValue, callback);
  };

  const customSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const handleRemoveAsset = (item) => {
    const currentSelectedAssets = [...selected.assets].filter(
      (e) => e.name !== item.name
    );
    if (item?.local) {
      const currentRecourses = [...selectedResources].filter(
        (e) => e.name !== item.name
      );
      const assetNames = new Set(
        currentSelectedAssets.map((asset) => asset.name)
      );
      const mapperAssets = currentRecourses.reduce((acc, e) => {
        if (!assetNames.has(e.name)) {
          acc.push({ ...e, name: e.name, local: true });
          assetNames.add(e.name);
        }
        return acc;
      }, []);

      setSelectedResources(currentRecourses);
      setSelected({
        ...selected,
        assets: [...currentSelectedAssets, ...mapperAssets],
      });
    } else {
      setSelected({ ...selected, assets: currentSelectedAssets });
      setFilesToDelete([...filesToDelete, item.id]);
    }
  };

  const fileUploaderClose = () => {
    setIsPopupOpen(false);
  };

  const showCalendar = () => {
    if (isCesa) {
      if (selectedGradeLevel && selectedGradeLevel.label === "Pregrado") {
        return true;
      }
      return false;
    }
    return true;
  };

  const showAcademicPeriod = () => {
    if (isCesa) {
      if (selectedGradeLevel && selectedGradeLevel.label === "Posgrado") {
        return false;
      }
      return true;
    }
    return true;
  };

  const getStudentTranslation = () => {
    const translation = {
      name: t("perfil_de_curso_formulario_numero_sesiones"),
      instruction: t("perfil_de_curso_formulario_numero_sesiones_instruction"),
      placeholder: t("perfil_de_curso_formulario_numero_sesiones_placeholder"),
    };
    if (
      isCesa &&
      selectedGradeLevel &&
      selectedGradeLevel.label === "Pregrado"
    ) {
      translation.name = t(
        "perfil_de_curso_formulario_numero_sesiones_pregrado_cesa"
      );
      translation.instruction = t(
        "perfil_de_curso_formulario_numero_sesiones_instruction_pregrado_cesa"
      );
      translation.placeholder = t(
        "perfil_de_curso_formulario_numero_sesiones_placeholder_pregrado_cesa"
      );
    }
    if (
      isCesa &&
      selectedGradeLevel &&
      selectedGradeLevel.label === "Posgrado"
    ) {
      translation.name = t(
        "perfil_de_curso_formulario_numero_sesiones_posgrado_cesa"
      );
      translation.instruction = t(
        "perfil_de_curso_formulario_numero_sesiones_instruction_posgrado_cesa"
      );
      translation.placeholder = t(
        "perfil_de_curso_formulario_numero_sesiones_placeholder_posgrado_cesa"
      );
    }
    return translation;
  };

  const getCourseName = () => {
    const translation = {
      name: t("perfil_de_curso_formulario_nombre"),
      instruction: t("perfil_de_curso_formulario_nombre_instruccion"),
      placeholder: t("perfil_de_curso_formulario_placeholder_nombre"),
    };
    if (isCesa) {
      translation.name = t("perfil_de_curso_formulario_nombre_cesa");
    }
    if (
      isCesa &&
      selectedGradeLevel &&
      selectedGradeLevel.label === "Pregrado"
    ) {
      translation.name = t("perfil_de_curso_formulario_nombre_pregrado_cesa");
      translation.instruction = t(
        "perfil_de_curso_formulario_nombre_instruccion_pregrado_cesa"
      );
      translation.placeholder = t(
        "perfil_de_curso_formulario_placeholder_nombre_pregrado_cesa"
      );
    }
    if (
      isCesa &&
      selectedGradeLevel &&
      selectedGradeLevel.label === "Posgrado"
    ) {
      translation.name = t("perfil_de_curso_formulario_nombre_posgrado_cesa");
      translation.instruction = t(
        "perfil_de_curso_formulario_nombre_instruccion_posgrado_cesa"
      );
      translation.placeholder = t(
        "perfil_de_curso_formulario_placeholder_nombre_posgrado_cesa"
      );
    }
    return translation;
  };

  const showSessionNumber = () => {
    if (isCesa) {
      if (selectedGradeLevel) {
        return true;
      }
      return false;
    }
    return true;
  };

  const showCourseName = () => {
    if (isCesa) {
      if (selectedGradeLevel) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="chat-content_title">
          <div className="welcome-text">
            <strong>{t("perfil_de_curso_titulo1")}</strong>
            <br />
            <br />
            {t("perfil_de_curso_titulo2")}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-full">
          <div className="input-row">
            <div className="input-text">
              <label className="input-label">{"Institución"}</label>
              <label className="input-instruction">
                {t("perfil_de_curso_formulario_institucion_instruccion")}
              </label>
              <Controller
                name="institution"
                control={methods.control}
                rules={{ required: t("campo_requerido") }}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadInstitutionOptions} //eslint-disable-line
                    onInputChange={(value) => {
                      setSearchTerm(value); //eslint-disable-line
                    }}
                    onChange={(selectedOption) => {
                      field.onChange(
                        selectedOption ? selectedOption.value : null
                      );
                      setValue("institution", selectedOption, {
                        shouldValidate: true,
                      });
                    }}
                    value={field.value}
                    styles={SelectStyles}
                    placeholder={t("registro_campo_institution_placeholder")}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    disabled={!loading}
                  />
                )}
              />
              {errors.institution && (
                <span className="input-error">
                  {errors.institution.message}
                </span>
              )}
            </div>
          </div>
          <div className="input-row">
            <div className="input-text">
              <label className="input-label">
                {t(
                  isCesa
                    ? "perfil_de_curso_formulario_grado_cesa"
                    : "perfil_de_curso_formulario_grado"
                )}
              </label>
              <label className="input-instruction">
                {t(
                  isCesa
                    ? "perfil_de_curso_formulario_grado_instruccion_cesa"
                    : "perfil_de_curso_formulario_grado_instruccion"
                )}
              </label>
              <div className="flex justify-around gap-2">
                <div className="w-full">
                  <Controller
                    name="gradeLevels"
                    control={methods.control}
                    rules={{ required: t("campo_requerido") }}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        cacheOptions
                        defaultOptions={gradeLevelOptions}
                        loadOptions={loadGradeLevelOptions}
                        styles={SelectStyles}
                        placeholder={t(
                          "perfil_de_curso_formulario_placeholder_grado"
                        )}
                      />
                    )}
                  />
                  {errors.gradeLevels && (
                    <span className="input-error">
                      {errors.gradeLevels.message}
                    </span>
                  )}
                </div>
                {!isCesa && (
                  <div>
                    <button
                      type="button"
                      onClick={toggleNewGradeLevelModal}
                      className="button-tertiary-rounded"
                    >
                      {t("perfil_de_curso_formulario_boton_crear_grado")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {showAcademicPeriod() ? (
              <div className="input-text">
                <SelectInput
                  name="academicPeriod"
                  label={t("perfil_de_curso_formulario_periodo_academico")}
                  labelInstruction={t(
                    "perfil_de_curso_formulario_periodo_academico_instruccion"
                  )}
                  options={academicPeriods
                    ?.filter((e) => e.show)
                    ?.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))}
                  isDefaultValue={!selected ? true : false}
                  required
                  placeholder={t(
                    "perfil_de_curso_formulario_periodo_academico_placerholder"
                  )}
                />
                {errors.academicPeriod && (
                  <span className="input-error">
                    {errors.academicPeriod.message}
                  </span>
                )}
              </div>
            ) : null}
          </div>
          <div className="input-row">
            <Modal3
              isOpen={isNewSubjectModalOpen}
              onClose={toggleNewSubjectModal}
            >
              <div className="p-4 flex gap-2">
                <TextInput2
                  name="new_name_subject"
                  label={t(
                    isCesa
                      ? "perfil_de_curso_formulario_modal_materia_nombre_cesa"
                      : "perfil_de_curso_formulario_modal_materia_nombre"
                  )}
                  required={isNewSubjectModalOpen}
                  customClassName="mt-0"
                />
                <div className="flex justify-center items-end ">
                  <button
                    type="button"
                    onClick={(e) =>
                      handleNewRegister(e, "subject-area", "new_name_subject")
                    }
                    className="button-tertiary-rounded"
                  >
                    {t(
                      isCesa
                        ? "perfil_de_curso_formulario_materia_nuevo_guardar_cesa"
                        : "perfil_de_curso_formulario_materia_nuevo_guardar"
                    )}
                  </button>
                </div>
              </div>
            </Modal3>

            <Modal3
              isOpen={isNewGradeLevelModalOpen}
              onClose={toggleNewGradeLevelModal}
            >
              <div className="p-4 flex gap-2">
                <TextInput2
                  name="new_name_grade"
                  label={t(
                    isCesa
                      ? "perfil_de_curso_formulario_modal_grado_nombre_cesa"
                      : "perfil_de_curso_formulario_modal_grado_nombre"
                  )}
                  required={isNewGradeLevelModalOpen}
                  customClassName="mt-0"
                />
                <div className="flex justify-center items-end ">
                  <button
                    type="button"
                    onClick={(e) =>
                      handleNewRegister(e, "grade", "new_name_grade")
                    }
                    className="button-tertiary-rounded"
                  >
                    {t(
                      isCesa
                        ? "perfil_de_curso_formulario_grado_nuevo_guardar_cesa"
                        : "perfil_de_curso_formulario_grado_nuevo_guardar"
                    )}
                  </button>
                </div>
              </div>
            </Modal3>

            {isCesa && showAreas && (
              <div className="input-text">
                <SelectInput
                  name="area"
                  label={t("perfil_de_curso_formulario_area")}
                  labelInstruction={t(
                    "perfil_de_curso_formulario_area_instruccion"
                  )}
                  options={areas?.map((e) => ({
                    value: e.id,
                    label: e.description,
                  }))}
                  onChange={() => {
                    setValue("subjectAreas", "");
                  }}
                  required
                  placeholder={t(
                    "perfil_de_curso_formulario_area_placerholder"
                  )}
                />
                {errors.area && (
                  <span className="input-error">{errors.area.message}</span>
                )}
              </div>
            )}

            <div className="input-text">
              <label className="input-label">
                {t(
                  isCesa
                    ? "perfil_de_curso_formulario_materia_cesa"
                    : "perfil_de_curso_formulario_materia"
                )}
              </label>
              <label className="input-instruction">
                {t(
                  isCesa
                    ? "perfil_de_curso_formulario_materia_instruccion_cesa"
                    : "perfil_de_curso_formulario_materia_instruccion"
                )}
              </label>
              <div className="flex justify-around gap-2">
                <div className="w-full">
                  <Controller
                    name="subjectAreas"
                    control={methods.control}
                    rules={{ required: t("campo_requerido") }}
                    render={({ field }) => (
                      <AsyncSelect
                        {...field}
                        cacheOptions
                        defaultOptions={subjectAreaOptions.filter(
                          (e) => e.show
                        )}
                        loadOptions={loadSubjectAreaOptions}
                        styles={SelectStyles}
                        isMulti
                        placeholder={t(
                          isCesa
                            ? "perfil_de_curso_formulario_placeholder_materia_cesa"
                            : "perfil_de_curso_formulario_placeholder_materia"
                        )}
                      />
                    )}
                  />
                  {errors.subjectAreas && (
                    <span className="input-error">
                      {errors.subjectAreas.message}
                    </span>
                  )}
                </div>

                {showCreateSubjectAreaButton && (
                  <div>
                    <button
                      type="button"
                      onClick={toggleNewSubjectModal}
                      className="button-tertiary-rounded"
                    >
                      {t("perfil_de_curso_formulario_boton_crear_materia")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {showCourseName() && (
            <div className="input-row">
              <div className="input-text">
                <TextInput2
                  label={getCourseName().name}
                  labelInstruction={getCourseName().instruction}
                  name="class_name"
                  placeHolder={getCourseName().placeholder}
                  maxLength={"60"}
                  customClassName={"mt-0"}
                  required
                />
              </div>
            </div>
          )}

          <div className="input-row">
            {showSessionNumber() && (
              <TextInput2
                label={getStudentTranslation().name}
                labelInstruction={getStudentTranslation().instruction}
                name="session_number"
                placeHolder={getStudentTranslation().placeholder}
                maxLength={"20"}
                required
              />
            )}

            <TextInput2
              label={t("perfil_de_curso_formulario_numero_estudiantes")}
              labelInstruction={t(
                "perfil_de_curso_formulario_numero_estudiantes_instruction"
              )}
              name="number_students"
              placeHolder={t(
                "perfil_de_curso_formulario_numero_estudiantes_placeholder"
              )}
              maxLength={"20"}
              required
            />
          </div>
          {showCalendar() ? (
            <div className="input-row flex flex-col ">
              <div>
                <DaySelector
                  label={t("perfil_de_curso_formulario_calendario")}
                  labelInstruction={t(
                    "perfil_de_curso_formulario_calendario_instruction"
                  )}
                  selectedData={selectedDaysData}
                  selectedGradeLevel={selectedGradeLevel}
                />
              </div>
              {errorCalendar && (
                <div className="text-Red-500 text-sm font-semibold pt-4 text-center">
                  {t("campo_requerido")}
                </div>
              )}
            </div>
          ) : null}

          {/* Hidde temporary */}
          {false && (
            <div className="input-row">
              <div className="input-text">
                <label className="input-label">
                  {t("perfil_de_curso_formulario_recursos")}
                </label>
                <label className="input-instruction">
                  {t("perfil_de_curso_formulario_recursos_instruction")}
                </label>
                <div className="flex flex-row sm-max:flex-col justify-around items-center w-full gap-4 ">
                  <Button
                    label={t("perfil_de_curso_formulario_recursos_cargar")}
                    variant="tertiary-rounded"
                    onClick={togglePopup}
                    disabled={gptLoading}
                    typeB={"button"}
                  />
                  {availableAssets.length ? (
                    <>
                      <span className="text-4 leading-4 text-LightBlue-500 flex h-full items-center justify-center">
                        o
                      </span>
                      <div className="flex-1 flex flex-row gap-0 sm-max:flex-col sm-max:gap-2 justify-center items-center">
                        <label className="sm:z-10 h-[42px] sm-max:w-full pl-4 pr-2 flex items-center justify-center text-3.5 leading-3.5 font-bold sm:rounded-tl-2 sm:rounded-bl-2 sm-max:rounded-2 bg-DarkBlue-800 text-Grey-500">
                          {t(
                            "perfil_de_curso_formulario_seleccionar_biblioteca"
                          )}
                        </label>
                        <SelectInput
                          name="existing_asset"
                          options={availableAssets?.map((e) => ({
                            value: e.id,
                            label: e.name,
                          }))}
                          customClassName="mt-0 sm:-ml-2"
                          placeholder={t(
                            "perfil_de_curso_biblioteca_placeholder"
                          )}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {/* Hidde temporary */}
          {selected && selected.assets.length && false ? (
            <div className="input-text">
              <label className="input-label">
                {t("perfil_de_curso_formulario_recursos_listado")}
              </label>
              <div className="cards-group mt-4">
                {selected &&
                  selected.assets.length &&
                  selected.assets.map((e) => (
                    <div className="flex items-start gap-3 bg-DarkBlue-900 p-3 rounded-2">
                      <div className="flex flex-col flex-1 gap-2 max-w-[100%-32px]">
                        <div className="flex items-start">
                          <span className="w-8 min-w-8 h-8 mr-2">
                            <img
                              src={UploadFile}
                              alt="Archivo"
                              className="max-w-full"
                            />
                          </span>
                          <div className="flex flex-col gap-2">
                            <label className="text-shaiaBlue">
                              {e?.local ? e.name : e.asset_uri.split("/").pop()}
                            </label>
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveAsset(e)}
                        className="w-6 flex items-center justify-center"
                      >
                        <span className="w-6 h-6">
                          <img
                            src={Trash}
                            alt="Eliminar"
                            className="max-w-full"
                          />
                        </span>
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}

          <TextArea2
            label={t("perfil_de_curso_formulario_consideraciones")}
            labelInstruction={t(
              "perfil_de_curso_formulario_consideraciones_instruction"
            )}
            name="special_considerations"
            placeholder={t(
              "perfil_de_curso_formulario_consideraciones_placeholder"
            )}
            maxLength={"2000"}
          />
          <div className="form-CTA">
            <button
              onClick={() => customSubmit()}
              type="button"
              disabled={loading}
              className="bg-[#3d96c2] text-[#28345c] font-bold rounded-lg p-3"
            >
              {loading
                ? t("perfil_de_curso_formulario_boton_enviando")
                : selected?.id || selectedRow?.id
                ? t("perfil_de_curso_formulario_boton_actualizar")
                : t("perfil_de_curso_formulario_boton_crear")}
            </button>
          </div>
        </form>
      </FormProvider>

      <Popup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        customClassName={"max-w-[75%] max-h-[80%]"}
      >
        <FileUploader onFileSelect={handleFiles} onClose={fileUploaderClose} />
      </Popup>
    </>
  );
};

export default FormCourseProfile;
