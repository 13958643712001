import AppRouter from "./utils/Router";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import classNames from "classnames";
/* Styles */
import "highlight.js/styles/github.css";
import "./styles/App.css";
/* Components */
import Modal from "./components/modal/modal";
import Header from "./components/header/header";
import useSnackbarStore from "./store/snackbarStore";
import Snackbar from "./components/snackbar/snackbar";
import Terms from "./components/terms/terms";
import Sidebar from "./components/sidebar/Sidebar";
import mixpanel from "mixpanel-browser";
/* Assets */
import History from "../src/assets/icons/watch_later.svg";
import Class from "../src/assets/icons/class.svg";
import logoShaiaBN from "../src/assets/azul-alt-bn.svg";
import LogOut from "../src/assets/icons/logout.svg";
import iconArrowDown from "../src/assets/icons/CaretDown.svg";
import subLevel from "../src/assets/icons/subdirectory_arrow_right.svg";
import ArrowBack from "../src/assets/icons/arrow_back.svg";
import AddItem from "../src/assets/icons/add_file.svg";
/* Services */
import { useMutation, useQuery, useQueryClient } from "react-query";
import { checkUser } from "./services/auth.service";
import { notAllowRoutesToCheckToken } from "./utils/Utils";
import useSessionStore from "./store/useSessionStore";
import { getAllUseCase } from "./services/useCase.service";
import useCaseStore from "./store/useCaseStore";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import useGlobalStore from "./store/useGlobalStore";
import useUserStore from "./store/useUser.store";
import useUsageStore from "./store/useUsageStore";
import useChatGPTStore from "./store/useChatGPT.store";
import { useMediaQuery } from "react-responsive";
import { useMixPanel } from "./hooks/useMixPanel";
import { MIX_PANEL_EVENTS } from "./utils/constans";
import langHandler from "./utils/langHandler";

const userData = { email: "Shaia Test", UserLetter: "S" };

const GA_MEASUREMENT_ID = "G-VNTJC74W7T";
function App() {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { isVisible, message, type, hide, duration } = useSnackbarStore();
  const currentToken = localStorage.getItem("token");
  const queryClient = useQueryClient();
  const { show } = useSnackbarStore();
  const { setUser } = useSessionStore();
  const { setUseCaseList } = useCaseStore();
  const { setLang } = useUserStore();
  const { data: useCaseData } = useQuery("useCaseList", getAllUseCase, {
    retries: 3,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    retry: (failureCount, error) => {
      if (error.status === 404) return false;
      return failureCount <= 3;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const isMobile = useMediaQuery({ maxWidth: 564 });
  const { user, setClear } = useSessionStore();
  const userString = localStorage.getItem("user");
  const userObject = userString ? JSON.parse(userString) : "";
  const currentUser = user || userObject.first_name;
  const { t, i18n } = useTranslation();
  const { setClear: setClearUseCaseUsageStore } = useUsageStore();
  const { setClear: setClearChatGTPStore } = useChatGPTStore();

  //Sidebar function
  const {
    isOpenSidebar,
    setIsOpenSidebar,
    isOpenHistoryH,
    setIsOpenHistoryH,
    setCreditAnimateClear,
  } = useGlobalStore();
  const toggleSidebar = () => setIsOpenSidebar(!isOpenSidebar);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const toggleClassHistoryH = () => setIsOpenHistoryH(!isOpenHistoryH);

  const mutation = useMutation(checkUser, {
    onSuccess: (data) => {
      queryClient.setQueryData(["user-login"], data?.data);
      localStorage.setItem("token", data?.access_token);
      localStorage.setItem("user", JSON.stringify(data?.user));
      setUser(data?.user);
      const lang = langHandler(data?.user);
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
      setLang(lang);
    },
    onError: (error) => {
      if (error?.response.status === 401) {
        show(error.response.data.message, "error");
        navigate("/login");
        localStorage.clear();
      }
    },
  });

  const { sendEvent } = useMixPanel();

  // const useCaseMutation = useMutation(getAllUseCase, {
  //   onSuccess: (data) => {
  //     if(data?.data?.length){
  //       setUseCaseList(data.data)
  //     } else {
  //       setUseCaseList([])
  //     }
  //   },
  //   onError: (error) => {
  //     setUseCaseList([])
  //   }
  // });

  useEffect(() => {
    if (useCaseData?.data?.length) {
      setUseCaseList(useCaseData.data);
    } else {
      setUseCaseList([]);
    }
    // eslint-disable-next-line
  }, [useCaseData]);

  useEffect(() => {
    if (
      !currentToken &&
      !notAllowRoutesToCheckToken.includes(location.pathname)
    ) {
      navigate("/login");
    }
  }, [currentToken, navigate, location]);

  useEffect(() => {
    if (currentToken) {
      mutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Función para establecer la altura del viewport
  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setViewportHeight(); // Establecer la altura al cargar la página
    window.addEventListener("resize", setViewportHeight); // Actualizar al cambiar tamaño
    // Limpieza al desmontar
    return () => window.removeEventListener("resize", setViewportHeight);
  }, []);

  useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const trackPageView = () => {
      const page = location.pathname + location.search;
      ReactGA.set({ page });
      ReactGA.pageview(page);
      console.log("Rastreando página:", page);
    };

    trackPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const pathnamesNoModal = [
    "/profile",
    "/login",
    "/register",
    "/register-google",
    "/register-hubspot",
    "/recovery-password",
    "/change-password",
    "/principles",
    "/confirmation",
  ];
  const pathnamesSidebar = ["/", "/course-profile"];
  const [alturaVentana, setAlturaVentana] = useState(window.innerHeight);

  useEffect(() => {
    const manejarCambioDeTamaño = () => {
      setAlturaVentana(window.innerHeight);
    };

    window.addEventListener("resize", manejarCambioDeTamaño);

    // Limpieza al desmontar el componente
    return () => window.removeEventListener("resize", manejarCambioDeTamaño);
  }, []);

  const blockStyle = {
    transform: isDropdownOpen ? "rotate(-180deg)" : "rotate(0deg)",
  };

  const closeMenu = () => {
    mixpanel.track(
      "SHAIA_LOGOUT",
      {
        email: currentUser?.email,
      },
      {
        send_immediately: true,
      },
      () => {
        mixpanel.reset();
      }
    );
    i18n.changeLanguage("es");
    setLang("es");
    localStorage.clear();
    localStorage.setItem("lang", "es");
    navigate("/login");
    setClear();
    setClearChatGTPStore();
    setClearUseCaseUsageStore();
  };

  const trackBackEvent = () => {
    navigate("/");
    setCreditAnimateClear();
    toggleDropdown();
    sendEvent(MIX_PANEL_EVENTS.activityBackHome);
  };

  const routesCustomCSSScroll = ["/login", "/register", "/login-institution", "/onboarding-teacher",];
  const routesCustomCSSWfull = ["/login", "/register", "/login-institution", "/onboarding-teacher",];

  return (
    <div
      className={`${
        location.pathname === "/register-hubspot"
          ? "root-bg-white"
          : "bg-wrapper"
      }`}
    >
      <div
        style={{ height: alturaVentana }}
        className={`${
          location.pathname === "/register-hubspot" ? "main-white" : "main"
        }`}
      >
        {pathnamesSidebar.includes(location.pathname) && !isMobile && (
          <div className="w-80 h-screen relative bg-DarkBlue-700/90 shadow flex flex-col gap-6 items-start justify-start px-4 py-4">
            <div className={classNames('header-navbar_logo max-h-16 sm-max:w-28 ',{
            "w-[20%]":!pathnamesSidebar.includes(location.pathname),
            "w-full":pathnamesSidebar.includes(location.pathname),
            })}>
              <Link to={"/"}>
                <img src={logoShaiaBN} className="logo" alt="Mentu" />
              </Link>
              {/* Logo institucional */}
              {user?.institutionalUser && (
                <div className="max-w-[50%] max-h-16 flex-1">
                  <img
                    src={
                      user.institutionalUser?.subscription?.institution
                        ?.logo_white
                    }
                    className="max-w-full max-h-full"
                    alt="Logo"
                  />
                </div>
              )}
            </div>
            <div className="w-full h-full flex flex-col gap-4 divide-y divide-dashed divide-Grey-200/10">
              <ul className="w-full h-full flex flex-col gap-4">
                {location.pathname === "/course-profile" ? (
                  <>
                    <li
                      className="w-full px-2 py-2 bg-LightBlue-500/10 hover:bg-LightBlue-500/40 cursor-pointer rounded-2 flex flex-row items-center justify-start gap-2"
                      onClick={trackBackEvent}
                    >
                      <span className="w-6 h-6">
                        <img
                          src={ArrowBack}
                          alt="empty"
                          className="max-w-full"
                        />
                      </span>
                      <p className="text-4 leading-4 text-Grey-500 font-semibold m-0">
                        {t("regresar")}
                      </p>
                    </li>
                    <li>
                      <Link
                        to={"/course-profile?create"}
                        className="w-full px-2 py-2 bg-LightBlue-500/10 hover:bg-LightBlue-500/40 cursor-pointer rounded-2 flex flex-row items-center justify-start gap-2"
                      >
                        <span className="w-6 h-6 opacity-50">
                          <img
                            src={AddItem}
                            alt="empty"
                            className="max-w-full"
                          />
                        </span>
                        <p className="text-4 leading-4 text-Grey-500 font-semibold m-0">
                          {t("perfil_de_curso_crear_curso")}
                        </p>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      className="w-full px-2 py-2 bg-LightBlue-500/10 hover:bg-LightBlue-500/40 cursor-pointer rounded-2 flex flex-row items-center justify-start gap-2"
                      onClick={toggleClassHistoryH}
                    >
                      <span className="w-6 h-6">
                        <img src={History} alt="empty" className="max-w-full" />
                      </span>
                      <p className="text-4 leading-4 text-Grey-500 font-semibold m-0">
                        {t("historialTitulo")}
                      </p>
                    </li>
                    {/* Elemento desplegable user?.institutionalUser */}
                    {user?.institutionalUser && (
                      <li
                        className="w-full px-2 py-2 bg-LightBlue-500/10 hover:bg-LightBlue-500/40 cursor-pointer rounded-2 flex flex-col items-center justify-start gap-2"
                        onClick={toggleDropdown}
                      >
                        <div className="w-full flex flex-row items-center justify-start gap-2">
                          <span className="w-6 h-6">
                            <img
                              src={Class}
                              alt="empty"
                              className="max-w-full"
                            />
                          </span>
                          <p className="text-4 leading-4 text-Grey-500 font-semibold m-0">
                            {t("perfil_de_curso_opcion_menu")}
                          </p>
                          <span
                            className="user-arrow ml-auto"
                            style={blockStyle}
                          >
                            <img src={iconArrowDown} alt="" />
                          </span>
                        </div>
                        {!isDropdownOpen && (
                          <ul className="w-full pl-4">
                            <li>
                              <Link
                                to={"/course-profile"}
                                className="rounded-1 py-1 hover:bg-LightBlue-300/20 text-3.5 leading-3.5 text-Grey-500/50 font-semibold m-0 flex flex-row items-center justify-start gap-1"
                              >
                                <span className="w-6 h-6 opacity-50">
                                  <img
                                    src={subLevel}
                                    alt="empty"
                                    className="max-w-full"
                                  />
                                </span>
                                {t("perfil_de_curso_ver_cursos")}
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={"/course-profile?create"}
                                className="rounded-1 py-1 hover:bg-LightBlue-300/20 text-3.5 leading-3.5 text-Grey-500/50 font-semibold m-0 flex flex-row items-center justify-start gap-1"
                              >
                                <span className="w-6 h-6 opacity-50">
                                  <img
                                    src={subLevel}
                                    alt="empty"
                                    className="max-w-full"
                                  />
                                </span>
                                {t("perfil_de_curso_crear_curso")}
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                    )}
                  </>
                )}
              </ul>
              <ul className="w-full h-auto flex flex-col gap-4 pt-4">
                <li
                  className="w-full px-2 py-2 bg-LightBlue-500/10 hover:bg-LightBlue-500/40 cursor-pointer rounded-2 flex flex-row items-center justify-start gap-2"
                  onClick={closeMenu}
                >
                  <span className="w-6 h-6">
                    <img src={LogOut} alt="empty" className="max-w-full" />
                  </span>
                  <p className="text-4 leading-4 text-Grey-500 font-semibold m-0">
                    {t("logout")}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div
          className={classNames("main_content", {
              scroll: routesCustomCSSScroll.includes(location.pathname),
              wFull: routesCustomCSSWfull.includes(location.pathname),
            }
          )}
        >
          {notAllowRoutesToCheckToken.includes(location.pathname) ? (
            <></>
          ) : (
            <Header {...userData} isInstitution={true} />
          )}
          <AppRouter />
          {isVisible && (
            <Snackbar
              message={message}
              type={type}
              onClose={hide}
              duration={duration}
            />
          )}
          {pathnamesNoModal.includes(location.pathname) ? (
            <></>
          ) : (
            <Modal isOpen={modalIsOpen} onClose={closeModal}>
              <div className="terms">
                <Terms />
              </div>
            </Modal>
          )}
        </div>
      </div>
      <Sidebar isOpen={isOpenSidebar} toggle={toggleSidebar} />
    </div>
  );
}

export default App;
