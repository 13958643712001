import React from 'react';
import { useFormContext } from 'react-hook-form';
import './textArea2.css';

const TextArea2 = ({ label, labelInstruction, placeholder, maxLength, name, required }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div className="input-text">
      {label && <label className="input-label">{label}</label>}
      {labelInstruction && <label className="input-instruction">{labelInstruction}</label>}
      <textarea
        placeholder={placeholder}
        className="textarea"
        maxLength={maxLength}
        {...register(name, { required: required && 'Este campo es obligatorio' })}
      />
      {errors[name] && <span className="input-error">{errors[name].message}</span>}
    </div>
  );
};

export default TextArea2;
