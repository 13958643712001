import './shaiabot.css';
import shaia from '../../assets/new_shaia.png';

const ShaiaBot = () => {
  return (
      <div className='shaia-bot'>
        <img src={shaia} className='shaia' alt='Sha.ia' />
      </div>
  );
};

export default ShaiaBot;