import { create } from "zustand";

const useGlobalStore = create((set) => ({
  gptSuccess: false,
  setGPTSuccess: (value) => set({ gptSuccess: value }),
  gptLoading: false,
  setGPTLoading: (value) => set({ gptLoading: value }),
  editorRef: null,
  setEditorRef: (ref) => set({ editorRef: ref }),
  creditAnimate: null,
  setCreditAnimate: (value) => set({creditAnimate: value}),
  urlCase: null,
  setUrlCase: (ref) => set({urlCase: ref}),
  setUrlCaseClear: () => set({urlCase: null}),
  setCreditAnimateClear: () => set({creditAnimate: null}),
  setGlobalClear: () => set({ gptSuccess: false, gptLoading: false, editorRef: null }),
  isOpenSidebar: false,
  setIsOpenSidebar: (value) => set({ isOpenSidebar: value }),
  isOpenHistory: false,
  setIsOpenHistory: (value) => set({isOpenHistory: value}),
  isOpenHistoryH: false,
  setIsOpenHistoryH: (value) => set({isOpenHistoryH: value}),
  createCourseProfileH: false,
  setCreateCourseProfileH: (value) => set({createCourseProfileH: value}),
  isChatActive: false,
  setIsChatActive: (value) => set({isChatActive: value})
}));

export default useGlobalStore;