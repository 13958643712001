import mixpanel from "mixpanel-browser";
export const useMixPanel = () => {
  const sendEvent = (eventName) => {
    const localStorageUser = localStorage.getItem("user");
    let user = "";
    let institution = "";
    let first_name = "";
    let last_name = "";
    let profile = "";
    let city = "";
    let country = "";
    let phone = "";
    let birthday = "";
    if (localStorageUser) {
      const parsedUser = JSON.parse(localStorageUser);
      user = parsedUser?.email;
      first_name = parsedUser?.first_name;
      last_name = parsedUser?.last_name;
      institution = parsedUser?.institution;
      profile = parsedUser?.profile;
      city = parsedUser?.city;
      country = parsedUser?.country;
      phone = parsedUser?.phone;
      birthday = parsedUser?.birthday;
    }
    mixpanel.track(eventName, {
      email: user,
      first_name,
      last_name,
      institution,
      profile,
      city,
      country,
      phone,
      birthday,
    });
  };

  const setInitialEvent = (data, event) => {
    localStorage.setItem("token", data?.access_token);
    localStorage.setItem("user", JSON.stringify(data?.user));

    const identityInfo = {
      $first_name: data?.user?.first_name,
      $last_name: data?.user?.last_name,
      $name: `${data?.user?.first_name} ${data?.user?.last_name}`,
      $email: data?.user?.email,
      $institution: data?.user?.institution,
      $app: "shaia",
    };

    mixpanel.identify(data?.user?.id);
    mixpanel.people.set(identityInfo);
    mixpanel.track(event, {
      email: data?.user?.email,
      institution: data?.user?.institution,
      first_name: data?.user?.first_name,
      last_name: data?.user?.last_name,
      country: data?.user?.country,
    });
  };

  const setManualEvent = (data, event) => {
    const identityInfo = {
      $first_name: data?.user?.first_name,
      $last_name: data?.user?.last_name,
      $name: `${data?.user?.first_name} ${data?.user?.last_name}`,
      $email: data?.user?.email,
      $institution: data?.user?.institution,
      $app: "shaia",
    };

    mixpanel.identify(data?.user?.id);
    mixpanel.people.set(identityInfo);
    mixpanel.track(event, {
      email: data?.user?.email,
      institution: data?.user?.institution,
      first_name: data?.user?.first_name,
      last_name: data?.user?.last_name,
      country: data?.user?.country,
    });
  };
  return { sendEvent, setInitialEvent, setManualEvent };
};
