import axios from "axios";
import { ENV, SHAIA_API, SHAIA_PYTHON_API } from "../utils/constans";
import * as Sentry from "@sentry/react";

const client = axios.create({
  baseURL: SHAIA_API,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 10000000,
});

export const pythonClient = axios.create({
  baseURL: SHAIA_PYTHON_API,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 10000000,
});

export const openAiClient = axios.create({
  baseURL: "https://shaia-llm-dev.mentu.co",
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: 10000000,
});

// Interceptor para reportar errores de respuesta
const responseErrorInterceptor = (error) => {
  const status = error.response ? error.response.status : null;
  if (status === 401) {
    return Promise.reject(error);
  }
  const userString = localStorage.getItem("user");
  const userObject = userString ? JSON.parse(userString) : "";
  const payload = error?.config?.data;
  const endpointUrl = `Service: ${error?.config?.url}`;
  Sentry.configureScope((scope) => {
    Sentry.captureMessage(endpointUrl, 'error');
    scope.setTag("env", ENV);
    scope.setTag("payload", payload);
    if (userObject) {
      scope.setUser({
        id: userObject?.id,
        email: userObject?.email,
        first_name: userObject?.first_name,
        last_name: userObject?.last_name,
      });
    }
  });
  Sentry.addBreadcrumb({
    category: "http",
    message: `Request failed: ${error.config.method.toUpperCase()} ${
      error.config.url
    }`,
    level: "error",
    data: {
      status: error.response?.status,
      payload,
      endpoint: error?.config?.url || ""
    },
  });

  Sentry.captureException(error);
  return Promise.reject(error);
};
client.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

client.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);
pythonClient.interceptors.response.use(
  (response) => response,
  responseErrorInterceptor
);

export default client;
