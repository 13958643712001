import { create } from 'zustand';

const useSessionStore = create((set) => ({
    user: null,
    setUser: (value) => set({ user: value }),
    setClearUser: () => set({user: null}),
    newUserGoogle: false,
    setNewUserGoogle: (value) => set({ newUserGoogle: value }),
    isTeacherOnboarding: false,
    setIsTeacherOnboarding: (value) => set({ isTeacherOnboarding: value }),
    setClearUserGoogle: () => set({newUserGoogle: false}),
    setClear: () => set({newUserGoogle: false, user: null}),
}));

export default useSessionStore;