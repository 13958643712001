import React, { useEffect, useState } from 'react';

const PieChartProgress = ({ progress }) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const size = windowSize.innerWidth <= 576 ? 24 : 32; // El tamaño del SVG
  const strokeWidth = 2.5; // El grosor del borde blanco
  const padding = 4; // El espacio entre el borde blanco y el gráfico de pastel
  const radius = size / 2; // El radio del círculo completo
  const innerRadius = radius - padding - strokeWidth; // Radio interior para el gráfico de pastel

  // Conversión del progreso a grados
  const progressInDegrees = (progress / 100) * 360;

  // Calculamos los puntos finales del arco del gráfico de pastel
  const x = radius + innerRadius * Math.sin((Math.PI * progressInDegrees) / 180);
  const y = radius - innerRadius * Math.cos((Math.PI * progressInDegrees) / 180);

  // Flag para el arco largo (necesario si el progreso es más del 50%)
  const largeArcFlag = progress > 50 ? 1 : 0;

  // Dibujo del camino para el sector del gráfico de pastel
  const pathData = [
    `M ${radius},${radius}`, // Mover al centro
    `L ${radius},${radius - innerRadius}`, // Dibujar línea hasta el comienzo del arco en la parte superior
    `A ${innerRadius},${innerRadius} 0 ${largeArcFlag} 1 ${x},${y}`, // Dibujar arco
    'Z' // Cerrar el camino
  ].join(' ');

  const getColor = () => {
    if(progress === 100) return '#3FB2E3'
    if(progress > 49) return '#F5BAA2';
    if(progress < 49) return '#EA9041';
  }

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Borde blanco separado alrededor del gráfico de pastel */}
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="transparent"
        stroke="white"
        strokeWidth={strokeWidth}
      />
      
      {/* Fondo del gráfico de pastel */}
      <circle cx={radius} cy={radius} r={innerRadius} fill="#E5E7EB" />
      
      {/* Sector del gráfico de pastel que representa el progreso */}
      <path d={pathData} fill={getColor()} />
    </svg>
  );
};

  export default PieChartProgress;

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }