import React, { useMemo, useState } from "react";
import PdfIcon from "../../assets/icons/pdf_icon.svg";
// import EditorPreview from "../editor-preview/defaultEditor";
import { format } from "date-fns";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
const LibraryCard = ({ guide, selected, onSelect, onDownload }) => {
  const { t } = useTranslation();
  const extractTitle = () => {
    let title = "Guía de catedra";
    if (guide?.memory_array) {
      const messages = JSON.parse(guide.memory_array);
      const findTitle = messages.find(
        (messageElement) =>
          messageElement?.content?.includes("<title>") ||
          messageElement?.data?.content?.includes("<title>")
      );
      if (findTitle) {
        const text = findTitle.content || findTitle.data.content;
        const regex = /<title>(.*?)<\/title>/;
        const match = text.match(regex);
        title = match ? match[1] : title;
      }
    }
    return title;
  };
  return (
    <div className=" flex flex-col gap-4 bg-DarkBlue-500/75 p-6 rounded-4">
      <div className="flex flex-col  justify-around">
        <div className="flex justify-start p-4 gap-4">
          <div className="flex items-center">
            <img src={PdfIcon} alt="Archivo" className="w-8 h-8" />
          </div>
          <div className="text-shaiaBlue">
            <div>{extractTitle()}</div>
            <div className="text-white text-xs ">
              {t("biblioteca_fecha")}: {format(guide.created_at, "dd-MM-yyyy")}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-white rounded-lg shadow-md p-4 h-80">
        <EditorPreview editorContent={guide.content} />
      </div> */}
      <div className="flex justify-center gap-4">
        <button
          onClick={() => onSelect(guide)}
          className={classNames(
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded button-primary",
            {
              "bg-shaiaGreen": selected?.id === guide?.id,
            }
          )}
        >
          {t(
            selected?.id === guide?.id
              ? "biblioteca_boton_seleccionado"
              : "biblioteca_boton_seleccionar"
          )}
        </button>
        <button
          onClick={() => onDownload(guide)}
          className={classNames(
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded button-primary"
          )}
        >
          {t("biblioteca_boton_ver")}
        </button>
      </div>
    </div>
  );
};

const ClassProfieLibrary = ({ data, onClose, onDownload, description }) => {
  const [selected, setSelected] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const handleSelect = (item) => {
    const find = selected?.id === item.id;
    setSelected(find ? null : item);
  };


  const filteredData = useMemo(() => {
    if (!searchTerm) return data;
    setSelected(null)
    return data.filter((guide) => {
      const messages = JSON.parse(guide.memory_array || "[]");
      return messages.some((message) => {
        const text = message.content || message.data.content;
        const regex = /<title>(.*?)<\/title>/g;
        let match;
        while ((match = regex.exec(text)) !== null) {
          if (match[1].toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    });
  }, [data, searchTerm]);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full pb-4">
      <div className="relative top-20 mx-auto p-5 rounded-md ">
        <div className="bg-dark-blue-600 text-white p-4 flex justify-between items-center">
          <div className="w-[80%]">
            <h2 className="text-xl font-semibold text-shaiaBlue">
              {t("lista_cursos_mi_biblioteca")}
            </h2>
          </div>
          <div className="flex flex-grow items-stretch mx-4 bg-[#DAEBF326] opacity-15 rounded overflow-hidden w-[20%]">
            <input
              type="text"
              className="h-10 px-4 py-2 w-full text-LightBlue-500 bg-[#DAEBF326] opacity-15 leading-tight focus:outline-none placeholder-LightBlue-500 border-LightBlue-500 border-1"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="flex items-center justify-center px-4 bg-[#DAEBF326] h-10">
              <svg
                className="w-4 h-4 text-[#3D96C2]"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1112 0A6 6 0 012 8z"
                  clipRule="evenodd"
                />
                <path d="M12.293 12.293a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414z" />
              </svg>
            </button>
          </div>
        </div>
        {description && (
          <div className="bg-dark-blue-600 text-white p-4 flex justify-between items-center mb-2">
            {description}
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredData.map((guide, index) => (
            <LibraryCard
              key={index}
              guide={guide}
              onSelect={handleSelect}
              selected={selected}
              onDownload={onDownload}
            />
          ))}
        </div>
        <div className="text-center mt-5">
          <button
            onClick={() => onClose(selected)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded button-primary"
          >
            {selected
              ? t("biblioteca_boton_terminar")
              : t("biblioteca_boton_cerrar")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClassProfieLibrary;