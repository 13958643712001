import PropTypes from 'prop-types';
import './button.css';
import classNames from "classnames";

const Button = ({ label, onClick, variant, typeB, disabled, arrow, customClassName = '', hasIconCredit, hasIcon, creditN }) => {
  let className = 'button';

  if (variant === 'primary') {
    className += ' button-primary';
  } else if (variant === 'secondary') {
    className += ' button-secondary';
  } else if (variant === 'tertiary') {
    className += ' button-tertiary';
  } else if (variant === 'tertiary-rounded') {
    className += ' button-tertiary-rounded';
  } else if (variant === 'primary-alt') {
    className += ' button-primary-alt';
  } else if (variant === 'primary-outline') {
    className += ' button-primary-outline';
  } else if (variant === 'secondary-outline') {
    className += ' button-secondary-outline';
  } else if (variant === 'red-button') {
    className += ' red-button';
  }
  
  if (arrow === true) {
    className += ' arrow';
  }

  if (disabled) {
    className += ' disabled';
  } 

  const classhasIcon = hasIconCredit || hasIcon ? 'py-2' : 'py-4';

  return (
    <button className={classNames(className, customClassName, classhasIcon)} onClick={onClick} type={typeB} disabled={disabled} >
      {label}
    </button>
  );
};

Button.propTypes = {
  typeB: PropTypes.string,
  onClick: PropTypes.func,
  riant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "tertiary-rounded",
    "primary-alt",
    "primary-outline",
    "secondary-outline"
  ]),
};

export default Button;
