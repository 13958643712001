export const MIX_PANEL_EVENTS = {
  home: 'SHAIA_HOME',
  login: 'SHAIA_LOGIN_NATIVE',
  loginSocial: 'SHAIA_LOGIN_SOCIAL_MEDIA',
  loginGoogle: 'SHAIA_LOGIN_GOOGLE',
  registerGoogle: 'SHAIA_REGISTER_GOOGLE',
  loginView: 'SHAIA_LOGIN_VIEW',
  register: 'SHAIA_REGISTER_NATIVE',
  registerGoogleHBS: 'SHAIA_REGISTER_GOOGLE_HBS',
  registerHBS: 'SHAIA_REGISTER_NATIVE_HBS',
  registerView: 'SHAIA_REGISTER_VIEW',
  editProfile:"EDITAR_PERFIL_DOCENTE",
  saveProfile:"GUARDAR_PERFIL_DOCENTE",
  activityIdea: "NUEVA_IDEA_USE_CASE_ACTIVITY",
  activityModify: "MODIFICAR_USE_CASE_ACTIVITY",
  activityMaterial: "GENERA_MATERIALES_USE_CASE_ACTIVITY",
  classIdea: "NUEVA_IDEA_USE_CASE_CLASS",
  classModify: "MODIFICAR_USE_CASE_CLASS",
  classMaterial: "GENERA_MATERIALES_USE_CASE_CLASS",
  assessmentIdea: "NUEVA_IDEA_USE_CASE_ASSESSMENT",
  assessmentModify: "MODIFICAR_USE_CASE_ASSESSMENT",
  assessmentMaterial: "GENERA_MATERIALES_USE_CASE_ASSESSMENT",
  assessmentAnswers: "GENERA_RESPUESTAS_USE_CASE_ASSESSMENT",
  activityBackHome: "SHAIA_ACTIVIDAD_BACK_HOME",
  assessmentBackHome: "SHAIA_ASSESSMENT_BACK_HOME",
  classBackHome: "SHAIA_CLASS_BACK_HOME",
  abpBackHome: "SHAIA_ABP_BACK_HOME",
  classDownloadPDF: "SHAIA_CLASS_DOWNLOAD_PDF",
  activityDownloadPDF: "SHAIA_ACTIVITY_DOWNLOAD_PDF",
  assessmentDownloadPDF: "SHAIA_ASSESSMENT_DOWNLOAD_PDF",
  activityUseCaseResponse: "SHAIA_USE_CASE_ACTIVITY_RESPONSE",
  activityUseCaseSend: "USE_CASE_ACTIVITY_SEND",
  activityUseCaseView: "USE_CASE_ACTIVITY_VIEW",
  classUseCaseSend: "USE_CASE_CLASS_SEND",
  classUseCaseView: "USE_CASE_CLASS_VIEW",
  classUseCaseResponse: "SHAIA_USE_CASE_CLASS_RESPONSE",
  assessmentUseCaseResponse: "SHAIA_USE_CASE_ASSESSMENT_RESPONSE",
  assessmentUseCaseSend: "USE_CASE_ASSESSMENT_SEND",
  assessmentUseCaseView: "USE_CASE_ASSESSMENT_VIEW",
  abpDownloadPDF: "SHAIA_ABP_DOWNLOAD_PDF",
  abpUseCaseResponse: "SHAIA_USE_CASE_ABP_RESPONSE",
  abpUseCaseView: "USE_CASE_ABP_VIEW",
  contextUseCaseResponse: "SHAIA_USE_CASE_CONTEXT_RESPONSE",
  contextUseCaseSend: "USE_CASE_CONTEXT_SEND",
  contextUseCaseView: "USE_CASE_CONTEXT_VIEW",
  contextIdea: "NUEVA_IDEA_USE_CASE_CONTEXT",
  contextModify: "MODIFICAR_USE_CASE_CONTEXT",
  contextDownloadPDF: "SHAIA_CONTEXT_DOWNLOAD_PDF",
  contextBackHome: "SHAIA_CONTEXT_BACK_HOME",
  bloomPerformanceUseCaseResponse: "SHAIA_USE_CASE_BLOOM_PERFORMANCE_RESPONSE",
  bloomPerformanceUseCaseSend: "USE_CASE_BLOOM_PERFORMANCE_SEND",
  bloomPerformanceUseCaseView: "USE_CASE_BLOOM_PERFORMANCE_VIEW",
  bloomPerformanceIdea: "NUEVA_IDEA_USE_CASE_BLOOM_PERFORMANCE",
  bloomPerformanceModify: "MODIFICAR_USE_CASE_BLOOM_PERFORMANCE",
  bloomPerformanceDownloadPDF: "SHAIA_BLOOM_PERFORMANCE_DOWNLOAD_PDF",
  bloomPerformanceBackHome: "SHAIA_BLOOM_PERFORMANCE_BACK_HOME",
  rubricUseCaseResponse: "SHAIA_USE_CASE_RUBRIC_RESPONSE",
  rubricUseCaseSend: "USE_CASE_RUBRIC_SEND",
  rubricUseCaseView: "USE_CASE_RUBRIC_VIEW",
  rubricIdea: "NUEVA_IDEA_USE_CASE_RUBRIC",
  rubricModify: "MODIFICAR_USE_CASE_RUBRIC",
  rubricDownloadPDF: "SHAIA_RUBRIC_DOWNLOAD_PDF",
  rubricBackHome: "SHAIA_RUBRIC_BACK_HOME",
  warmupUseCaseResponse: "SHAIA_USE_CASE_WARMUP_RESPONSE",
  warmupUseCaseSend: "USE_CASE_WARMUP_SEND",
  warmupUseCaseView: "USE_CASE_WARMUP_VIEW",
  warmupIdea: "NUEVA_IDEA_USE_CASE_WARMUP",
  warmupModify: "MODIFICAR_USE_CASE_WARMUP",
  warmupDownloadPDF: "SHAIA_WARMUP_DOWNLOAD_PDF",
  warmupBackHome: "SHAIA_WARMUP_BACK_HOME",
  registerNativeStep1: "SHAIA_REGISTER_NATIVE_Step1",
  registerNativeStep2: "SHAIA_REGISTER_NATIVE_Step2",
  registerNativeStep3: "SHAIA_REGISTER_NATIVE_Step3",
  registerGoogleStep1: "SHAIA_REGISTER_GOOGLE_Step1",
  registerGoogleStep2: "SHAIA_REGISTER_GOOGLE_Step2",
  registerGoogleStep3: "SHAIA_REGISTER_GOOGLE_Step3",
  registerInstitutionStep1: 'SHAIA_REGISTER_NATIVE_INSTITUTION_Step1',
  registerHBSStep1: 'SHAIA_REGISTER_NATIVE_HBS_Step1',
  registerHBSStep2: 'SHAIA_REGISTER_NATIVE_HBS_Step2',
  abpSend: "USE_CASE_ABP_SEND",
  confirmedEmail: "SHAIA_CONFIRMED_EMAIL",
  edasDownloadPDF: "SHAIA_EDAS_DOWNLOAD_PDF",
  edasUseCaseResponse: "SHAIA_USE_CASE_EDAS_RESPONSE",
  edasUseCaseView: "USE_CASE_EDAS_VIEW",
  edasSend: "USE_CASE_EDAS_SEND",
  edasBackHome: "SHAIA_EDAS_BACK_HOME",
  readingComprehensionUseCaseView: "USE_CASE_READING_COMPREHENSION_VIEW",
  readingComprehensionDownloadPDF: "SHAIA_READING_COMPREHENSION_DOWNLOAD_PDF",
  readingComprehensionUseCaseResponse: "SHAIA_USE_CASE_READING_COMPREHENSION_RESPONSE",
  readingComprehensionSend: "USE_CASE_READING_COMPREHENSION_SEND",
  readingComprehensionBackHome: "SHAIA_READING_COMPREHENSION_BACK_HOME",
  readingComprehensionIdea: "NUEVA_IDEA_USE_CASE_READING_COMPREHENSION",
  readingComprehensionModify: "MODIFICAR_USE_CASE_READING_COMPREHENSION",
  criticalThinkingUseCaseView: "USE_CASE_CRITICAL_THINKING_VIEW",
  criticalThinkingDownloadPDF: "SHAIA_CRITICAL_THINKING_DOWNLOAD_PDF",
  criticalThinkingUseCaseResponse: "SHAIA_USE_CASE_CRITICAL_THINKING_RESPONSE",
  criticalThinkingSend: "USE_CASE_CRITICAL_THINKING_SEND",
  criticalThinkingBackHome: "SHAIA_CRITICAL_THINKING_BACK_HOME",
  criticalThinkingIdea: "NUEVA_IDEA_USE_CASE_CRITICAL_THINKING",
  criticalThinkingModify: "MODIFICAR_USE_CASE_CRITICAL_THINKING",
  courseProfileBackHome: "SHAIA_COURSE_PROFILE_BACK_HOME",
};

export const SHAIA_API = process.env.REACT_APP_SHAIA_API;
export const SHAIA_PYTHON_API = process.env.REACT_APP_SHAIA_PYTHON_API;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const ENV = process.env.NODE_ENV;
console.log('SHAIA_API ', SHAIA_API);


export const initialTextClass = `
# Plan de Clase: Aprendiendo Fracciones

## Objetivos
- Comprender el concepto de fracciones como divisiones de una cantidad en partes iguales.
- Utilizar operaciones básicas (suma y resta) con fracciones en situaciones cotidianas.
- Representar fracciones en forma de dibujos y en notación numérica.

## Actividades

### Introducción: "¿Qué son las fracciones?" (10 min)
- Presentar a los estudiantes una situación cotidiana en la que se divide una cantidad en partes iguales, como repartir una pizza entre varias personas.
- Preguntar a los estudiantes si saben qué es una fracción y si han escuchado hablar de ellas.
- Establecer una conversación en grupo para activar sus conocimientos previos y generar interés por el tema.
- Realizar preguntas orientadoras, como: ¿Qué creen que significa la palabra "fracción"? ¿Han visto fracciones en algún otro contexto?

### Actividad de Aprendizaje 1: "Fracciones en el fútbol" (15 min)
- Dividir a los estudiantes en grupos de 4 y proporcionarles un libro con problemas relacionados con el fútbol y fracciones.
- Cada grupo debe resolver los problemas utilizando fracciones. Por ejemplo, "si un equipo de fútbol ha ganado 3 de los 8 partidos jugados, ¿qué fracción de partidos han ganado?"
- Darles tiempo suficiente para que trabajen en los problemas y luego permitirles compartir y discutir sus respuestas en clase.
- Durante la discusión, enfatizar la relación entre las fracciones y la división de una cantidad en partes iguales.

### Actividad de Aprendizaje 2: "Representando fracciones" (15 min)
- Proporcionar papel y lápices a los estudiantes.
- Pedirles que dibujen objetos divididos en fracciones en forma de dibujos.
- Por ejemplo, pueden dibujar una pizza dividida en mitades, un pastel dividido en cuartos, o incluso un campo de fútbol dividido en octavos.
- Una vez que hayan terminado sus dibujos, pedirles que etiqueten las partes de cada dibujo con la notación numérica correspondiente a la fracción.
- Invitar a los estudiantes a compartir sus dibujos y explicar cómo representaron las fracciones.

### Cierre: "¿Qué hemos aprendido sobre las fracciones?" (10 min)
- Hacer una breve recapitulación de los conceptos clave aprendidos sobre las fracciones.
- Realizar una actividad de metacognición, donde los estudiantes reflexionen sobre los desafíos que tuvieron al aprender sobre fracciones y cómo los superaron.
- Preguntar a los estudiantes si tienen alguna duda o pregunta adicional sobre fracciones.
- Proporcionar retroalimentación y evaluar la comprensión de los estudiantes a través de preguntas dirigidas y respuestas en el grupo.

## Evaluación
- Observar la participación activa de los estudiantes durante las actividades y la discusión en grupo.
- Evaluar la precisión y comprensión de las respuestas de los estudiantes en las actividades de los grupos.
- Tomar nota de las preguntas y confusiones de los estudiantes para identificar áreas que requieren clarificación o repaso.

## Tareas para la casa
- Pedir a los estudiantes que encuentren ejemplos de fracciones en situaciones cotidianas y los compartan en clase.
- Solicitar a los estudiantes que practiquen la suma y resta de fracciones en situaciones de la vida real, como compartir una pizza en casa.

## Posibles dificultades
- Algunos estudiantes pueden tener dificultades para comprender el concepto de fracciones como divisiones de una cantidad en partes iguales. En este caso, se puede utilizar material manipulativo, como palillos, para ayudar a visualizar la división.
- Algunos estudiantes pueden tener dificultades para identificar las partes de un dibujo o relacionarlas con la notación numérica correspondiente a la fracción. Se pueden proporcionar ejemplos adicionales y ejercicios de práctica para reforzar estos conceptos.

## Consideraciones especiales
- Teniendo en cuenta que a los estudiantes les gusta el fútbol, las actividades se basan en ejemplos relacionados con este deporte para generar mayor interés y motivación.
- Adaptar los problemas y ejemplos a situaciones y objetos cotidianos que sean relevantes para los estudiantes, para que puedan relacionar las fracciones con su propia experiencia.

## Consideraciones pedagógicas
1. Utilizar el principio "Conocimiento previo": Hacer preguntas que vinculen el nuevo concepto de fracciones con lo que los estudiantes ya saben sobre divisiones y sumas. Por ejemplo, preguntar: "Si sumamos 1/4 de pastel y 1/4 de pastel, ¿qué fracción de pastel tenemos?" Esto les permite utilizar su conocimiento previo de las sumas y aplicarlo al nuevo concepto.
2. Aplicar el principio "Múltiples representaciones": Animar a los estudiantes a usar tanto dibujos como notación numérica para representar fracciones. Esto les ayuda a desarrollar una comprensión más profunda del concepto y les proporciona diversas formas de visualizar las fracciones. Por ejemplo, pueden dibujar una pizza dividida en octavos y escribir la notación numérica correspondiente a cada parte.
`;

export const initialTextAssess = `
# Evaluación de Ecuaciones con Potencias

## Preguntas de Verdadero/Falso

Decide si la siguiente afirmación es falsa o verdadera y explica por qué.

1. La expresión $3^2$ es igual a 9.
2. $4^3$ es igual a 64.

## Preguntas de Opción Múltiple

Selecciona la opción correcta.

1. ¿Cuál es el resultado de la siguiente operación? $2^3 + 5^2$
   a) 8
   b) 16
   c) 29π
   d) 37

2. ¿Cuál es el resultado de la siguiente operación? $10^0 + 3^1$
   a) 0
   b) 1
   c) 4
   d) 13

3. ¿Cuál de las siguientes expresiones es igual a 1?
   a) $5^0$
   b) $0^5$
   c) $2^3$
   d) $3^2$

## Preguntas Abiertas

1. Explica qué es una ecuación con potencias y da un ejemplo.

2. Calcula el valor de $2^4 + 3^2 - 2^2$.

3. Escribe una ecuación con potencias que incluya las siguientes operaciones: suma, multiplicación y exponente.
`;

export const initialTextActivity = `
#### Actividad: Tiro al arco matemático

Duración: 20 minutos

Objetivos:
- Los estudiantes podrán aplicar operaciones básicas de suma, resta, multiplicación y división.
- Los estudiantes podrán resolver problemas matemáticos utilizando estrategias adecuadas.
- Los estudiantes podrán trabajar de forma colaborativa en grupos pequeños.

Propósito: Esta actividad tiene como objetivo equipar a los estudiantes con herramientas para desarrollar habilidades matemáticas básicas mientras se divierten y trabajan en equipo.

Materiales:
- Hojas de papel
- Lápices
- Pizarrón o papelógrafo
- Marcadores
- Cartulinas
- Tijeras
- Cinta adhesiva

Instrucciones:
1. Explique a los estudiantes que van a participar en un juego llamado "Tiro al arco matemático".
2. Forme grupos de tres estudiantes y distribuya los materiales necesarios.
3. En el pizarrón o papelógrafo, escriba diferentes problemas matemáticos (suma, resta, multiplicación y división) y asigne un puntaje a cada problema.
4. Corte las cartulinas en forma de flechas y escriba los números del 1 al 10 en cada flecha.
5. Cuelgue las cartulinas en diferentes partes del salón, asignando un puntaje a cada una.
6. Explique a los estudiantes que cada grupo deberá resolver un problema matemático y lanzar una flecha al número correspondiente de la respuesta correcta.
7. Los grupos deben trabajar juntos para resolver los problemas de manera precisa y rápida.
8. Después de cada ronda, sume los puntajes obtenidos por cada grupo y anótelo en el pizarrón.
9. Al finalizar el juego, felicite a los grupos por su trabajo en equipo y discuta las estrategias utilizadas para resolver los problemas.

Evaluación:
- Observe la participación y colaboración de los estudiantes durante el juego.
- Evalúe la precisión de las respuestas a los problemas matemáticos.
- Revise las estrategias utilizadas por los estudiantes para resolver los problemas.

Posibles dificultades:
- Algunos estudiantes pueden tener dificultades para resolver los problemas matemáticos. Brinde apoyo adicional a estos estudiantes y anime a sus compañeros de grupo a ayudarlos.
- Algunos grupos pueden enfrentar dificultades para trabajar de manera colaborativa. Anime a todos los miembros del grupo a participar y contribuir.
- La organización del juego puede ser un desafío, especialmente si el salón de clase tiene un espacio limitado. Asegúrese de tener suficiente espacio y materiales para que todos los grupos participen sin problemas.

Consideraciones especiales:
- Aproveche el interés de los estudiantes en el fútbol para relacionar el juego con situaciones de la vida real, como calcular el puntaje de un equipo en un partido.
- Proporcione ejemplos y ejercicios adicionales para aquellos estudiantes que deseen practicar más los problemas matemáticos.
- Anime a los estudiantes a explicar sus estrategias y razonamientos durante el juego.

Consideraciones pedagógicas:
1. Todos pueden aprender: Establezca altas expectativas para todos los estudiantes y brinde apoyo adicional a aquellos que lo necesiten. Por ejemplo, si un estudiante tiene dificultades para resolver un problema, anime a sus compañeros de grupo a explicar cómo llegaron a la respuesta correcta.
2. Conocimiento previo: Aproveche el conocimiento y los intereses de los estudiantes para hacer conexiones con los problemas matemáticos. Por ejemplo, puede utilizar ejemplos relacionados con el fútbol para que los estudiantes puedan aplicar los conceptos matemáticos a situaciones reales. Por ejemplo, puede plantear un problema en el que los estudiantes tengan que calcular el puntaje de un equipo de fútbol basado en los goles anotados
`;


export const GENERAL_SPANISH = "es";
export const GENERAL_ENG = "en";
export const PERU_SPANISH = "es_pe";
export const PERU_COUNTRY_ID = "158";