import useLoggedUserInfo from "./useLoggedUserInfo";
import {useEffect, useState} from "react";
import langHandler from "../utils/langHandler";


const useInstitutionLang = () => {
    const userInfo = useLoggedUserInfo();

    const [institutionDefaultLang, setInstitutionDefaultLang] = useState("es");

    useEffect(() => {
        const lang = langHandler(userInfo);
        setInstitutionDefaultLang(lang);
    }, [userInfo]);

    return institutionDefaultLang;

};

export default useInstitutionLang;