import React, { useState, useEffect } from "react";
import "katex/dist/katex.min.css";
import katex from "katex";
import { Remarkable } from "remarkable";
/* Icons */
import icon_alignLeft from "../../assets/icons/format_align_left.svg";
import icon_alignRight from "../../assets/icons/format_align_right.svg";
import icon_alignCenter from "../../assets/icons/format_align_center.svg";
import icon_alignJustify from "../../assets/icons/format_align_justify.svg";
import { exportToDocx } from "../../utils/Utils";

const DefaultEditor = ({ editorContent }) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [, setEditableContent] = useState("");

  useEffect(() => {
    processContent(editorContent);
    // eslint-disable-next-line
  }, [editorContent]);

  const processContent = (content) => {
    const stageContents = extractStageContent(content);
    let combinedContent = "";
    // Concatenar el contenido de cada etapa, si está disponible
    if (stageContents["stage_1"]) {
      combinedContent += stageContents["stage_1"] + "\n\n";
    }
    if (stageContents["stage_2"]) {
      combinedContent += stageContents["stage_2"] + "\n\n";
    }
    if (stageContents["stage_3"]) {
      combinedContent += stageContents["stage_3"] + "\n\n";
    }

    // Procesar el contenido combinado
    if (combinedContent) {
      processText(combinedContent);
    }
  };

  const extractStageContent = (text) => {
    const stageRegex = /<(stage_[123])>(.*?)<\/\1>/gs;
    let matches;
    let stageContents = {};

    while ((matches = stageRegex.exec(text)) !== null) {
      // matches[1] es el identificador de la etapa (stage_1, stage_2, stage_3)
      // matches[2] es el contenido
      stageContents[matches[1]] = matches[2].trim();
    }

    return stageContents;
  };

  const convertListMarkdown = (markdownText) => {
    let processedText = markdownText;

    // Convierte los títulos que terminan en ':' en negrita
    processedText = processedText.replace(
      /^(?!#+\s)(.*?:)$/gm,
      (match, title) => `<strong>${title}</strong>\n`
    );

    // Convierte texto en negrita
    processedText = processedText.replace(
      /\*\*(.*?)\*\*/g,
      (match, text) => `<strong>${text}</strong>`
    );

    // Busca y procesa bloques de listas con guiones

    processedText = processedText.replace(
      /^[\s]*\-\s+(.+)/gm, // eslint-disable-line
      (match, text) => `<li class="editor-li">${text.trim()}</li>`
    );

    // Agrega las etiquetas <ul> alrededor de los elementos <li> no ordenados
    processedText = processedText.replace(
      /(<li class="editor-li">.*?<\/li>)/gs,
      (match) => `<ul>\n${match}\n</ul>`
    );

    // Procesa listas numeradas
    processedText = processedText.replace(
      /^[\s]*(\d+)\.\s+(.+)/gm,
      (match, number, text) =>
        `<li style="list-style: none">${number}. ${text.trim()}</li>`
    );

    // Agrega las etiquetas <ol> alrededor de los elementos <li> numerados
    processedText = processedText.replace(
      /(<li class="editor-li-numbers">.*?<\/li>)/gs,
      (match) => `<ol>\n${match}\n</ol>`
    );

    // Convierte las opciones de las preguntas de opción múltiple con salto de línea después de cada opción
    processedText = processedText.replace(
      /^(\w\)) ([^\n]+)/gm,
      (match, bullet, text) =>
        `<div class="editor-list-options-letters">  ${bullet} ${text}</div>\n`
    );
    processedText = processedText.replace(
      /^(\d+\.) ([^\n]+)/gm,
      (match, bullet, text) =>
        `<div class="editor-list-options-numbers">  ${bullet} ${text}</div>\n`
    );

    // Procesa tablas eliminando celdas vacías
    processedText = processedText.replace(
      /<table>(.*?)<\/table>/gs,
      (match, tableContent) => {
        // Eliminar celdas vacías
        const cleanedTable = tableContent.replace(
          /<t[dh][^>]*>\s*<\/t[dh]>/g,
          ""
        );
        // Eliminar filas que están completamente vacías después de quitar las celdas vacías
        const cleanedRows = cleanedTable.replace(
          /<tr>(\s*|\s*<\/?t[dh][^>]*>)*<\/tr>/g,
          ""
        );
        return `<div className="table-wrapper"><table>${cleanedRows}</table></div>`;
      }
    );

    // Elimina líneas vacías entre las etiquetas <ul> y <ol>
    processedText = processedText
      .replace(/<\/ul>\s+<ul>/g, "</ul><ul>")
      .replace(/<\/ol>\s+<ol>/g, "</ol><ol>");

    return processedText;
  };

  const processText = (textToProcess) => {
    const md = new Remarkable({
      html: true, // Permite HTML en el input
      breaks: true, // Convierte '\n' en <br>
      typographer: true, // Habilita algunas sustituciones tipográficas
    });
    // KaTeX pre-processing here if needed
    const parsedText = convertListMarkdown(textToProcess);
    let html = md.render(parsedText);

    // KaTeX post-processing
    html = html.replace(/\$(.+?)\$/g, (match, formula) => {
      try {
        return katex.renderToString(formula, {
          throwOnError: false,
          displayMode: false,
        });
      } catch (error) {
        console.error("Error rendering formula with KaTeX:", error);
        return match;
      }
    });

    setHtmlContent(html);
  };

  const applyStyle = (command) => {
    document.execCommand(command, false, null);
  };

  const applyUnderline = () => {
    document.execCommand("underline", false, "");
  };

  const applyStrikethrough = () => {
    document.execCommand("strikeThrough", false, "");
  };

  const alignLeft = () => {
    document.execCommand("justifyLeft", false, "");
  };

  const alignRight = () => {
    document.execCommand("justifyRight", false, "");
  };

  const alignCenter = () => {
    document.execCommand("justifyCenter", false, "");
  };

  const alignJustify = () => {
    document.execCommand("justifyFull", false, "");
  };

  const handleContentEditable = (event) => {
    const updatedHtml = event.target.innerHTML;
    setEditableContent(updatedHtml);
  };

  const cleanTableHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const tables = doc.querySelectorAll("table");

    tables.forEach((table) => {
      const cells = table.querySelectorAll("th, td");
      cells.forEach((cell) => {
        if (!cell.textContent.trim()) {
          cell.remove();
        }
      });

      const rows = table.querySelectorAll("tr");
      rows.forEach((row) => {
        const cells = row.querySelectorAll("th, td");
        if (cells.length === 0) {
          row.remove();
        }
      });
    });
    return doc.body.innerHTML;
  };

  return (
    <div className="flex flex-col lg-max:max-h-includeBack lg:max-h-includeBack sm-max:h-includeToggle">
      <div className="bg-white hover:bg-white w-full flex flex-row gap-4 items-center justify-start py-2 px-4">
        <button
          className=" text-black font-bold rounded w-8 h-8 flex items-center justify-center"
          onClick={() => applyStyle("bold")}
        >
          𝗕
        </button>
        <button
          className=" text-black font-bold rounded w-8 h-8 flex items-center justify-center italic"
          onClick={() => applyStyle("italic")}
        >
          I
        </button>
        <button
          className="text-black font-bold rounded w-8 h-8 flex items-center justify-center underline"
          onClick={applyUnderline}
        >
          U
        </button>
        <button
          className="text-black font-bold rounded w-8 h-8 flex items-center justify-center line-through"
          onClick={applyStrikethrough}
        >
          𝚂
        </button>
        <button
          className="text-black font-bold rounded w-8 h- flex items-center justify-center"
          onClick={alignLeft}
        >
          <img className="max-w-full w-5" src={icon_alignLeft} alt="" />
        </button>
        <button
          className="text-black font-bold rounded w-8 h- flex items-center justify-center"
          onClick={alignCenter}
        >
          <img className="max-w-full w-5" src={icon_alignCenter} alt="" />
        </button>
        <button
          className="text-black font-bold rounded w-8 h-8 flex items-center justify-center"
          onClick={alignRight}
        >
          <img className="max-w-full w-5" src={icon_alignRight} alt="" />
        </button>
        <button
          className="text-black font-bold rounded w-8 h- flex items-center justify-center"
          onClick={alignJustify}
        >
          <img className="max-w-full w-5" src={icon_alignJustify} alt="" />
        </button>
      </div>
      <div className="flex justify-center overflow-auto lg-max:max-h-includeBack lg:max-h-includeBack sm-max:h-includeToggle pl-0 pr-1 pb-20 pt-0">
        <div
          className="mentu-editor preview w-full h-full"
          contentEditable
          dangerouslySetInnerHTML={{ __html: cleanTableHTML(htmlContent) }}
          onInput={handleContentEditable}
        />
      </div>
      {false && (
        <button
          onClick={exportToDocx}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Exportar a Word
        </button>
      )}
    </div>
  );
};

export default DefaultEditor;
