import { useEffect, useState } from "react";
/* Styles */
import './toggleSwitch.css';

const ToggleSwitch = ({ name, onChange, value }) => {
  const [toggle, setToggle] = useState(true);
  const toggleClass = 'toggle-active';
  const onToogle = () => {
    onChange(!toggle)
    setToggle(!toggle);
  }

  useEffect(() => {
    const localLang = localStorage.getItem('lang');
    if(localLang){
      setToggle(localLang === 'en');
    } else { setToggle(false); }
  },[])

  return (
    <div
      className={`toggle-switch ${toggle ? 'eng' : 'esp'}`}
      onClick={onToogle}
    >
      <div className={`toggle-inner ${toggle ? null : toggleClass}`}></div>
    </div>
  );
}

export default ToggleSwitch;