import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import "./textInput.css";
import { useTranslation } from 'react-i18next';

const TextInput2 = ({
  label,
  labelInstruction,
  name,
  placeHolder,
  maxLength,
  required,
  minLength,
  type = "text",
  disabled = false,
  customClassName = "",
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const {t} = useTranslation()
  const selectedValue = watch(name);
  useEffect(() => {
    if (type === "number" && maxLength && Number(selectedValue) > maxLength) {
      setValue(name, maxLength);
    }
    if (type === "number" && minLength && Number(selectedValue) < minLength) {
      setValue(name, minLength);
    }
    // eslint-disable-next-line
  }, [selectedValue, maxLength, minLength, type]);
  const className = "input-text";

  return (
    <div
      className={classNames(className, customClassName)}
    >
      <div className="w-full grow">
        {label && <label className="input-label">{label}</label>}
        {labelInstruction && (
          <label className="input-instruction">{labelInstruction}</label>
        )}
      </div>
      <input
        className="input-box w-full"
        type={type}
        placeholder={placeHolder}
        maxLength={maxLength}
        disabled={disabled}
        {...register(name, {
          required: required && t("requiredField"),
        })}
      />
      {errors[name] && (
        <span className="input-error">{errors[name].message}</span>
      )}
    </div>
  );
};

export default TextInput2;
