import {
  GENERAL_ENG,
  GENERAL_SPANISH,
  PERU_COUNTRY_ID,
  PERU_SPANISH,
} from "./constans";

const langHandler = (userInfo) => {
  // User lang
  if (userInfo.country && userInfo.country === PERU_COUNTRY_ID) {
    return PERU_SPANISH;
  }

  // Institutions lang
  const findPeruInstitution = userInfo?.institutions?.find(
    (e) => e?.country === PERU_COUNTRY_ID
  );
  if (findPeruInstitution) return PERU_SPANISH;

  if (
    !userInfo ||
    !userInfo.country ||
    !userInfo.institutions ||
    userInfo.institutions.length <= 0
  ) {
    return GENERAL_SPANISH;
  }

  if (localStorage.getItem("lang") === GENERAL_ENG) {
    return GENERAL_ENG;
  }

  return GENERAL_SPANISH;
};

export default langHandler;
