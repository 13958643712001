import client from "../config/axios";

export const getAllGradeLevels = async () => {
  const response = await client.get("/grade-level");
  return response.data;
};

export const getAllGradeLevelsByUser = async (user) => {
  const response = await client.get(`/grade-level/user/${user}`);
  return response.data;
};

export const createGradeLevelSingle = async (data) => {
  const response = await client.post("/grade-level/single", data);
  return response.data;
};
