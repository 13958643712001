import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
/* Components */
import { useFormContext } from "react-hook-form";
import Popup from "../../popup/popup";
import useSessionStore from '../../../store/useSessionStore';

const DaySelector = ({ label, labelInstruction, selectedData, selectedGradeLevel }) => {
  const { setValue } = useFormContext();
  const [selectedDays, setSelectedDays] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t } = useTranslation();

  const currentDays = [
    {
      day: t("selector_dias_lunes"),
      translation: "selector_dias_lunes",
      duration: "",
      comment: "",
      checked: false,
      order: 1,
    },
    {
      day: t("selector_dias_martes"),
      translation: "selector_dias_martes",
      duration: "",
      comment: "",
      checked: false,
      order: 2,
    },
    {
      day: t("selector_dias_miercoles"),
      translation: "selector_dias_miercoles",
      duration: "",
      comment: "",
      checked: false,
      order: 3,
    },
    {
      day: t("selector_dias_jueves"),
      translation: "selector_dias_jueves",
      duration: "",
      comment: "",
      checked: false,
      order: 4,
    },
    {
      day: t("selector_dias_viernes"),
      translation: "selector_dias_viernes",
      duration: "",
      comment: "",
      checked: false,
      order: 5,
    },
    {
      day: t("selector_dias_sabado"),
      translation: "selector_dias_sabado",
      duration: "",
      comment: "",
      checked: false,
      order: 6,
    },
  ]

  const addDay = (days) => {
    const existingDays = [...selectedDays];
    days.forEach((newDay) => {
      const index = existingDays.findIndex((day) => day.day === newDay.day);
      if (index > -1) {
        existingDays[index] = { ...newDay };
      } else {
        existingDays.push({ ...newDay });
      }
    });
    setSelectedDays(existingDays);
    setModalIsOpen(false);
  };

  const removeDay = (e, day) => {
    e.preventDefault();
    const updatedDays = selectedDays.filter((d) => d.day !== day);
    setSelectedDays(updatedDays);
    setValue("calendario", updatedDays);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setModalIsOpen(true);
  };

  useEffect(() => {
    setValue("calendario", selectedDays);
  }, [selectedDays, setValue]);

  useEffect(() => {
    if (selectedData.length) {
      const parseDays = selectedData.map(e => {
        const findDay = currentDays.find(d => d.day === e.day)
        return { ...e, day: findDay.day, translation: findDay.translation }
      })
      setValue("calendario", parseDays);
      setSelectedDays(parseDays);
    }
    // eslint-disable-next-line
  }, [selectedData]);

  return (
    <>
      <div className="input-text">
        {label && <label className="input-label">{label}</label>}
        {labelInstruction && (
          <label className="input-instruction">{labelInstruction}</label>
        )}
        <div className="w-full overflow-x-auto">
          <div className="flex flex-wrap gap-2 justify-center">
            {selectedDays
              .sort((a, b) => a.order - b.order)
              .map((day, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center py-1 px-4 rounded-full border border-solid border-LightBlue-500 bg-LightBlue-500/50 h-10 text-3.5 leading-3.5 text-Grey-500"
                >
                  <span className="px-2">{t(day.translation)}</span>
                  <button
                    className="text-Red-500 font-bold"
                    onClick={(e) => removeDay(e, day.day)}
                  >
                    X
                  </button>
                </div>
              ))}
            <button
              className="button-tertiary-rounded"
              onClick={(e) => handleOpen(e)}
            >
              {t(
                selectedDays?.length
                  ? "selector_dias_boton_ver_calendario"
                  : "selector_dias_boton_agregar"
              )}
            </button>
          </div>
        </div>
      </div>
      <DayModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        addDay={addDay}
        selectedDays={selectedDays}
        setSelectedDays={setSelectedDays}
        selectedGradeLevel={selectedGradeLevel}
      />
    </>
  );
};

const DayModal = ({
  isOpen,
  onRequestClose,
  addDay,
  selectedDays,
  setSelectedDays,
  selectedGradeLevel
}) => {
  const [requiredDays, setRequiredDays] = useState([]);
  const [isCesa, setIsCesa] = useState(false);
  const { t } = useTranslation();
  const { user } = useSessionStore();

  useEffect(() => {
    if (user?.institutionalUser) {
      const isCesaInst =
        user.institutionalUser.subscription.institution.name ===
        "CESA - Colegio de Estudios Superiores de Administración";
      setIsCesa(isCesaInst);
    }

    // eslint-disable-next-line
  }, [user]);
 
  const [days, setDays] = useState([
    {
      day: t("selector_dias_lunes"),
      translation: "selector_dias_lunes",
      duration: "",
      comment: "",
      checked: false,
      order: 1,
    },
    {
      day: t("selector_dias_martes"),
      translation: "selector_dias_martes",
      duration: "",
      comment: "",
      checked: false,
      order: 2,
    },
    {
      day: t("selector_dias_miercoles"),
      translation: "selector_dias_miercoles",
      duration: "",
      comment: "",
      checked: false,
      order: 3,
    },
    {
      day: t("selector_dias_jueves"),
      translation: "selector_dias_jueves",
      duration: "",
      comment: "",
      checked: false,
      order: 4,
    },
    {
      day: t("selector_dias_viernes"),
      translation: "selector_dias_viernes",
      duration: "",
      comment: "",
      checked: false,
      order: 5,
    },
    {
      day: t("selector_dias_sabado"),
      translation: "selector_dias_sabado",
      duration: "",
      comment: "",
      checked: false,
      order: 6,
    },
  ]);

  const handleCheck = (index) => {
    const newDays = [...days];
    if (newDays[index].checked) {
      newDays[index] = {
        ...newDays[index],
        checked: false,
        duration: "",
        comment: "",
      };
      const currentSelectedDays = selectedDays.filter(
        (e) => e.day !== newDays[index].day
      );
      const newRequiredDays = [...requiredDays].filter(e => e !== newDays[index].day);
      setRequiredDays(newRequiredDays)
      setSelectedDays(currentSelectedDays);
    } else {
      newDays[index].checked = true;
    }
    setDays(newDays);
  };

  const handleChange = (index, field, value) => {
    const newDays = [...days];
    newDays[index][field] = value;
    setDays(newDays);
  };

  const handleObjConstructor = (e) => {
    e.preventDefault();
    const currentDays = days.filter((day) => day.checked && !day.duration);
    if (currentDays.length) {
      const stringDays = currentDays.map((e) => e.day);
      setRequiredDays(stringDays);
      return;
    }

    const selected = days
      .filter((day) => day.checked)
      .map((day) => ({
        day: day.day,
        duration: day.duration,
        comment: day.comment,
        order: day.order,
        translation: day.translation,
      }));
    addDay(selected);
    onRequestClose();
  };

  useEffect(() => {
    const updatedDays = days.map((day) => {
      const found = selectedDays.find(
        (selectedDay) => selectedDay.day === day.day
      );
      return {
        ...day,
        duration: found ? found.duration : "",
        comment: found ? found.comment : "",
        checked: !!found,
      };
    });

    setDays(updatedDays);
    // eslint-disable-next-line
  }, [selectedDays]);

  useEffect(() => {
  setDays([
    {
      day: t("selector_dias_lunes"),
      translation: "selector_dias_lunes",
      duration: "",
      comment: "",
      checked: false,
      order: 1,
    },
    {
      day: t("selector_dias_martes"),
      translation: "selector_dias_martes",
      duration: "",
      comment: "",
      checked: false,
      order: 2,
    },
    {
      day: t("selector_dias_miercoles"),
      translation: "selector_dias_miercoles",
      duration: "",
      comment: "",
      checked: false,
      order: 3,
    },
    {
      day: t("selector_dias_jueves"),
      translation: "selector_dias_jueves",
      duration: "",
      comment: "",
      checked: false,
      order: 4,
    },
    {
      day: t("selector_dias_viernes"),
      translation: "selector_dias_viernes",
      duration: "",
      comment: "",
      checked: false,
      order: 5,
    },
    {
      day: t("selector_dias_sabado"),
      translation: "selector_dias_sabado",
      duration: "",
      comment: "",
      checked: false,
      order: 6,
    },
  ])
  },[t])

  return (  
    <Popup
      isOpen={isOpen}
      onClose={onRequestClose}
      customClassName={"!max-w-[75%] !max-h-[95%]"}
    >
      <div className="w-full h-full flex flex-col relative">
        <label className="input-label mb-2 pb-4 text-center">
          {t("selector_dias_titulo_modal")}
        </label>
        <label className="input-instruction mb-6">
          {t(isCesa && selectedGradeLevel && selectedGradeLevel.label === "Pregrado" ? "selector_dias_titulo_modal_instruccion_pregrado_cesa" : "selector_dias_titulo_modal_instruccion")}
        </label>
        <div className="flex flex-row gap-2 items-start mb-4">
          <label className="input-label max-w-30 text-center">
            {t("calendario_selector_dia_label")}
          </label>
          <label className="input-label w-full text-center">
            {t("calendario_selector_duracion_label")}
          </label>
          <label className="input-label w-full text-center">
            {t("calendario_selector_comentario_label")}
          </label>
        </div>
        <div className="flex flex-col gap-4">
          {days.map((day, index) => (
            <div key={index} className="flex flex-row gap-2 items-start">
              <label className="input-box min-w-36 flex-row gap-2 justify-start">
                <input
                  className=""
                  type="checkbox"
                  name={day.day}
                  checked={day.checked}
                  onChange={() => handleCheck(index)}
                />
                {day.day}
              </label>
              <div className='w-full' >
              <input
                className={`input-box w-full ${
                  !day.checked ? "opacity-30" : ""
                }`}
                type="text"
                placeholder="Ej. 2 horas"
                value={day.duration}
                onChange={(e) =>
                  handleChange(index, "duration", e.target.value)
                }
                disabled={!day.checked}
              />
              {requiredDays.includes(day.day) && (
                  <div className="text-Red-500 pt-1 font-bold text-left text-sm">
                    {t("campo_requerido")}
                  </div>
                )}
              </div>
              <textarea
                className={`input-box min-h-[40px] h-[40px] w-full text-sm leading-3.5 ${
                  !day.checked ? "opacity-30" : ""
                }`}
                placeholder={t("selector_dias_comentario_placeholder")}
                value={day.comment}
                onChange={(e) => handleChange(index, "comment", e.target.value)}
                disabled={!day.checked}
              />
            </div>
          ))}
        </div>
        <div className="w-full flex items-center justify-center mt-6">
          <button
            className="button button-primary"
            onClick={(e) => handleObjConstructor(e)}
          >
            {t("selector_dias_boton_aceptar")}
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default DaySelector;
