import { create } from "zustand";

const useChatGPTStore = create((set) => ({
  isFirsTimePlan: false,
  plan: "",
  messages: [],
  context: null,
  history: [],
  formObject: [],
  formObjectEDAS: [],
  outputMaterial: null,
  setMessages: (value) => set({ messages: value }),
  setContext: (value) => set({ context: value }),
  setHistory: (value) => set({ history: value }),
  setFormObject: (value) => set({ formObject: value }),
  setFormObjectEDAS: (value) => set({ formObjectEDAS: value }),
  setPlan: (value) => set({ plan: value }),
  setIsFirsTimePlan: (value) => set({ isFirsTimePlan: value }),
  setOutputMaterial: (value) => set({ outputMaterial: value }),
  setClear: () =>
    set({
      messages: [],
      context: null,
      history: [],
      formObject: [],
      outputMaterial: null,
      isFirsTimePlan: false,
      plan: "",
    }),
}));

export default useChatGPTStore;
