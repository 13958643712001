import {useState, useEffect, useCallback} from "react";

const useLoggedUserInfo = () => {
    const [currentUserInfo, setCurrentUserInfo] = useState({});

    const checkUserData = useCallback(() => {
        const item = localStorage.getItem('user');
        if (item)
            setCurrentUserInfo(JSON.parse(item))
    }, []);

    useEffect(() => {
        checkUserData();
        window.addEventListener('storage', checkUserData);

        return () => {
            window.removeEventListener('storage', checkUserData);
        }
    }, [checkUserData]);

    return currentUserInfo;
};

export default useLoggedUserInfo;