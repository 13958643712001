import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
const SelectStyles = {
  control: (base) => ({
    ...base,
    backgroundColor: "rgb(182 182 182 / 0.25)",
    borderColor: "transparent",
    borderRadius: "4px",
    caretColor: "black",
  }),
  input: (provided) => ({
    ...provided,
    color: "black",
    fontWeight: "bold",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#F2F2F2" : "#FEFEFE",
    color: "black", // Asegura que el texto sea negro
    cursor: "pointer",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#FEFEFE",
    "&:hover": {
      color: "#3FB2E3",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#fefefe",
    fontWeight: "bold",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "black",
    ":hover": {
      color: "black",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#FEFEFE", // Fondo blanco para el menú
    color: "black",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#FEFEFE", // Fondo blanco para la lista de opciones
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#a0aec0", // Color de placeholder
  }),
};
const SelectInput = ({
  name,
  label,
  labelInstruction,
  options,
  required,
  customClassName = "",
  isDefaultValue = false,
  placeholder = "",
  onChange = null
}) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { control, setValue } = useFormContext();
  const { t } = useTranslation();
  useEffect(() => {
    if (isInitialLoad && isDefaultValue && options?.length) {
      setValue(name, {
        label: options[0].label,
        value: options[0].value,
      });
      setIsInitialLoad(false);
    }
    // eslint-disable-next-line
  }, [options, isDefaultValue, isInitialLoad]);
  return (
    <div className={classNames("input-text", customClassName)}>
      {label && <label className="input-label">{label}</label>}
      {labelInstruction && (
        <label className="input-instruction">{labelInstruction}</label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{ required: required && t("campo_requerido") }}
        render={({ field }) => (
          <Select
            {...field}
            name={name}
            options={options}
            placeholder={placeholder}
            classNamePrefix="react-select"
            className="react-select-container"
            styles={SelectStyles}
            onChange={(selectedOption) => {
              setValue(name, selectedOption, {
                shouldValidate: true,
              });
              if(onChange) onChange()
            }}
          />
        )}
      />
    </div>
  );
};

export default SelectInput;
