import icon from '../../assets/icons/credit.svg';
import classNames from "classnames";

const IconCredit = ({ creditNumber, customClass }) => {
  return (
    <div className={classNames(`relative w-10 h-10 mb-1`, customClass)}>
      <img className='w-full' src={icon} alt='' />
      <span className='text-xs text-shaiaBlue font-extrabold text-center w-3 absolute bottom-2.5 left-2.5'>{creditNumber}</span>
    </div>
  );
};

export default IconCredit;