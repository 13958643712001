import React, { useState } from 'react';
import './icon.css';
import classNames from 'classnames';


// Tooltip
const Tooltip = ({ text }) => (
  <div className="tooltip">
    {text}
  </div>
);

// Icon
const Icon = ({ icon, tooltipText, iconText, onClick, disabled, customClassName = '', iconClass, disabledToolTip = false }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <button 
      className={classNames("icon-container",customClassName)}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onClick={onClick}
      disabled={disabled}
    >
      {!disabledToolTip && showTooltip && <Tooltip text={tooltipText} />}
      <img src={icon} className={iconClass} alt='' />
      <p className='icon-text'>{iconText}</p>
    </button>
  );
};

export default Icon;