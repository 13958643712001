import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import HeaderImage from "../assets/pdfHeaderImg";
import HeaderImageCESA from "../assets/institution/pdfHeaderImgCESA";

import HeaderImageCESAPNG from "../assets/institution/header-cesa.png";

import {
  Document as MSDocument,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  Packer,
  WidthType,
  TableLayoutType,
  Header,
  ImageRun,
  HeadingLevel,
} from "docx";
import { saveAs } from "file-saver";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const notAllowRoutesToCheckToken = [
  "/login",
  "/login-institution",
  "/onboarding-teacher",
  "/confirmation",
  "/register",
  "/register-hubspot",
  "/recovery-password",
  "/change-password",
];

export const htmlToPlainText = (html) => {
  let text = html
    .replace(/<br\s*\/?>/gi, "\n") // Reemplaza <br> con salto de línea
    .replace(/<\/p>/gi, "\n") // Añade un salto de línea al final de cada párrafo
    .replace(/<li>/gi, "\n• ") // Reemplaza <li> con un punto de viñeta y salto de línea
    .replace(/<\/li>/gi, "") // Elimina </li>
    .replace(/<\/ul>/gi, "\n") // Añade un salto de línea al final de las listas desordenadas
    .replace(/<\/ol>/gi, "\n") // Añade un salto de línea al final de las listas ordenadas
    .replace(/<h[1-6]>/gi, "\n") // Añade un salto de línea antes de cada encabezado
    .replace(/<\/h[1-6]>/gi, "\n") // Añade un salto de línea después de cada encabezado
    .replace(/<[^>]+>/g, ""); // Elimina el resto de las etiquetas HTML

  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const htmlToMarkdown = (html) => {
  let markdown = html
    .replace(/<br\s*\/?>/gi, "  \n") // Convierte <br> en dos espacios y salto de línea
    .replace(/<\/p>/gi, "\n\n") // Dos saltos de línea al final de cada párrafo
    .replace(/<li>/gi, (match) =>
      html.match(/<ul[^>]*>[\s\S]*?<\/ul>/g) &&
      html.match(/<ul[^>]*>[\s\S]*?<\/ul>/g).includes(match)
        ? "* "
        : "1. "
    ) // Convierte <li> en listas de Markdown
    .replace(/<\/li>/gi, "") // Elimina </li>
    .replace(/<\/ul>/gi, "\n") // Añade un salto de línea al final de listas desordenadas
    .replace(/<\/ol>/gi, "\n") // Añade un salto de línea al final de listas ordenadas
    .replace(/<h([1-6])>/gi, (match, level) => "\n" + "#".repeat(level) + " ") // Convierte <h1> - <h6> en encabezados de Markdown
    .replace(/<\/h[1-6]>/gi, "\n") // Añade un salto de línea después de los encabezados
    .replace(/<[^>]+>/g, "") // Elimina el resto de las etiquetas HTML
    .replace(/<em>/gi, "*") // Convierte <em> en asteriscos para énfasis en Markdown
    .replace(/<\/em>/gi, "*"); // Convierte </em> en asteriscos para cerrar énfasis

  return markdown;
};

function htmlToPdfmake(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const tableLayout = {
    hLineWidth: function (i, node) {
      return i === 0 || i === node.table.body.length ? 1 : 0.5;
    },
    vLineWidth: function (i, node) {
      return i === 0 || i === node.table.widths.length ? 1 : 0.5;
    },
    hLineColor: function (I, node) {
      return I === 0 || I === node.table.body.length ? "#f2f2f2" : "#cccccc";
    },
    vLineColor: function (i, node) {
      return i === 0 || i === node.table.widths.length ? "#f2f2f2" : "#cccccc";
    },
    paddingLeft: function (i, node) {
      return 6;
    },
    paddingRight: function (i, node) {
      return 6;
    },
    paddingTop: function (i, node) {
      return 6;
    },
    paddingBottom: function (i, node) {
      return 6;
    },
    fillColor: function (rowIndex, node, columnIndex) {
      return rowIndex === 0 ? "#434964" : null;
    },
  };

  function adjustFontSize(htmlFontSize) {
    return htmlFontSize * (3 / 4);
  }

  function walkNode(node) {
    const nodeName = node.nodeName.toLowerCase();
    let content = [];

    if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== "") {
      return { text: node.textContent.trim(), bold: false };
    }

    if (nodeName === "br") {
      return "\n";
    }

    if (
      nodeName === "table" ||
      (nodeName === "div" && node.classList.contains("table-wrapper"))
    ) {
      const table = nodeName === "table" ? node : node.querySelector("table");
      if (table) {
        let tableBody = [];
        const numberOfColumns = table.rows[0]?.cells.length || 0;
        if (!numberOfColumns) return null;

        let columnWidths = new Array(numberOfColumns).fill("auto");
        const headerText =
          table.rows[0].cells[numberOfColumns - 1]?.textContent.trim();
        if (headerText === "Comentarios") {
          columnWidths[columnWidths.length - 1] = 200;
        }

        Array.from(table.rows).forEach((row, rowIndex) => {
          let rowData = [];
          Array.from(row.cells).forEach((cell) => {
            const cellContent = cell.textContent.trim();
            const cellData =
              rowIndex === 0
                ? { text: cellContent, style: "tableHeader" }
                : { text: cellContent, style: "tableBody" };
            rowData.push(cellData);
          });

          // Asegurarse de que todas las filas tengan el mismo número de celdas
          while (rowData.length < numberOfColumns) {
            rowData.push({ text: "", style: "tableBody" });
          }

          if (rowData.length > 0) {
            tableBody.push(rowData);
          }
        });

        return {
          table: {
            headerRows: 1,
            widths: columnWidths,
            body: tableBody,
          },
          layout: tableLayout,
        };
      }
    }

    Array.from(node.childNodes).forEach((childNode) => {
      let childContent = walkNode(childNode);

      if (node.nodeName.toLowerCase() === "strong") {
        childContent.bold = true;
        if (
          typeof childContent.text === "string" &&
          childContent.text.endsWith(":")
        ) {
          childContent.text += " ";
        }
        if (
          typeof childContent.text === "string" &&
          childContent.text.match(/\d+\.$/)
        ) {
          childContent.text += " ";
        }
      }

      if (childContent) {
        content.push(childContent);
      }
    });

    if (content.length === 0) {
      return null;
    }

    content = content.reduce((acc, item) => {
      if (typeof acc[acc.length - 1] === "string" && typeof item === "string") {
        return [...acc.slice(0, -1), acc[acc.length - 1] + item];
      }
      return [...acc, item];
    }, []);

    switch (nodeName) {
      case "h1":
      case "h2":
      case "h3":
      case "h4":
        return {
          text: content,
          fontSize: adjustFontSize(
            { h1: 24, h2: 22, h3: 20, h4: 18 }[nodeName]
          ),
          bold: true,
          margin: [0, 24, 0, 10],
        };
      case "p":
        return {
          text: content.map((item) => {
            if (typeof item === "string" || item instanceof String) {
              return { text: item, bold: false };
            }
            return item;
          }),
          fontSize: adjustFontSize(16),
          margin: [0, 8, 0, 8],
        };
      case "strong":
        return {
          text: content,
          fontSize: adjustFontSize(16),
          bold: true,
        };
      case "ul":
        return {
          ul: content.map((item) =>
            typeof item === "string" ? { text: item } : item
          ),
          margin: [20, 0, 0, 8],
        };
      case "ol":
        return {
          ol: content,
          margin: [20, 0, 0, 8],
        };
      case "li":
        return {
          stack: [{ text: content }],
          fontSize: adjustFontSize(16),
          margin: [20, 8, 0, 8],
        };
      default:
        return content.length > 0 ? content : "";
    }
  }

  const elements = walkNode(doc.body);

  if (elements && elements.length > 0 && elements[0].margin) {
    elements[0].margin = [
      elements[0].margin[0],
      20,
      elements[0].margin[2],
      elements[0].margin[3],
    ];
  } else if (elements && elements.length > 0) {
    elements[0].margin = [0, 20, 0, 0];
  }

  return elements;
}

export const htmlToPdf = (
  labelText,
  name,
  institutionH,
  htmlContent = null,
  download = false
) => {
  let contentHtml = htmlContent || document.querySelector(".preview").innerHTML;

  if (htmlContent) {
    contentHtml = contentHtml.replace(/<td>\s*<\/td>/g, "");
  }

  const calculateRectangleWidth = (text) => {
    const baseWidth = 50; // Ancho base en puntos.
    const widthPerCharacter = 5; // Ancho adicional por carácter.
    return baseWidth + text.length * widthPerCharacter;
  };

  const getHeaderImage = () => {
    if (institutionH === "CESA") {
      return HeaderImageCESA;
    }
    return HeaderImage;
  };

  const docDefinition = {
    info: {
      title: "",
      author: "",
      subject: "",
      keywords: "",
    },
    pageSize: "Letter",
    pageMargins: [30, 120, 30, 80], // [izquierda, arriba, derecha, abajo]
    header: function (currentPage, pageCount, pageSize) {
      const rectangleWidth = calculateRectangleWidth(labelText);
      return {
        margin: [40, 20, 40, 0],
        stack: [
          {
            image: getHeaderImage(),
            width: 540,
          },
          {
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 0,
                w: rectangleWidth,
                h: 20,
                r: 4,
                color: "#434964",
              },
            ],
            margin: [0, 5, 0, 10],
          },
          {
            columns: [
              {
                width: rectangleWidth,
                text: labelText,
                style: "labelStyle",
                alignment: "center",
              },
            ],
            margin: [0, -25, 0, 10], // Ajusta para mover el texto hacia arriba sobre el rectángulo del `canvas`
          },
        ],
        columnGap: 10,
      };
    },
    footer: function (currentPage, pageCount) {
      // Obtener la fecha actual
      const today = new Date();
      // Formatear la fecha
      const formattedDate =
        today.getFullYear() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getDate();
      return {
        margin: [40, 20, 40, 20], // [izquierda, arriba, derecha, abajo]
        stack: [
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 5,
                x2: 515,
                y2: 5,
                lineWidth: 1,
                lineColor: "black",
              },
            ],
            margin: [0, 0, 0, 10],
          },
          {
            text: "",
            margin: [0, 5, 0, 0],
          },
          {
            columns: [
              {
                text: `Creado por: ${name}`,
                alignment: "center",
                fontSize: 10,
                margin: [4, 0],
              },
              {
                text: `Fecha de creación: ${formattedDate}`,
                alignment: "center",
                fontSize: 10,
                margin: [0, 0],
              },
              {
                text: "Visita nuestro sitio web",
                color: "#359ED5",
                decoration: "underline",
                alignment: "center",
                fontSize: 10,
                link: "https://mentu.co/shaia",
                bold: true,
                margin: [4, 0],
              },
            ],
          },
        ],
      };
    },
    content: htmlToPdfmake(contentHtml),
    styles: {
      headerSpacer: {
        //
      },
      labelStyle: {
        fontSize: 10,
        bold: true,
        color: "white",
      },
      tableHeader: {
        bold: true,
        color: "white",
        fontSize: 10,
      },
      tableBody: {
        fontSize: 8,
      },
    },
  };
  if (htmlContent) {
    if (download) {
      pdfMake.createPdf(docDefinition).download("documento.pdf");
    }
    return new Promise((resolve, reject) => {
      pdfMake.createPdf(docDefinition).getBlob((blob) => {
        resolve(blob);
      });
    });
  } else {
    pdfMake.createPdf(docDefinition).download("documento.pdf");
  }
};

export const getHistoryTitle = (content, indicator) => {
  // Esta expresión regular maneja espacios opcionales y diferentes formatos del patrón del título.
  // Utiliza '^' para indicar que el patrón debe estar al inicio de la línea.
  const tituloRegex = /^(?:###\s*|##\s*|#\s*|)\s*Título\s*(.+?)(?=\n|$)/m;
  const match = content.match(tituloRegex);

  if (match && match[1]) {
    return match[1].trim();
  }
  return null;
};

export const getHistorySystemUser = (history) => {
  let currentUserMessage = null;
  if (history) {
    const currentHistory = history.find((e) => {
      const currentMemoryArray = JSON.parse(e.memory_array);
      const findUserMessage = currentMemoryArray.find(
        (e) => e?.role === "user" || e?.type === "human"
      );
      return !!findUserMessage;
    });
    if (currentHistory) {
      const historyMessages = JSON.parse(currentHistory.memory_array);
      const findUserMessage = historyMessages?.find(
        (e) => e?.role === "user" || e?.type === "human"
      );
      const currentHistoryUserMessage =
        findUserMessage?.content || findUserMessage?.data?.content;
      if (currentHistoryUserMessage) {
        currentUserMessage = currentHistoryUserMessage;
      }
    }
  }
  return currentUserMessage;
};

export const parseForm = (text) => {
  const keyValuePairs = {};
  const reference = text.includes("Context:") ? "  " : "\n";
  const lines = text.split(reference);

  // Procesa cada línea
  lines.forEach((line) => {
    // Intenta encontrar pares clave-valor separados por ':'
    if (text.includes("contextoUsuario:") && line.includes(":")) {
      const [key, value] = line.split(":").map((part) => part.trim());
      keyValuePairs[key] = value;
    } else {
      // Si no hay ':', trata de dividir por dos espacios, como en el formato original
      const segments = line.split("  ");
      segments.forEach((segment) => {
        if (segment.trim() !== "") {
          const [key, value] = segment.split(":").map((part) => part.trim());
          keyValuePairs[key] = value;
        }
      });
    }
  });

  return keyValuePairs;
};

export const getBuildForm = (formContext, questions) => {
  const extractedText = formContext.includes("```")
    ? formContext.match(/```([\s\S]*?)```/)[1]
    : formContext;
  const contextObject = parseForm(extractedText);
  const parsedQuestions = Object.keys(contextObject).reduce((acc, key, i) => {
    const findCurrentQuestion = questions.find((e) => e.question === key);
    if (findCurrentQuestion) {
      return [
        ...acc,
        {
          field: findCurrentQuestion.field,
          value: contextObject[key],
          order: findCurrentQuestion?.order ? findCurrentQuestion.order : i + 1,
        },
      ];
    }
    return acc;
  }, []);

  return parsedQuestions;
};

export const parseCompetences = ({
  grade,
  subject,
  competence,
  capability,
  performance,
  data,
}) => {
  const returnObj = {
    competences: [],
    capabilities: [],
    performances: [],
  };

  if (!grade && !subject) {
    return returnObj;
  }
  const competences = data;

  // debugger;
  const competencesFilteredByGrade = competences.filter(
    (c) => c.grade_level.id === grade && c.subject_area.id === subject
  );

  if (!competence) {
    returnObj.competences = competencesFilteredByGrade.map((c) => c.competence);
    returnObj.competences = [...new Set(returnObj.competences)];
    return returnObj;
  }

  returnObj.competences = [competence];
  returnObj.capabilities = competencesFilteredByGrade
    .filter((c) => c.competence === competence)
    .map((c) => c.capability);
  returnObj.capabilities = [...new Set(returnObj.capabilities)];

  if (!capability) {
    return returnObj;
  }

  returnObj.capabilities = [capability];
  returnObj.performances = competencesFilteredByGrade
    .filter((c) => c.competence === competence && c.capability === capability)
    .map((c) => c.performance);
  returnObj.performances = [...new Set(returnObj.performances)];

  if (!performance) {
    return returnObj;
  }

  returnObj.performances = [performance];
  return returnObj;
};

export const cleanTableHTML = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const tables = doc.querySelectorAll("table");

  tables.forEach((table) => {
    const cells = table.querySelectorAll("th, td");
    cells.forEach((cell) => {
      if (!cell.textContent.trim()) {
        cell.remove();
      }
    });

    const rows = table.querySelectorAll("tr");
    rows.forEach((row) => {
      const cells = row.querySelectorAll("th, td");
      if (cells.length === 0) {
        row.remove();
      }
    });
  });

  return doc.body.innerHTML;
};

export const converTextMarkdown = (markdownText) => {
  let processedText = markdownText;

  // Convierte los títulos que terminan en ':' en negrita
  processedText = processedText.replace(
    /^(?!#+\s)(.*?:)$/gm,
    (match, title) => `<strong>${title}</strong>\n`
  );

  // Convierte texto en negrita
  processedText = processedText.replace(
    /\*\*(.*?)\*\*/g,
    (match, text) => `<strong>${text}</strong>`
  );

  // Busca y procesa bloques de listas con guiones

  processedText = processedText.replace(
    /^[\s]*\-\s+(.+)/gm, // eslint-disable-line
    (match, text) => `<li class="editor-li">${text.trim()}</li>`
  );

  // Agrega las etiquetas <ul> alrededor de los elementos <li> no ordenados
  processedText = processedText.replace(
    /(<li class="editor-li">.*?<\/li>)/gs,
    (match) => `<ul>\n${match}\n</ul>`
  );

  // Procesa listas numeradas
  processedText = processedText.replace(
    /^[\s]*(\d+)\.\s+(.+)/gm,
    (match, number, text) =>
      `<li style="list-style: none">${number}. ${text.trim()}</li>`
  );

  // Agrega las etiquetas <ol> alrededor de los elementos <li> numerados
  processedText = processedText.replace(
    /(<li class="editor-li-numbers">.*?<\/li>)/gs,
    (match) => `<ol>\n${match}\n</ol>`
  );

  // Convierte las opciones de las preguntas de opción múltiple con salto de línea después de cada opción
  processedText = processedText.replace(
    /^(\w\)) ([^\n]+)/gm,
    (match, bullet, text) =>
      `<div class="editor-list-options-letters">  ${bullet} ${text}</div>\n`
  );
  processedText = processedText.replace(
    /^(\d+\.) ([^\n]+)/gm,
    (match, bullet, text) =>
      `<div class="editor-list-options-numbers">  ${bullet} ${text}</div>\n`
  );

  // Procesa tablas eliminando celdas vacías
  processedText = processedText.replace(
    /<table>(.*?)<\/table>/gs,
    (match, tableContent) => {
      // Eliminar celdas vacías
      const cleanedTable = tableContent.replace(
        /<t[dh][^>]*>\s*<\/t[dh]>/g,
        ""
      );
      // Eliminar filas que están completamente vacías después de quitar las celdas vacías
      const cleanedRows = cleanedTable.replace(
        /<tr>(\s*|\s*<\/?t[dh][^>]*>)*<\/tr>/g,
        ""
      );
      return `<div className="table-wrapper"><table>${cleanedRows}</table></div>`;
    }
  );

  // Elimina líneas vacías entre las etiquetas <ul> y <ol>
  processedText = processedText
    .replace(/<\/ul>\s+<ul>/g, "</ul><ul>")
    .replace(/<\/ol>\s+<ol>/g, "</ol><ol>");

  return processedText;
};

const cleanTableHTML1 = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const tables = doc.querySelectorAll("table");

  tables.forEach((table) => {
    // Asegura que cada tabla tenga un ancho del 100%
    table.style.width = "100%";

    const cells = table.querySelectorAll("th, td");
    cells.forEach((cell) => {
      cell.style.width = "1000px";
      if (!cell.textContent.trim()) {
        cell.remove();
      }
    });

    const rows = table.querySelectorAll("tr");
    rows.forEach((row) => {
      row.style.width = "1000px";
      const cells = row.querySelectorAll("th, td");
      if (cells.length === 0) {
        row.remove();
      }
    });
  });

  return doc.body.innerHTML;
};

const getDocFontSize = (tagName) => {
  switch (tagName) {
    case "h1":
      return 24 * (3 / 4);
    case "h2":
      return 22 * (3 / 4);
    case "h3":
      return 20 * (3 / 4);
    case "h4":
      return 18 * (3 / 4);
    default:
      return 18 * (3 / 4);
  }
};

const addHorizontalRule = () => {
  return new Paragraph({
    children: [new TextRun(" ")], // TextRun vacío para asegurar que el párrafo ocupe espacio
    border: {
      bottom: {
        color: "auto",
        space: 1,
        value: "single",
        size: 6, // Tamaño del borde puede ser ajustado para hacer la línea más gruesa
      },
    },
    spacing: {
      after: 200, // Espacio después del HR para no pegar textos siguientes a la línea
    },
  });
};

const parseHtmlContent = (htmlContent) => {
  return htmlContent;
};

async function convertImageToBase64(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const exportToDocx = async () => {
  const content = document.querySelector(".preview").innerHTML;
  console.log("content ", content);
  const parser = new DOMParser();
  const modifiedContent = parseHtmlContent(content);

  const cleanedContent = cleanTableHTML1(modifiedContent);

  const htmlDoc = parser.parseFromString(cleanedContent, "text/html");
  const paragraphs = [];
  const fontConditions = ["H1", "H2", "H3", "H4"];

  Array.from(htmlDoc.body.children).forEach((el) => {
    if (fontConditions.includes(el.tagName)) {
      const fontSize = getDocFontSize(el.tagName); // Tamaño de fuente para H1 y H2

      paragraphs.push(
        new Paragraph({
          children: [
            new TextRun({
              text: el.innerText,
              size: fontSize * 2, // Tamaño en media puntos, por eso se multiplica por 2
              bold: true, // Asumiendo que quieres los títulos en negrita
            }),
          ],
          spacing: {
            after: ["H1", "H2", "H3"].includes(el.tagName) ? 240 : 0, // 12 puntos equivalen a 240 en la escala DXA de docx (20 puntos = 360 DXA)
            before: ["H1", "H2", "H3"].includes(el.tagName) ? 240 : 0, // 12 puntos equivalen a 240 en la escala DXA de docx (20 puntos = 360 DXA)
          },
        })
      );
    } else if (["STRONG", "SPAN"].includes(el.tagName)) {
      paragraphs.push(
        new Paragraph({
          children: [
            new TextRun({
              text: el.innerText,
              bold: true,
            }),
          ],
        })
      );
    } else if (el.tagName === "HR") {
      paragraphs.push(addHorizontalRule());
    } else if (el.tagName === "P") {
      handleParagraph(el, paragraphs);
    } else if (["UL", "OL"].includes(el.tagName)) {
      handleList(el, paragraphs, 0, true);
    } else if (el.tagName === "LI") {
      const previousElement = el.previousElementSibling;
      // Determina si necesitas resetear la numeración basado en el elemento anterior
      const shouldReset = !previousElement || previousElement.tagName !== "LI";
      if (previousElement && previousElement.tagName === "P") {
        paragraphs.push(
          new Paragraph({
            children: [],
            spacing: { before: 100 },
          })
        );
      }
      handleListItem(el, paragraphs, shouldReset, 0);
      // Continúa la numeración después de un <li>
    } else if (el.tagName === "TABLE") {
      paragraphs.push(
        new Paragraph({
          children: [],
          spacing: { before: 240 },
        })
      );
      handleTable(el, paragraphs);
      paragraphs.push(
        new Paragraph({
          children: [],
          spacing: { after: 240 },
        })
      );
    }
  });

  const header = await createHeader();
  const doc = createDocument(header, paragraphs);
  saveDocument(doc);
};

function handleParagraph(el, paragraphs) {
  const runs = [];
  el.childNodes.forEach((node) => {
    runs.push(
      new TextRun({
        text: node.textContent,
        bold: node.nodeName === "STRONG",
        italics: node.nodeName === "EM",
      })
    );
    if (node.nodeName === "BR") {
      runs.push(new TextRun({ text: "\n" }));
      paragraphs.push(new Paragraph({ children: runs.slice() }));
      runs.length = 0;
    }
  });
  if (runs.length > 0) {
    paragraphs.push(new Paragraph({ children: runs }));
  }
}

function handleList(el, paragraphs, level, reset) {
  // Si es una nueva lista, revisamos si necesitamos resetear la numeración
  const isNestedList = el.parentElement && el.parentElement.tagName === "LI";
  let resetNumbering = reset || !isNestedList;

  el.childNodes.forEach((li) => {
    if (li.nodeType === 1 && li.tagName === "LI") {
      handleListItem(li, paragraphs, resetNumbering, level);
      resetNumbering = false; // Después del primer <li>, no reiniciamos más
    }
  });
}

function handleListItem(el, paragraphs, reset, level = 0) {
  const matchesNumber = el.textContent.trim().match(/^\d+\.\s+/);
  if (matchesNumber) {
    paragraphs.push(
      new Paragraph({
        text: el.textContent,
      })
    );
  } else {
    paragraphs.push(
      new Paragraph({
        text: el.textContent,
        bullet: { level },
      })
    );
  }
}

function handleTable(el, paragraphs) {
  const totalWidth = 9000;
  const columnWidth = totalWidth / el.rows[0].cells.length;

  const headerFillColor = "#434964"; // Color oscuro para el fondo del encabezado
  const headerFontColor = "#FFFFFF"; // Blanco para el texto del encabezado
  const borderColor = "#FFFFFF";

  const tableRows = Array.from(el.rows).map((tr, rowIndex) => {
    const isHeader = rowIndex === 0;
    const cells = Array.from(tr.cells).map((td) => {
      const cellContent = Array.from(td.childNodes).map((node) => {
        // Aplicar color de texto blanco solo en las celdas del encabezado
        return new TextRun({
          text: node.textContent,
          bold: isHeader, // En negrita si es el encabezado
          color: isHeader ? headerFontColor : undefined, // Blanco para el encabezado, sin cambio para otras filas
        });
      });

      return new TableCell({
        children: [new Paragraph({ children: cellContent })],
        width: {
          size: columnWidth,
          type: WidthType.DXA,
        },
        shading: {
          fill: isHeader ? headerFillColor : "FFFFFF", // Aplicar color de fondo solo en el encabezado
          val: "clear",
          color: "auto",
        },
        borders: isHeader
          ? {
              top: { size: 2, color: borderColor },
              bottom: { size: 2, color: borderColor },
              left: { size: 2, color: borderColor },
              right: { size: 2, color: borderColor },
            }
          : undefined,
      });
    });

    return new TableRow({ children: cells });
  });

  paragraphs.push(
    new Table({
      rows: tableRows,
      width: {
        size: totalWidth,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.FIXED,
    })
  );
}
async function createHeader() {
  const headerImage = await convertImageToBase64(HeaderImageCESAPNG);
  return new Header({
    children: [
      new Paragraph({
        children: [
          new ImageRun({
            data: headerImage,
            transformation: { width: 630, height: 60 },
          }),
        ],
      }),
      new Paragraph({ text: "Syllabus", heading: HeadingLevel.HEADING_1 }),
      new Paragraph({ children: [new TextRun(" ")], spacing: { after: 50 } }),
    ],
  });
}

function createDocument(header, paragraphs) {
  return new MSDocument({
    creator: "Your Name",
    title: "My Document Title",
    description: "A detailed description of the document",
    sections: [
      {
        headers: { default: header },
        properties: {
          pageMargin: { top: 1440, right: 1440, bottom: 1440, left: 1440 },
        },
        children: paragraphs,
      },
    ],
    numbering: {
      config: [
        {
          reference: "numberingId",
          levels: [
            { level: 0, format: "decimal", text: "%1.", alignment: "left" },
            { level: 1, format: "bullet", text: "•", alignment: "left" },
          ],
        },
      ],
    },
  });
}

async function saveDocument(doc) {
  try {
    const blob = await Packer.toBlob(doc);
    saveAs(blob, "simpleOutput.docx");
  } catch (err) {
    console.error("Error creating document:", err);
  }
}
