import client from '../config/axios';

export const getAllSubjectArea = async () => {
    const response = await client.get("/subject-area");
    return response.data;
  }

  export const getAllSubjectAreasByUser = async (user) => {
    const response = await client.get(`/subject-area/user/${user}`);
    return response.data;
  };

  export const createSubjectAreaSingle = async (data) => {
    const response = await client.post("/subject-area/single", data);
    return response.data;
  };

  export const getAllSubjectAreasByAreas = async (user) => {
    const response = await client.get(`/subject-area/by-areas/${user}`);
    return response.data;
  };

  export const getAllSubjectAreasByInstitution = async (id) => {
    const response = await client.get(`/subject-area/institution/${id}`);
    return response.data;
  };
  