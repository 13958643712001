import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

/* Components */
import ShaiaBot from "../shaiaBot/shaiabot";

const Terms = () => {
  const { t } = useTranslation();
  const location = useLocation();

  if (location.pathname === "/") {
    return (
      <>
        <ShaiaBot />
        <p className="body-modal">
        {t('modal_disclaimer_texto1')} {t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span>, {t('modal_disclaimer_texto2')}
        </p>
        <h2 className="alt-h2">{t('modal_disclaimer_titulo')}</h2>
        <div className="ul-row">
          <ul className="ul-modal">
            <li>
              <span className="modal-label">{t('modal_disclaimer_inteligencia_aumentada_label')}</span>
              <p>{t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_inteligencia_aumentada_descripcion')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_humanos_al_mando_label')}</span>
              <p>{t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_humanos_al_mando_descripcion')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_privacidad_label')}</span>
              <p>{t('modal_disclaimer_privacidad_descripcion')}.</p>
            </li>
          </ul>
          <ul className="ul-modal">
            <li>
              <span className="modal-label">{t('modal_disclaimer_uso_responsable_label')}</span>
              <p>{t('modal_disclaimer_uso_responsable_descripcion1')} {t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_uso_responsable_descripcion2')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_limitaciones_label')}</span>
              <p>{t('modal_disclaimer_limitaciones_descripcionl1')} {t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_limitaciones_descripcionl2')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_version_preliminar_label')}</span>
              <p>{t('modal_disclaimer_version_preliminar_descripcion1')} {t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_version_preliminar_descripcion2')}.</p>
            </li>
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <>
        <ShaiaBot />
        <h2 className="alt-h2">{t('modal_disclaimer_titulo')}</h2>
        <div className="ul-row">
          <ul className="ul-modal">
            <li>
              <span className="modal-label">{t('modal_disclaimer_inteligencia_aumentada_label')}</span>
              <p>{t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_inteligencia_aumentada_descripcion')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_humanos_al_mando_label')}</span>
              <p>{t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_humanos_al_mando_descripcion')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_privacidad_label')}</span>
              <p>{t('modal_disclaimer_privacidad_descripcion')}.</p>
            </li>
          </ul>
          <ul className="ul-modal">
            <li>
              <span className="modal-label">{t('modal_disclaimer_uso_responsable_label')}</span>
              <p>{t('modal_disclaimer_uso_responsable_descripcion1')} {t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_uso_responsable_descripcion2')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_limitaciones_label')}</span>
              <p>{t('modal_disclaimer_limitaciones_descripcionl1')} {t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_limitaciones_descripcionl2')}.</p>
            </li>
            <li>
              <span className="modal-label">{t('modal_disclaimer_version_preliminar_label')}</span>
              <p>{t('modal_disclaimer_version_preliminar_descripcion1')} {t('modal_disclaimer_sha')}<span className="blue-text">{t('modal_disclaimer_ia')}</span> {t('modal_disclaimer_version_preliminar_descripcion2')}.</p>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default Terms;