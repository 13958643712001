import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
/* Styles */
import "./header.css";
/* Assets */
//import logo from '../../assets/logo_mentu.svg';
import logoM from "../../assets/logo_m.png";
import logoShaiaBN from "../../assets/azul-alt-bn.svg";
import iconArrowDown from "../../assets/icons/CaretDown.svg";
import UserIcon from "../../assets/icons/user_icon.svg";
import MenuIcon from "../../assets/icons/menu.svg";
import mixpanel from "mixpanel-browser";
/* Components */
import ToggleSwitch from "../form-elements/ToggleSwitch/toggleSwitch";
import { useTranslation } from "react-i18next";
import useUserStore from "../../store/useUser.store";
import { useMutation } from "react-query";
import { getUsage } from "../../services/useCaseUsage.service";
import useSessionStore from "../../store/useSessionStore";
import useUsageStore from "../../store/useUsageStore";
import PieChartProgress from "../PieChartProgress/PieChartProgress";
import Popup from "../popup/popup";
import IconCredit from "../icon/iconCredits";
import useChatGPTStore from "../../store/useChatGPT.store";
import useGlobalStore from "../../store/useGlobalStore";
import useInstitutionLang from "../../hooks/useInstitutionLang";
import classNames from "classnames";

const Header = ({ UserName, UserLetter, isInstitution }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const institutionDefaultLang = useInstitutionLang();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { setLang } = useUserStore();
  const { user, setClear } = useSessionStore();
  const {
    usageParams,
    setUsage,
    setClear: setClearUseCaseUsageStore,
  } = useUsageStore();
  const { setClear: setClearChatGTPStore } = useChatGPTStore();
  const userString = localStorage.getItem("user");
  const userObject = userString ? JSON.parse(userString) : "";
  const currentUser = user || userObject.first_name;

  const mutation = useMutation(getUsage, {
    onSuccess: (data) => {
      setUsage({
        total: data?.total,
        limit: data?.limit,
        percentage: data?.usagePercentage,
      });
      if (data?.limit && (data?.total < 0 || data?.total >= data?.limit)) {
        navigate("/");
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if (user) {
      mutation.mutate(user.email);
    }
    const isAdmin = user?.role?.length
      ? user?.role.some((e) => e.slug === "admin")
      : false;
    setIsAdmin(isAdmin);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  /* Dropdown menu function */
  const [isOpen, setIsOpen] = useState(false);
  const blockStyle = {
    transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onSwitch = (event) => {
    i18n.changeLanguage(event ? "en" : institutionDefaultLang);
    setLang(event ? "en" : institutionDefaultLang);
    localStorage.setItem("lang", event ? "en" : institutionDefaultLang);
  };

  const closeMenu = () => {
    mixpanel.track(
      "SHAIA_LOGOUT",
      {
        email: currentUser?.email,
      },
      {
        send_immediately: true,
      },
      () => {
        mixpanel.reset();
      }
    );
    i18n.changeLanguage("es");
    setLang("es");
    localStorage.clear();
    localStorage.setItem("lang", "es");
    navigate("/login");
    setIsOpen(false);
    setClear();
    setClearChatGTPStore();
    setClearUseCaseUsageStore();
  };

  const goToProfile = () => {
    navigate("/profile");
    setIsOpen(false);
  };

  const getColor = (percentage) => {
    if (usageParams.total < 1) return "counter-low";
    if (percentage >= 71 && percentage <= 100) return "counter-low";
    if (percentage >= 31 && percentage <= 70) return "counter-mid";
    if (percentage >= 0 && percentage <= 30) return "counter-full";
  };

  const amount = usageParams.limit - usageParams.total;

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const pathnamesSidebar = ["/", "/course-profile"];

  //Sidebar function
  const { isOpenSidebar, setIsOpenSidebar } = useGlobalStore();
  const toggleSidebar = () => setIsOpenSidebar(!isOpenSidebar);

  const showCounter = () => {
    if (currentUser?.institutionalUser) {
      if(currentUser.institutionalUser?.is_fremium){
        return true
      }
      return false
    }
    return true;
  };


  return (
    <header className="header-navbar">
      {(!pathnamesSidebar.includes(location.pathname) ||
        windowSize.innerWidth <= 576) && (
        <div
          className={classNames("header-navbar_logo sm-max:w-28 ", {
            "w-[20%]": !pathnamesSidebar.includes(location.pathname),
            "w-full": pathnamesSidebar.includes(location.pathname),
          })}
        >
          <Link to={"/"}>
            {windowSize.innerWidth <= 576 ? (
              <img src={logoM} className="logo" alt="Mentu" />
            ) : (
              <img src={logoShaiaBN} className="logo" alt="Mentu" />
            )}
          </Link>
          {/* Logo institucional */}
          {currentUser?.institutionalUser && (
            <div className="max-w-[50%] max-h-16 flex-1">
              <img
                src={
                  currentUser.institutionalUser?.subscription?.institution
                    ?.logo_white
                }
                className="max-w-full max-h-full"
                alt="Logo"
              />
            </div>
          )}
        </div>
      )}
      <div className="header-navbar_rigth">
        {showCounter() && (
          <div
            className={`navbar_counter ${getColor(usageParams.percentage)}`}
            onClick={() => togglePopup()}
          >
            {windowSize.innerWidth <= 576 ? (
              <div className="navbar_counter-text">{`${t(
                "usoCreditosText1"
              )}: ${usageParams.total < 1 ? usageParams.limit : amount}`}</div>
            ) : (
              <div className="flex flex-col gap-1">
                <div className="navbar_counter-text">{`${t(
                  "usoCreditosText1"
                )} ${usageParams.total < 1 ? usageParams.limit : amount} ${t(
                  amount === 1 ? "usoCreditosText2" : "usoCreditosText2_1"
                )} ${t("usoCreditosText3")}.`}</div>
                <div className="navbar_counter-text underline">
                  {t("header_creditos_como_funciona")}
                </div>
              </div>
            )}
            <div>
              <PieChartProgress
                progress={
                  usageParams.total < 1
                    ? usageParams.limit
                    : usageParams.percentage
                }
              />
            </div>
          </div>
        )}
        {windowSize.innerWidth > 576 && (
          <>
            <div className="navbar_toggle">
              <ToggleSwitch name="lang" onChange={onSwitch} />
            </div>
            <div className="navbar_menu">
              <div className="header-navbar_user" onClick={toggleMenu}>
                {windowSize.innerWidth <= 576 ? (
                  ""
                ) : (
                  <div className="user-name">{currentUser?.email}</div>
                )}
                <span className="user-firstLetter">
                  <img src={UserIcon} alt="" />
                </span>
                <span className="user-arrow" style={blockStyle}>
                  <img src={iconArrowDown} alt="" />
                </span>
              </div>
              {isOpen && (
                <ul className="dropdown-list">
                  <li onClick={goToProfile}>{t("my_profile")}</li>
                  {isAdmin ? <li onClick={() => navigate("/")}>Home</li> : null}
                  {isAdmin ? (
                    <li onClick={() => navigate("/admin")}>Admin</li>
                  ) : null}
                  <li onClick={closeMenu}>{t("logout")}</li>
                </ul>
              )}
            </div>
          </>
        )}
        {windowSize.innerWidth <= 576 && (
          <div className="navbar_menu">
            <span onClick={toggleSidebar}>
              <img src={MenuIcon} className="menu" alt="Menu" />
            </span>
          </div>
        )}
      </div>
      <Popup isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="flex flex-col text-Grey-500 max-h-full overflow-y-auto">
          <h4 className="text-Grey-500 mb-6">⭐ {t("popupCreditos_h4")}</h4>
          <p>{t("popupCreditos_parrafo1")}</p>
          <p>{t("popupCreditos_parrafo2")}</p>
          <div className="mb-4">
            <ul className="flex flex-row sm-max:flex-col gap-2 items-center justify-center px-4">
              <li className="flex-1 flex flex-col gap-1 items-center justify-center text-center text-DarkBlue-500 font-bold text-3.5 leading-4 px-2 py-1 bg-shaiaBlue/70 rounded-2">
                <IconCredit creditNumber="5" />
                {t("popupCreditos_Listado_item1")}
              </li>
              <li className="flex-1 flex flex-col gap-1 items-center justify-center text-center text-DarkBlue-500 font-bold text-3.5 leading-4 px-2 py-1 bg-shaiaBlue/70 rounded-2">
                <IconCredit creditNumber="5" />
                {t("popupCreditos_Listado_item2")}
              </li>
              <li className="flex-1 flex flex-col gap-1 items-center justify-center text-center text-DarkBlue-500 font-bold text-3.5 leading-4 px-2 py-1 bg-shaiaBlue/70 rounded-2">
                <IconCredit creditNumber="30" />
                {t("popupCreditos_Listado_item3")}
              </li>
            </ul>
          </div>
          <p>{t("popupCreditos_parrafo3")}</p>
        </div>
      </Popup>
    </header>
  );
};

export default Header;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
