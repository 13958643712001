import { formatDistanceToNow } from "date-fns";
import HourWatch from "../../assets/icons/watch_later.svg";
import { es } from "date-fns/locale";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';

const UseCaseHistory = ({
  history,
  sessionId,
  isView,
  toggleView,
  showUseCase = false,
  containerClassName = "",
  stringLimit = "",
}) => {
  const { i18n } = useTranslation()
  const list = history
    .filter((e) => {
      const messages = JSON.parse(e.memory_array);
      const findStage3 = messages.find(
        (e) =>
          (e.role === "user" || e.role === "human") &&
          e.content?.includes("<stage_3>")
      );
      return e.superPower.slug === "abp3" ? !findStage3 : e;
    })
    .filter((e) => e.plan && e.memory_array)
    .filter((e) => (sessionId ? e.session_id !== sessionId : true))
    .sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB - dateA;
    })
    .map((h, i) => {
      const timeAgo = formatDistanceToNow(h.created_at, {
        addSuffix: true,
        locale: es,
      });
      const regexTitles =
        /^(###\s*Título:\s*|##\s*Título:\s*|#\s*Título:\s*|\*\*Título\*\*:\s*|Título:\s*)/;
      let title = h?.plan;
      title = h.plan.replace(regexTitles, "");
      title = title.replace("#", "");
      title = title.replace("##", "");
      title = title.replace("###", "");
      title = title.replace("###", "");
      title = title.replace("**", "");
      title = title.replace("---", " ");
      title = title.replace(":**", ":");
      title = title.replace(":***", ":");
      title = title.replace("<recursos_pedagógicos_adicionales>", "");
      if (title.includes("<stage_4>") || title.includes("<title>")) {
        const regex = /<(stage_4|title)>(.*?)<\/(stage_4|title)>/s;
        const match = title.match(regex);
        if (match) {
          const newContent = match[2].trim();
          title = newContent;
        }
      }
      let tag = '';
      if(h?.superPower?.translations?.length){
        const superPowerTranslation = h.superPower.translations.find(e => e.locale.code === i18n.language);
        if(superPowerTranslation) tag = superPowerTranslation.tag
      }
      return (
        <li
          className={`sidebar-button ${isView === `item_${i}` ? "active" : ""}`}
          onClick={() => toggleView(`item_${i}`, h)}
          key={i}
        >
          <div className="history_text">
            {showUseCase && (
              <p className="sidebar-time_tag">{tag}</p>
            )}
            <p className="sidebar-time_label">{stringLimit ? title.substring(0, stringLimit) : title.substring(0, 40)}...</p>
            <div className="sidebar-button_sublabel">
              <span className="sidebar-button_icon">
                <img src={HourWatch} alt="" />
              </span>
              <p className="history_time">{timeAgo}</p>
            </div>
          </div>
        </li>
      );
    });

  return (
    <div
      className={classNames(
        "overflow-y-auto w-full sm-max:h-full pr-4",
        containerClassName
      )}
    >
      <ul className="sidebar-buttons">{list}</ul>
    </div>
  );
};

export default UseCaseHistory;
