import { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';

const useWebSocket = (url) => {
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const socketIo = io(url);
    setSocket(socketIo);

    socketIo.on('AssetListenerChannel', (message) => {
      console.log('Data received:', message);
    });

    socketIo.on('IndexUriResponse', (message) => {
        console.log('IndexUriResponse Data received:', message);
        setData(message);
      });

    return () => {
      socketIo.disconnect();
    };
  }, [url]);

  const send = useCallback((event, message) => {
    if (socket) {
      socket.emit(event, message);
    }
  }, [socket]);

  // Función para enviar mensajes periódicos
  const startPeriodicSend = useCallback((event, classProfileId, selectedAssetId, interval ) => {
    const timerId = setInterval(() => {
      send(event, { classProfileId, selectedAssetId });
    }, interval);
    
    return () => clearInterval(timerId);  // Retorna una función para detener los mensajes
  }, [send]);

  const closeConnection = useCallback(() => {
    if (socket) {
      socket.disconnect();
    }
  }, [socket]);


  return { data, send, startPeriodicSend, closeConnection };
};

export default useWebSocket;
