import React from "react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import classNames from "classnames";

const SubjectAreaSelection = ({ updateStep, disabled, data }) => {
  const [list, setList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const { setValue } = useFormContext();

  useEffect(() => {
    if(data && data.length){
      setList(data);
    }
  }, [data]);

  const onChange = (item) => {
    setSelectedItem(item);
    setValue("selectedSubjectArea", item);
    updateStep(3);
  };

  return (
    <>
      <div
        className={classNames("flex flex-wrap gap-4 xl:px-10", {
          "pointer-events-none": disabled,
          "opacity-50": disabled,
        })}
      >
        {list?.map((course, i) => (
          <label
            key={i}
            className={`py-2 px-4 rounded-2 text-3.5 flex flex-row gap-4 cursor-pointer hover:bg-DarkBlue-700 hover:text-Grey-500 ${
              selectedItem?.id === course.id
                ? "text-Grey-500 bg-DarkBlue-700"
                : "text-Grey-500/50 bg-DarkBlue-700/20"
            }`}
          >
            <input
              className="hidden"
              type="radio" // Cambiado a tipo radio
              value={course.name}
              checked={selectedItem?.id === course.id}
              onChange={() => onChange(course)}
            />
            {course.name}
            <span
              className={`${
                selectedItem?.id === course.id
                  ? "text-Yellow-500"
                  : "text-Grey-500/50"
              }`}
            >
              &#x2714;
            </span>
          </label>
        ))}
      </div>
    </>
  );
};

export default SubjectAreaSelection;
