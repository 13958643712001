import React from "react";
import { useEffect, useState } from "react";
import useSessionStore from "../../../../store/useSessionStore";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import Popup from "../../../../components/popup/popup";
import FormCourseProfile from "../../../../components/form/formCourseProfile";
import AddItem from "../../../../assets/icons/add_file.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const CourseSelection = ({ updateStep, disabled, api }) => {
  const [isCreateProfile, setIsCreateProfile] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null); // Estado para el curso seleccionado
  const { user } = useSessionStore();
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const level = queryParams.get("level");

  const mutationGetAvailableClassProfileToIndexDocument = useMutation(api, {
    onSuccess: (data) => {
      const parsedCourses = data.map((e) => ({ ...e, name: e.class_name }));
      setCourseData(parsedCourses);
      setIsCreateProfile(false);
    },
    onError: (error) => {},
  });

  useEffect(() => {
    if(user){
      mutationGetAvailableClassProfileToIndexDocument.mutate({
        email: user?.email,
        level
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const reloadList = () => {
    mutationGetAvailableClassProfileToIndexDocument.mutate({
      email: user?.email,
      level
    });
  };

  const handleCourseChange = (course) => {
    setSelectedCourse(course); // Establece el curso seleccionado
    setValue("courses", course); // Asegura que se actualiza el formulario con el nuevo curso
    updateStep(2); // Suponiendo que seleccionar un curso te lleva siempre al paso 2
  };

  const togglePopup = () => {
    setIsCreateProfile((prev) => !prev);
  };
  let createCourseButton = t("chat_lista_cursos_boton_crear_curso");
  if(level === 'pregrado'){
    createCourseButton = t("chat_lista_cursos_boton_crear_curso_cesa_pregrado")
  }
  if(level === 'posgrado'){
    createCourseButton = t("chat_lista_cursos_boton_crear_curso_cesa_posgrado")
  }
  return (
    <>
      <div
        className={classNames("flex flex-wrap gap-4 xl:px-10", {
          "pointer-events-none": disabled,
          "opacity-50": disabled,
        })}
      >
        {courseData?.map((course, i) => (
          <label
            key={i}
            className={`py-2 px-4 rounded-2 text-3.5 flex flex-row gap-4 cursor-pointer hover:bg-DarkBlue-700 hover:text-Grey-500 ${
              selectedCourse?.id === course.id
                ? "text-Grey-500 bg-DarkBlue-700"
                : "text-Grey-500/50 bg-DarkBlue-700/20"
            }`}
          >
            <input
              className="hidden"
              type="radio" // Cambiado a tipo radio
              value={course.name}
              checked={selectedCourse?.id === course.id}
              onChange={() => handleCourseChange(course)}
            />
            {course.name}
            <span
              className={`${
                selectedCourse?.id === course.id
                  ? "text-Yellow-500"
                  : "text-Grey-500/50"
              }`}
            >
              &#x2714;
            </span>
          </label>
        ))}
        <button
          type="button"
          onClick={togglePopup}
          className="py-2 px-4 rounded-2 text-3.5 flex flex-row gap-4 items-center justify-center cursor-pointer bg-DarkBlue-700 text-LightBlue-500 font-bold"
        >
          <span className="w-6 h-6">
            <img src={AddItem} alt="Agregar" className="max-w-full" />
          </span>
          {createCourseButton}
        </button>
      </div>
      <Popup
        isOpen={isCreateProfile}
        onClose={togglePopup}
        customClassName={isCreateProfile && "!max-w-[95%] !max-h-[95%]"}
      >
        <FormCourseProfile
          selectedRow={null}
          reloadList={reloadList}
          showTitle
        />
      </Popup>
    </>
  );
};

export default CourseSelection;
