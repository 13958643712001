
import mixpanel from 'mixpanel-browser';

export const InitMixPanel = () => {
    // For Testing Debug in true
    /* eslint-disable */
    let tokenTest = '3a6feea065bf9dc6002220504b668526';
    /* eslint-disable */
    const tokenProd = '75522f276bb4e4420aa5024e1cc00e6a'
    const debug = false;
   mixpanel.init(tokenProd,{
      debug,
      loaded: () => console.log(`MixPanel initializated. debug : ${debug}`),
    });
}

