import React from 'react';
import Popup from "../../../../components/popup/popup";

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;
  
    return (
      <Popup
        isOpen={isOpen}
        onClose={onClose}
      >
        <p className="text-Grey-500">
          ¿Estás seguro de que deseas eliminar el archivo y reemplazarlo?
        </p>
        <div className="flex flex-wrap gap-4">
          <button
            className="flex-1 w-full button button-primary-outline"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            className="flex-1 w-full button button-primary"
            onClick={onConfirm}
          >
            Confirmar
          </button>
        </div>
      </Popup>
    );
  };

  export default ConfirmationModal;