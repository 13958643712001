import client from '../config/axios';

export const getInstitutions = async ({page = 1, name = ''}) => {
    const response = await client.get(`/institution`, { params: { page, name } });
    return response.data;
  }
  

  export const getInstitutionsByName = async (name) => {
    const response = await client.get(`/institution/search/${name}`);
    return response.data;
  }

  export const storeInstitutionForm = async (data) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    const response = await client.post('/institution',data, config);
    return response.data;
  }

  export const storeInstitution = async (data) => {
    const response = await client.post('/institution/single',data);
    return response.data;
  }

  export const updateInstitution = async ({id, body}) => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    const response = await client.patch(`/institution/${id}`,body, config);
    return response.data;
  }

  export const sendInvitations = async (data) => {
    const response = await client.post('/institution/send-invitations',{ ...data });
    return response.data;
  }


  export const removeInvitations = async (data) => {
    const response = await client.post('/institution/remove-invitation',{ ...data });
    return response.data;
  }

  export const generateCodes = async (data) => {
    const response = await client.post('/institution/generate-codes',{ ...data });
    return response.data;
  }


  export const sendAllInvitations = async (data) => {
    const response = await client.post('/institution/send-all-invitations',{ ...data });
    return response.data;
  }
