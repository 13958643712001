import { create } from 'zustand';

// SuperPowerStore
const useCaseStore = create((set) => ({
    useCaseList: [],
    activityMessages: [],
    setActivityMessages: (value) => set({ activityMessages: value }),
    activityUserContext: "",
    setActivityUserContext: (value) => set({ activityUserContext: value }),
    activityForm: [],
    setActivitForm: (value) => set({ activityForm: value }),
    classMessages: [],
    setClassMessages: (value) => set({ classMessages: value }),
    classUserContext: "",
    setClassUserContext: (value) => set({ classUserContext: value }),
    classForm: [],
    setClassForm: (value) => set({ classForm: value }),
    rubricMessages: [],
    setRubricMessages: (value) => set({ rubricMessages: value }),
    rubricUserContext: "",
    setRubricUserContext: (value) => set({ rubricUserContext: value }),
    rubricForm: [],
    setRubricForm: (value) => set({ rubricForm: value }),
    contextUseCaseMessages: [],
    setContextUseCaseMessages: (value) => set({ contextUseCaseMessages: value }),
    contextUseCaseUserContext: "",
    setContextUserContext: (value) => set({ contextUseCaseUserContext: value }),
    contextForm: [],
    setContextForm: (value) => set({ contextForm: value }),
    warmupUseCaseMessages: [],
    setWarmupUseCaseMessages: (value) => set({ warmupUseCaseMessages: value }),
    warmupUseCaseUserContext: "",
    setWarmupUserContext: (value) => set({ warmupUseCaseUserContext: value }),
    warmupForm: [],
    setWarmupForm: (value) => set({ warmupForm: value }),
    assesMessages: [],
    setAssesMessages: (value) => set({ assesMessages: value }),
    assesUserContext: "",
    setAssesUserContext: (value) => set({ assesUserContext: value }),
    assesForm: [],
    setAssesForm: (value) => set({ assesForm: value }),
    abpMessages: [],
    setAbpMessages: (value) => set({ abpMessages: value }),
    abpUserContext: "",
    setAbpUserContext: (value) => set({ abpUserContext: value }),
    bloomPerformanceUseCaseMessages: [],
    setBloomPerformanceUseCaseMessages: (value) => set({ bloomPerformanceUseCaseMessages: value }),
    bloomPerformanceUseCaseUserContext: "",
    setBloomPerformanceUserContext: (value) => set({ bloomPerformanceUseCaseUserContext: value }),
    bloomPerformanceForm: [],
    setBloomPerformanceForm: (value) => set({ bloomPerformanceForm: value }),
    readingComprehensionUseCaseMessages: [],
    setReadingComprehensionUseCaseMessages: (value) => set({ readingComprehensionUseCaseMessages: value }),
    readingComprehensionUseCaseUserContext: "",
    setReadingComprehensionUserContext: (value) => set({ readingComprehensionUseCaseUserContext: value }),
    readingComprehensionForm: [],
    setReadingComprehensionForm: (value) => set({ readingComprehensionForm: value }),
    criticalThinkingUseCaseMessages: [],
    setCriticalThinkingUseCaseMessages: (value) => set({ criticalThinkingUseCaseMessages: value }),
    criticalThinkingUseCaseUserContext: "",
    setCriticalThinkingUserContext: (value) => set({ criticalThinkingUseCaseUserContext: value }),
    criticalThinkingForm: [],
    setCriticalThinkingForm: (value) => set({ criticalThinkingForm: value }),
    setUseCaseList: (value) => set({ useCaseList: value })
}));

export default useCaseStore;