import client, { openAiClient } from '../config/axios';

export const updateTeacherSession = async (param) => {
  console.log('param ', param);
  const data = { plan: param.plan };
    const response = await client.put(`/teacher-session/${param.id}`, {  ...data  });
    return response.data;
  }


  export const getSessionsByUseCase = async (useCase = '') => {
    const response = await client.get(`/teacher-session/use-case-list/${useCase}`);
    return response.data;
  }

  export const getTeacherSessions = async () => {
    const response = await client.get("/teacher-session/history/list");
    return response.data;
  }

  export const getSessionByUseCase = async (useCase = '') => {
    const response = await client.get(`/teacher-session/use-case/${useCase}`);
    return response.data;
  }


  export const getSessionById = async (id) => {
    const response = await client.get(`/teacher-session/${id}`);
    return response.data;
  }

  export const getIteractionById = async (id) => {
    const response = await openAiClient.get(`/interactions/${id}`);
    const mapperMessages = response.data.data.messages.map(msgs => ({
      role: msgs.role, content: msgs.content[0]?.text?.value 
    }))
    return {
      data: {
        ...response.data.data,
      messages: mapperMessages,
      superPower: response.data.data.super_power
      }
    };
  }

  