import client from '../config/axios';

export const getUsage = async (email) => {
    const response = await client.get(`/use-case-usage/${email}`);
    return response.data;
  }

  export const storeUsage = async (data) => {
    const response = await client.post("/use-case-usage",{...data});
    return response.data;
  }
  