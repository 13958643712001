/* Components */
import { useTranslation } from 'react-i18next';
import Button from '../button/button';
/* Styles */
import './modal.css';

function Modal({ isOpen, onClose, children }) {
  const { t } = useTranslation();
  const modalStyle = {
    display: isOpen ? 'flex' : 'none',
  };

  return (
    <div style={modalStyle} className='modal-wrapper'>
      <div className='modal-wrapper_content'>
        <div className='content-scroll'>
          {children}
          <div className='modal_CTA'>
            <Button
              label={t('modal_boton')}
              variant="primary"
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
