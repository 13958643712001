import React, { useState, useEffect } from "react";
import classNames from "classnames";
/* Styles */
import "./popup.css";
/* Assets */
import iconClose from "../../assets/icons/close.svg";

const Popup = ({ isOpen, onClose, children, customClassName }) => {
  const [shouldRender, setShouldRender] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    }
  }, [isOpen]);

  const handleClose = (e) => {
    e.preventDefault();
    setShouldRender(false);
    onClose(e);
  };

  if (!shouldRender) return null;

  return (
    <div className={`popup-overlay ${isOpen ? "open" : ""}`}>
      <div className={classNames("popup-content ", customClassName)}>
        {onClose && (
          <button
            type="button"
            className="absolute right-[10px] cursor-pointer p-4 rounded-4 bg-transparent top-[-7px]"
            onClick={handleClose}
          >
            <img src={iconClose} alt="" />
          </button>
        )}
        <div className="w-full h-full overflow-y-auto p-4 sm-max:p-0">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
