/* Styles */
import './modal3.css';

function Modal3({ isOpen, onClose, children, customStyles = {} }) {
  const modalStyle = {
    display: isOpen ? 'flex' : 'none'
  };

  return (
    <div style={modalStyle} className='modal-wrapper3'>
      <div className='modal-wrapper3_content border-white border-2' style={customStyles} >
        <button
          onClick={onClose}
          className='absolute top-1 right-4 text-white font-bold text-xl cursor-pointer'
          aria-label="close"
        >
          X
        </button>

        <div className='content-scroll w-full'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal3;
