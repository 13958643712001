import React, { useEffect, useState, useRef } from "react"; //eslint-disable-line
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { MIX_PANEL_EVENTS, SHAIA_API } from "../../../utils/constans"; //eslint-disable-line
import { Remarkable } from "remarkable";
import { useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { useMixPanel } from "../../../hooks/useMixPanel";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
/* Services */
import useChatGPTStore from "../../../store/useChatGPT.store";
import { fetchChatGPT } from "../../../services/chat.service";
import useCaseStore from "../../../store/useCaseStore";
import useGlobalStore from "../../../store/useGlobalStore";
import useWebSocket from "../../../hooks/useWebSocket";
import { storeAssetByCourseProfile } from "../../../services/asset.service";
import {
  getSessionById,
  getSessionsByUseCase,
} from "../../../services/teacherSession.service";
import useSessionStore from "../../../store/useSessionStore";
/* Components */
import UseCaseHistory from "../../../components/UseCaseHistory/UseCaseHistory";
import DefaultEditor from "../../../components/default-editor/defaultEditor";
import Button from "../../../components/button/button"; //eslint-disable-line
import { cleanTableHTML, htmlToPdf } from "../../../utils/Utils"; //eslint-disable-line
import Icon from "../../../components/icon/icon";
import CourseSelection from "./Class_Sequence/CourseSelection";
import ConfirmationModal from "./Class_Sequence/ConfirmationModal";
/* Assets */
import ShaiaCreate from "../../../assets/shaia_create.png";
import UserIcon from "../../../assets/icons/user_icon.svg";
import ArrowBack from "../../../assets/icons/arrow_back.svg";
import ChatIcon from "../../../assets/icons/chat.svg";
import History from "../../../assets/icons/watch_later.svg";
import HistoryEmpty from "../../../assets/icons/history_toggle_off.svg";
import PDFFile from "../../../assets/icons/pdf_icon.svg";
import DOCXFile from "../../../assets/icons/word_icon.svg";
/* Styles */
import "../../../components/chat/chat.css";
import classNames from "classnames";
import {
  getAvailableClassProfiles,
  getsessionByClassProfile,
  getsessionsByClassProfile,
} from "../../../services/profile-class.service";
import SubjectAreaSelection from "./Class_Sequence/SubjectAreaSelection";
import FileClassCesaUpload from "./FileClassCesaUpload";
import Popup from "../../../components/popup/popup";
import ClassProfieLibrary from "../../../components/ClassProfieLibrary/ClassProfieLibrary";
import useTimer from "../../../hooks/useTimer";

const DesignCESAClass = () => {
  const [showExtendedLoadingMessage, setShowExtendedLoadingMessage] =
    useState(false);
  const [history, setHistory] = useState([]);
  const [isView, setIsView] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stageMessage, setStageMessage] = useState(0);
  const [messagesStep1, setMessagesStep1] = useState([]);
  const [messagesStep2, setMessagesStep2] = useState([]);
  const [messagesStep3, setMessagesStep3] = useState([]);
  const [resMessages, setResMessages] = useState([]);
  const [historyById, setHistoryById] = useState(null);
  const [isEditorOn, setIsEditorOn] = useState(false);
  const [libraryModal, setLibraryModal] = useState(false);
  const [sessionsByClassProfile, setSessionsByClassProfile] = useState([]);
  const [currentCatedraContent, setCurrentCatedraContent] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const { gptSuccess, setCreditAnimateClear, isChatActive, setIsChatActive } =
    useGlobalStore();
  const methods = useForm();
  const { handleSubmit, watch, setValue, getValues } = methods;
  const navigate = useNavigate();
  const { sendEvent } = useMixPanel();
  const { useCaseList } = useCaseStore();
  const temporalLoadingRef = useRef(false);
  const parentSessionIdRef = useRef(null);
  const sessionIdRef = useRef(null);
  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);
  const stopSendingRef = useRef(null);
  const stepsRef = useRef(1);
  const { messages, setMessages } = useChatGPTStore();
  const isMobile = useMediaQuery({ maxWidth: 564 }); //eslint-disable-line
  const { t, i18n } = useTranslation(); //eslint-disable-line
  const watchedFile = watch("file");
  const selectedClassProfile = watch("courses");
  const selectedSubjectArea = watch("selectedSubjectArea");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("sessionId");
  const level = queryParams.get("level");
  const { user } = useSessionStore();
  const {
    data: notificationData,
    startPeriodicSend,
    closeConnection,
  } = useWebSocket(SHAIA_API);
  const { count, startTimer, resetTimer } = useTimer();

  const userInfoString = localStorage.getItem("user");
  const userInfo = JSON.parse(userInfoString || "{}");
  const fullName =
    `${user?.first_name || ""} ${user?.last_name || ""}`.trim() ||
    user?.email ||
    `${userInfo.first_name || ""} ${userInfo.last_name || ""}`.trim();

  const currentUseCase = useCaseList?.find(
    (e) => e.description === `cesa_sequence_setp_` //eslint-disable-line
  );
  const useCaseId = currentUseCase ? currentUseCase.id : null;
  const md = new Remarkable({
    html: true,
    breaks: true,
    typographer: true,
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    if (selectedClassProfile) {
      mutationGetSessionByClassProfile.mutate({
        email: user?.email,
        classProfileId: selectedClassProfile.id,
        superPower: `cesa_sequence_${level}_step_2`,
      });
      mutationGetSessionsByClassProfile.mutate({
        email: user?.email,
        classProfileId: selectedClassProfile.id,
        superPower: `cesa_sequence_${level}_step_2`,
      });
    }
    // eslint-disable-next-line
  }, [selectedClassProfile]);

  useEffect(() => {
    if (count > 30) {
      scrollToBottom();
      setShowExtendedLoadingMessage(true);
    }
  }, [count]);

  useEffect(() => {
    if (notificationData) {
      if (notificationData.status) {
        setStageMessage(2);
        closeConnection();
        const calendars = selectedClassProfile?.classProfileCalendars?.reduce(
          (acc, value) => {
            return `${acc}
            - Dia: ${value.day} | Duracion: ${value.duration} | Comentario: ${value.comment}
          `;
          },
          ""
        );
        const contentUser = `ContextoClase(
            Institución: ${selectedClassProfile?.institution?.name}
            Periodo académico: ${selectedClassProfile?.academicPeriod?.name}
            Nivel: ${
              selectedClassProfile?.gradeLevels?.length
                ? selectedClassProfile?.gradeLevels[0].name
                : ""
            }
            Asignatura: ${selectedSubjectArea?.name}
            Curso: ${selectedClassProfile?.class_name}
            ${
              calendars && level === "pregrado"
                ? `Calendario: 
              ${calendars}`
                : ""
            }
            )
        `;
        send(contentUser);
      }
      console.log("notificationData ", notificationData);
    }
    // eslint-disable-next-line
  }, [notificationData, closeConnection]);

  useEffect(() => {
    const selectedCourse = getValues("courses");
    console.log("notificationData ", notificationData);
    if (stageMessage === 1) {
      stopSendingRef.current = startPeriodicSend(
        "fileListener",
        selectedCourse?.id || null,
        selectedAssetId || null,
        5000
      );
    }

    return () => {
      if (stopSendingRef.current) {
        stopSendingRef.current();
      }
    };
    // eslint-disable-next-line
  }, [startPeriodicSend, stageMessage, getValues, notificationData]);

  useEffect(() => {
    if (watchedFile && watchedFile.length > 0) {
      setFile(watchedFile[0]);
      setStep(4);
    }
  }, [watchedFile]);

  const teacherSessionMutation = useMutation(getSessionsByUseCase, {
    onSuccess: (res) => {
      setHistory(res);
    },
    onError: () => {},
  });

  const mutationGetSessionByClassProfile = useMutation(
    getsessionByClassProfile,
    {
      onSuccess: (data) => {
        if (data?.content) {
          setCurrentCatedraContent(data.content);
        }
      },
      onError: (error) => {},
    }
  );

  const mutationGetSessionsByClassProfile = useMutation(
    getsessionsByClassProfile,
    {
      onSuccess: (data) => {
        if (data?.data) {
          setSessionsByClassProfile(data.data);
        }
      },
      onError: (error) => {},
    }
  );

  const getFileIcon = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (fileExtension === "docx") {
      return DOCXFile;
    }
    return PDFFile;
  };

  const updateStep = (newStep) => {
    setStep(newStep);
  };

  const handleRemoveFileClick = () => {
    setIsModalOpen(true);
  };

  const confirmRemoveFile = () => {
    setFile(null);
    setValue("file", []);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setStep(2);
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const showChat = () => {
    setIsChatActive(!isChatActive);
  };

  const hiddenChat = () => {
    setIsChatActive(!isChatActive);
  };

  const convertListMarkdown = (markdownText) => {
    let processedText = markdownText;

    // Convierte los títulos que terminan en ':' en negrita
    processedText = processedText.replace(
      /^(?!#+\s)(.*?:)$/gm,
      (match, title) => `<strong>${title}</strong>\n`
    );

    // Convierte texto en negrita
    processedText = processedText.replace(
      /\*\*(.*?)\*\*/g,
      (match, text) => `<strong>${text}</strong>`
    );

    // Busca y procesa bloques de listas con guiones

    processedText = processedText.replace(
      /^[\s]*\-\s+(.+)/gm, // eslint-disable-line
      (match, text) => `<li class="editor-li">${text.trim()}</li>`
    );

    // Agrega las etiquetas <ul> alrededor de los elementos <li> no ordenados
    processedText = processedText.replace(
      /(<li class="editor-li">.*?<\/li>)/gs,
      (match) => `<ul>\n${match}\n</ul>`
    );

    // Procesa listas numeradas
    processedText = processedText.replace(
      /^[\s]*(\d+)\.\s+(.+)/gm,
      (match, number, text) =>
        `<li style="list-style: none">${number}. ${text.trim()}</li>`
    );

    // Agrega las etiquetas <ol> alrededor de los elementos <li> numerados
    processedText = processedText.replace(
      /(<li class="editor-li-numbers">.*?<\/li>)/gs,
      (match) => `<ol>\n${match}\n</ol>`
    );

    // Convierte las opciones de las preguntas de opción múltiple con salto de línea después de cada opción
    processedText = processedText.replace(
      /^(\w\)) ([^\n]+)/gm,
      (match, bullet, text) =>
        `<div class="editor-list-options-letters">  ${bullet} ${text}</div>\n`
    );
    processedText = processedText.replace(
      /^(\d+\.) ([^\n]+)/gm,
      (match, bullet, text) =>
        `<div class="editor-list-options-numbers">  ${bullet} ${text}</div>\n`
    );

    // Procesa tablas eliminando celdas vacías
    processedText = processedText.replace(
      /<table>(.*?)<\/table>/gs,
      (match, tableContent) => {
        // Eliminar celdas vacías
        const cleanedTable = tableContent.replace(
          /<t[dh][^>]*>\s*<\/t[dh]>/g,
          ""
        );
        // Eliminar filas que están completamente vacías después de quitar las celdas vacías
        const cleanedRows = cleanedTable.replace(
          /<tr>(\s*|\s*<\/?t[dh][^>]*>)*<\/tr>/g,
          ""
        );
        return `<div className="table-wrapper"><table>${cleanedRows}</table></div>`;
      }
    );

    // Elimina líneas vacías entre las etiquetas <ul> y <ol>
    processedText = processedText
      .replace(/<\/ul>\s+<ul>/g, "</ul><ul>")
      .replace(/<\/ol>\s+<ol>/g, "</ol><ol>");

    return processedText;
  };

  const transformCatedraContentToPDF = async () => {
    if (currentCatedraContent) {
      const md = new Remarkable({
        html: true, // Permite HTML en el input
        breaks: true, // Convierte '\n' en <br>
        typographer: true, // Habilita algunas sustituciones tipográficas
      });
      const parsedText = convertListMarkdown(currentCatedraContent);
      let html = md.render(parsedText);
      const pdfFile = await htmlToPdf("Clase CESA", fullName, "CESA", html);
      const formStoreAssetData = new FormData();
      formStoreAssetData.append("asset_file", pdfFile, "catedra.pdf");
      formStoreAssetData.append(
        "class_profile_id",
        selectedClassProfile?.id || null
      );
      setStep(5);
      mutationStoreSuperPower.mutate(formStoreAssetData);
    }
  };

  const mutationStoreSuperPower = useMutation(storeAssetByCourseProfile, {
    onSuccess: (data) => {
      setSelectedAssetId(data?.length ? data[0].id : null);
      setStageMessage(1);
    },
    onError: (error) => {},
  });

  const proceedToNextStep = () => {
    const formDataToSend = new FormData();
    const file = getValues("file");
    const selectedCourse = getValues("courses");
    if (file[0] instanceof File) {
      formDataToSend.append("asset_file", file[0]);
    }
    formDataToSend.append("class_profile_id", selectedCourse?.id || null);
    formDataToSend.append(
      "super_power",
      `Clase_Curso_${selectedCourse.class_name.trim()}`
    );
    mutationStoreSuperPower.mutate(formDataToSend);

    setStep(5);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, step, file]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const createMarkup = (markdown) => {
    const rawMarkup = md.render(markdown);
    return { __html: rawMarkup };
  };

  const conditions = [
    "<stage_1>",
    "<stage_2>",
    "<stage_3>",
    "contextoUsuario",
    "ContextoClase",
  ];

  const getMessagesByStep = (currentStep) => {
    switch (currentStep) {
      case 1:
        return messagesStep1;
      case 2:
        return messagesStep2;
      case 3:
        return messagesStep3;
      default:
        return 1;
    }
  };

  useEffect(() => {
    sessionByIdMutation.mutate(sessionId);
    // eslint-disable-next-line
  }, [sessionId]);

  useEffect(() => {
    if (resMessages.length) {
      setMessagesByStep(resMessages);
      setNewMessage("");
      if (temporalLoadingRef.current) temporalLoadingRef.current = false;
      const messageAssistands = resMessages.filter(
        (e) => e?.role === "assistant" || e?.type === "ai"
      );
      if (sessionIdRef.current) {
        sessionByIdMutation.mutate(sessionIdRef.current);
      }
      if (messageAssistands.length) {
        const lastAssistand = messageAssistands[messageAssistands.length - 1];
        setMessages([...messages, { ...lastAssistand }]);
        console.log(
          `before <stage_${stepsRef.current}>`,
          `<stage_${stepsRef.current}>`
        );
        if (
          lastAssistand?.content?.includes(`<stage_${stepsRef.current}>`) ||
          lastAssistand?.data?.content.includes(`<stage_${stepsRef.current}>`)
        ) {
          console.log(
            `after <stage_${stepsRef.current}>`,
            `<stage_${stepsRef.current}>`
          );
          if (stepsRef.current === 2 && historyById) {
            const findStage2 = resMessages.find(
              (e) =>
                (e?.role === "assistant" || e?.type === "ai") &&
                (e?.content?.includes("<stage_2>") ||
                  e?.data?.content?.includes("<stage_2>"))
            );
            const contentStage2 =
              findStage2?.content || findStage2?.data?.content;

            const currentContent = contentStage2;
            setEditorContent(currentContent);
            setStep(5);
            setIsEditorOn(true);
            setStageMessage(2);
          } else {
            const nextStep =
              stepsRef.current < 3 ? stepsRef.current + 1 : stepsRef.current;
            stepsRef.current = nextStep;
            let currentContent =
              lastAssistand?.content || lastAssistand?.data?.content;
            if (nextStep === 2) {
              console.log("messages ", messages);
              const calendars =
                selectedClassProfile?.classProfileCalendars?.reduce(
                  (acc, value) => {
                    return `${acc}
                  - Dia: ${value.day} | Duracion: ${value.duration} | Comentario: ${value.comment}
                `;
                  },
                  ""
                );
              let contentUser = `
              Institución: ${selectedClassProfile?.institution?.name}
              Nivel: ${
                selectedClassProfile?.gradeLevels?.length
                  ? selectedClassProfile?.gradeLevels[0].name
                  : ""
              }
              Periodo académico: ${selectedClassProfile?.academicPeriod?.name}
              Curso: ${selectedClassProfile?.class_name}
              Materia: ${selectedSubjectArea?.name}
              }
              `;

              const currentGrade = selectedClassProfile?.gradeLevels?.length
                ? selectedClassProfile?.gradeLevels[0]
                : null;

              if (currentGrade?.name === "Pregrado") {
                contentUser = `
                ${
                  calendars
                    ? `Calendario: 
                    ${calendars}`
                    : ""
                }
                Consideraciones para este curso: ${
                  selectedClassProfile.special_considerations || "No aplica"
                }
                `;
              }
              if (currentGrade?.name === "Posgrado") {
                contentUser = `
                  Número de clases: ${selectedClassProfile.session_number}
                  Consideraciones para este curso: ${
                    selectedClassProfile.special_considerations || "No aplica"
                  }
                `;
              }

              currentContent = `ContextoClase(${contentUser})
              Resultado anterior:(
              ${lastAssistand?.content || lastAssistand?.data?.content})`;
            }
            const userMessage = { role: "user", content: currentContent };
            sessionIdRef.current = uuidv4();
            const nextUseCase = useCaseList?.find(
              (e) => e.description === `cesa_clase_step_${nextStep}`
            );
            const nextUseCaseId = nextUseCase ? nextUseCase.id : null;
            temporalLoadingRef.current = true;
            mutation.mutate({
              messages: [userMessage],
              type: `cesa_clase_${level}_step_${nextStep}`,
              parent_session_id: sessionId,
              session_id: sessionIdRef.current,
              use_case_id: nextUseCaseId,
              lang: i18n.language,
              class_profile_id: selectedClassProfile?.id,
              asset_ids: selectedAssetId ? [selectedAssetId] : [],
            });
            parentSessionIdRef.current = sessionId;
            navigate(
              `/design-class-cesa?level=${level}&sessionId=${sessionIdRef.current}`
            );
          }
        }
      }

      setLoading(false);
    }
  }, [resMessages]); //eslint-disable-line

  const getCurrentStepByUseCase = (slug) => {
    switch (slug) {
      case `cesa_clase_${level}_step_1`:
        return 1;
      case `cesa_clase_${level}_step_2`:
        return 2;
      case `cesa_clase_${level}_step_3`:
        return 2;
      default:
        return 1;
    }
  };

  const sessionByIdMutation = useMutation(getSessionById, {
    onSuccess: (data) => {
      if (data?.data) {
        if (!resMessages.length) {
          if (data.data?.classProfile) {
            setValue("courses", data.data?.classProfile);
          }
          if (data.data?.asset) {
            setSelectedAssetId(data.data.asset.id);
          }
          const currentStepMessages = [];
          const currentStep = getCurrentStepByUseCase(
            data.data.superPower.slug
          );
          stepsRef.current = currentStep;
          const historyStep1Messages = JSON.parse(data?.data?.memory_array);
          currentStepMessages.push({
            step: currentStep,
            messages: historyStep1Messages,
          });

          parentSessionIdRef.current = data?.data?.parent_session_id || null;
          if (data.history.length) {
            data.history.forEach((element) => {
              const historyStepMessages = JSON.parse(element.memory_array);
              currentStepMessages.push({
                step: getCurrentStepByUseCase(element.superPower.slug),
                messages: historyStepMessages,
              });
            });
          }
          const satinizedMessages = [];
          currentStepMessages
            .sort((a, b) => a.step - b.step)
            .forEach((e) => {
              satinizedMessages.push(...e.messages);
              setMessagesByInitialStep(e.step, e.messages);
            });
          setMessages(satinizedMessages);
          const historyStep2Messages = JSON.parse(data.data.memory_array);

          const findStage2 = historyStep2Messages.find(
            (e) =>
              (e?.role === "assistant" || e?.type === "ai") &&
              (e?.content?.includes("<stage_2>") ||
                e?.data?.content?.includes("<stage_2>"))
          );
          console.log("findStage2 ", findStage2);
          if (findStage2) {
            const historyStep1 = data?.history.find(
              (e) => e.superPower.slug === `cesa_clase_${level}_step_1`
            );
            console.log("historyStep1 ", historyStep1);
            const historyStep1Messages = JSON.parse(historyStep1.memory_array);
            setMessages([...historyStep1Messages, ...historyStep2Messages]);
            const currentContent =
              findStage2.content || findStage2?.data?.content;
            setEditorContent(currentContent);
            setStep(5);
            setIsEditorOn(true);
            setStageMessage(2);
          }
        } else {
          setHistoryById(data);
          parentSessionIdRef.current = data?.data?.parent_session_id || null;
        }
        setStep(5);
        setStageMessage(2);
      }
    },
    onError: (error) => {},
  });

  const mutation = useMutation(fetchChatGPT, {
    onSuccess: (res) => {
      resetTimer();
      setShowExtendedLoadingMessage(false);
      setLoading(false);
      setResMessages(res?.messages);
      scrollToBottom();
    },
    onError: () => {
      resetTimer();
      setShowExtendedLoadingMessage(false);
      setLoading(false);
    },
  });

  const setMessagesByStep = (currentMessages) => {
    switch (stepsRef.current) {
      case 1:
        setMessagesStep1(currentMessages);
        break;
      case 2:
        setMessagesStep2(currentMessages);
        break;
      case 3:
        setMessagesStep3(currentMessages);
        break;
      default:
        return 1;
    }
  };

  const setMessagesByInitialStep = (initialStep, currentMessages) => {
    switch (initialStep) {
      case 1:
        setMessagesStep1(currentMessages);
        break;
      case 2:
        setMessagesStep2(currentMessages);
        break;
      case 3:
        setMessagesStep3(currentMessages);
        break;
      default:
        return 1;
    }
  };

  const send = (message) => {
    startTimer();
    if (message && message.trim() !== "") {
      const payload = {
        role: "user",
        content: message === "N/A" ? "" : message,
      };
      getMessagesByStep(stepsRef.current); // analizar funcion para borrar o mantener
      setLoading(true);
      setMessages([...messages, { ...payload }]);
      setNewMessage("");

      if (!sessionId) {
        sessionIdRef.current = uuidv4();
        navigate(
          `/design-class-cesa?level=${level}&sessionId=${sessionIdRef.current}`
        );
      }
      const selectedCourse = getValues("courses");
      temporalLoadingRef.current = true;
      mutation.mutate({
        messages: [payload], //eslint-disable-line
        type: `cesa_clase_${level}_step_${stepsRef.current}`,
        session_id: sessionId || sessionIdRef.current,
        parent_session_id: parentSessionIdRef.current || null,
        use_case_id: useCaseId,
        lang: i18n.language,
        class_profile_id: selectedCourse?.id,
        asset_ids: selectedAssetId ? [selectedAssetId] : [],
      });
    }
  };

  const sendMessage = () => {
    if (newMessage && newMessage.trim() !== "") {
      send(newMessage);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey || e.ctrlKey) {
        // Permite el salto de línea con Shift+Enter o Ctrl+Enter
        return;
      } else {
        // Evita el comportamiento predeterminado de "Enter" en un textarea (añadir una nueva línea)
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const handleQuickReply = (message) => {
    send(message);
  };

  const handleLibrary = () => {
    setLibraryModal(true);
  };

  const handleLibraryClose = () => {
    setLibraryModal(false);
  };

  const onCloseLibrary = async (item) => {
    if (item) {
      const md = new Remarkable({
        html: true, // Permite HTML en el input
        breaks: true, // Convierte '\n' en <br>
        typographer: true, // Habilita algunas sustituciones tipográficas
      });
      const selectedCourse = getValues("courses");
      const parsedText = convertListMarkdown(item.content);
      let html = md.render(parsedText);
      html = cleanTableHTML(html);
      const pdfFile = await htmlToPdf("Clase CESA", fullName, "CESA", html);
      const formStoreAssetData = new FormData();
      formStoreAssetData.append("asset_file", pdfFile, "catedra.pdf");
      formStoreAssetData.append(
        "class_profile_id",
        selectedClassProfile?.id || null
      );
      formStoreAssetData.append(
        "super_power",
        `Clase_Curso_${selectedCourse.class_name.trim()}`
      );
      mutationStoreSuperPower.mutate(formStoreAssetData);
      setStep(5);
    }
    setLibraryModal(false);
  };

  const onDownloadLibraryPDF = async (item) => {
    if (item) {
      const md = new Remarkable({
        html: true,
        breaks: true,
        typographer: true,
      });
      const parsedText = convertListMarkdown(item.content);
      let html = md.render(parsedText);
      html = cleanTableHTML(html);
      await htmlToPdf(
        "Clase",
        `${user?.first_name || ""} ${user?.last_name || ""}`.trim(),
        "CESA",
        html,
        true
      );
    }
  };

  const quickReplies = [
    t("respuesta_rapida_b"),
    t("respuesta_rapida_c"),
    t("respuesta_rapida_d"),
    t("respuesta_rapida_e"),
  ];

  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    teacherSessionMutation.mutate(`cesa_clase_${level}_step_2`);
    const showTimeout = setTimeout(() => {
      setIsVisible(true);
    }, 15000); // Aparece después de 15 segundos

    const hideTimeout = setTimeout(() => {
      setIsVisible(false);
    }, 45000); // Desaparece después de 45 segundos

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
    // eslint-disable-next-line
  }, []);

  const trackBackEvent = () => {
    if (isMobile) {
      navigate("/");
    }
    setCreditAnimateClear();
    sendEvent(MIX_PANEL_EVENTS.classBackHome);
  };

  useEffect(() => {
    const addCollapsibleBehavior = () => {
      const tables = document.querySelectorAll(".chat-message-content table");

      tables.forEach((table) => {
        const thead = table.querySelector("thead");
        const tbody = table.querySelector("tbody");

        if (thead && tbody) {
          // Crear el contenedor de la tabla
          const wrapper = document.createElement("div");
          wrapper.classList.add("table-wrapper");

          // Insertar el contenedor antes de la tabla
          table.parentNode.insertBefore(wrapper, table);

          // Mover la tabla dentro del contenedor
          wrapper.appendChild(table);

          thead.classList.add("close");

          thead.addEventListener("click", () => {
            if (tbody.style.display === "none") {
              tbody.style.display = "table-row-group";
              thead.classList.remove("close");
              thead.classList.add("open");
            } else {
              tbody.style.display = "none";
              thead.classList.remove("open");
              thead.classList.add("close");
            }
          });
        }
      });
    };

    addCollapsibleBehavior();
  }, [messages]);

  const exportAsPDF = async () => {
    htmlToPdf("Clase CESA", fullName, "CESA");
    setStep(5);
    console.log(step);
  };

  const getPreOrPosGrade = (data) => {
    if (data?.superPower?.slug === "cesa_clase_pregrado_step_2")
      return "pregrado";
    if (data?.superPower?.slug === "cesa_clase_posgrado_step_2")
      return "posgrado";
  };

  const toggleView = (buttonName, data) => {
    console.log("toggleView data ", data);
    navigate(
      `/design-class-cesa?level=${getPreOrPosGrade(data)}&sessionId=${data.session_id}`
    );
    setIsView(buttonName);
    if (isActive) {
      toggleClass();
    }
  };
  const filteredMessages = messages
    ?.filter(
      (e) =>
        !conditions.some(
          (stage) =>
            e?.content?.includes(stage) || e?.data?.content.includes(stage)
        )
    )
    .filter((e) => e?.content || e?.data?.content);
  let titleMain = t("classText2");
  if (level === "pregrado") titleMain = t("classText2Pregrado");
  if (level === "posgrado") titleMain = t("classText2Posgrado");
  return (
    <>
      {!isMobile && (
        <div className="back-button">
          <Link to={"/"} className="back-button_arrow" onClick={trackBackEvent}>
            <img src={ArrowBack} className="" alt="" />
            {t("inicio")}
          </Link>
          <span className="back_button_root">{t("classText1")} /</span>
          <span className="back_button_page">{titleMain}</span>
        </div>
      )}
      <div className="wrapper editor">
        {/* Historial */}
        <div
          className={`sidebar_history !h-auto max-h-desktopWrapper xl:max-w-96 xl:min-w-80 ${
            isActive ? "active" : ""
          }`}
        >
          <h6 className="blue-text sidebar-title">{t("historialTitulo")}</h6>
          {history.length ? (
            <UseCaseHistory
              history={history}
              sessionId={null}
              isView={isView}
              toggleView={toggleView} //eslint-disable-line
            />
          ) : (
            <>
              <div className="history_empty">
                <img src={HistoryEmpty} alt="empty" />
                <p className="blue-text">{t("historialTituloVacio")}</p>
              </div>
            </>
          )}
          <Button
            label="Cerrar"
            variant="primary-outline"
            onClick={toggleClass}
          />
        </div>
        {/* Editor */}
        {isEditorOn && (
          <div className="editor_board h-full">
            <DefaultEditor editorContent={editorContent} />
          </div>
        )}
        {/* Chat */}
        <div
          className={`h-full w-full sm-max:max-h-70/100 flex flex-col justify-end md:gap-4 chat-sticky ${
            isChatActive ? "active" : null
          } ${isEditorOn && "xl:max-w-100"}`}
        >
          <div
            ref={chatContainerRef}
            className={`flex flex-col flex-grow justify-end h-0 overflow-y-auto relative sm-max:px-0 sm-max:pt-0 sm-max:pb-4 sm-max:bg-transparent ${
              isEditorOn && "px-1 pt-1 pb-3 "
            }`}
          >
            <div className="chat_wrapper-scroll pr-2">
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className="h-auto">
                  <div className="chat_conversation-thread-ai">
                    <div className="bubble-wrapper max-w-full">
                      <div className="chat_bubble-ai ">
                        <div
                          className={`bubble-message ${
                            isEditorOn ? "xl:px-2" : "xl:px-10"
                          }`}
                        >
                          <p>
                            {" "}
                            {t("interaccion_chat_Shaia_step_1_a_Clase")}
                            <br />
                            <br />
                            {t("interaccion_chat_Shaia_step_1_b_Clase")}
                          </p>
                        </div>
                        <CourseSelection
                          updateStep={updateStep}
                          disabled={stageMessage === 2}
                          api={getAvailableClassProfiles}
                        />
                      </div>
                    </div>
                    <div className="chat_avatar-ai items-start !bg-Grey-500/20">
                      <img src={ShaiaCreate} alt="Sha.ia" />
                    </div>
                  </div>

                  {step >= 2 && (
                    <div className={`chat_conversation-thread-ai pl-12`}>
                      <div className="bubble-wrapper max-w-full">
                        <div className="chat_bubble-ai ">
                          <div
                            className={`bubble-message ${
                              isEditorOn ? "xl:px-2" : "xl:px-10"
                            }`}
                          >
                            <p>{t("interaccion_chat_Shaia_step_2_Clase")}</p>
                          </div>
                          <SubjectAreaSelection
                            updateStep={updateStep}
                            disabled={stageMessage === 2}
                            data={selectedClassProfile?.subjectAreas}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {step >= 3 && (
                    <div className="chat_conversation-thread-ai pl-12">
                      <div className="bubble-wrapper max-w-full">
                        <div className="chat_bubble-ai ">
                          <div
                            className={`bubble-message ${
                              isEditorOn ? "xl:px-2" : "xl:px-10"
                            }`}
                          >
                            <p>{t("interaccion_chat_Shaia_step_3_a_Clase")}</p>
                          </div>
                          <FileClassCesaUpload
                            setFile={setFile}
                            updateStep={updateStep}
                            fileInputRef={fileInputRef}
                            disabled={stageMessage === 2}
                            handleCatedra={transformCatedraContentToPDF}
                            hasCatedraContent={!!currentCatedraContent}
                            handleLibrary={handleLibrary}
                            customClassName={
                              isEditorOn ? "xl:px-2" : "xl:px-10"
                            }
                          />
                          <div
                            className={`bubble-message mt-4 ${
                              isEditorOn ? "xl:px-2" : "xl:px-10"
                            }`}
                          >
                            <p>{t("interaccion_chat_Shaia_step_3_b_Clase")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </FormProvider>

              {step >= 4 && file && (
                <div
                  className={classNames("chat_conversation-thread-user", {
                    "pointer-events-none": stageMessage === 2,
                    "opacity-50": stageMessage === 2,
                  })}
                >
                  <div className="bubble-wrapper">
                    <div className="chat_bubble-user">
                      <div
                        className={`bubble-message ${
                          isEditorOn ? "xl:px-2" : "xl:px-10"
                        }`}
                      >
                        <div className="flex items-center gap-2 bg-DarkBlue-700/50 py-2 px-4 rounded-2">
                          <span className="w-8 h-8">
                            <img
                              src={getFileIcon(file.name)}
                              alt="Archivo"
                              className="max-w-full"
                            />
                          </span>
                          <span className="text-Grey-500 text-4 leading-4">
                            {file.name}
                          </span>
                        </div>
                        <div className="flex flex-wrap sm-max:flex-col gap-4 mt-4">
                          <button
                            onClick={handleRemoveFileClick}
                            className="button button-tertiary-outline !h-10 !text-3.5"
                          >
                            {t("interaccion_chat_Shaia_step_4_boton_1")}
                          </button>
                          <button
                            onClick={proceedToNextStep}
                            className="button button-primary !h-10 !text-3.5"
                          >
                            {t("interaccion_chat_Shaia_step_4_boton_2")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat_avatar-user">
                    <img src={UserIcon} alt="Tú" />
                  </div>
                </div>
              )}

              {step >= 5 && (
                <>
                  <div className="chat_conversation-thread-ai">
                    <div className="bubble-wrapper">
                      <div className="chat_bubble-ai">
                        <div
                          className={`bubble-message ${
                            isEditorOn ? "xl:px-2" : "xl:px-10"
                          }`}
                        >
                          <p>
                            {t("interaccion_chat_Shaia_step_5_a_Clase")}
                            <br /> {t("interaccion_chat_Shaia_step_5_b_Clase")}
                          </p>
                        </div>
                        {stageMessage !== 2 && (
                          <div
                            className={`flex flex-row gap-2 items-center ${
                              isEditorOn ? "xl:px-2" : "xl:px-10"
                            }`}
                          >
                            <svg
                              aria-hidden="true"
                              className="w-6 h-6 text-Grey-200 animate-spin dark:text-Grey-600 fill-Yellow-500"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="text-Yellow-500 font-semibold">
                              {t(
                                "interaccion_chat_Shaia_step_analizando_Clase"
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="chat_avatar-ai items-start !bg-Grey-500/20">
                      <img src={ShaiaCreate} alt="Sha.ia" />
                    </div>
                  </div>
                  {/* Chat dinámico con la respuesta AI */}
                  {filteredMessages?.map((message, i) => {
                    return (
                      <div
                        key={i}
                        className={`max-w-full chat_conversation-thread-${
                          message?.role === "user" || message?.type === "human"
                            ? "user"
                            : "ai"
                        }`}
                      >
                        <div className={classNames("bubble-wrapper")}>
                          <div
                            className={`chat_bubble-${
                              message?.role === "user" ||
                              message?.type === "human"
                                ? "user"
                                : "ai"
                            }`}
                          >
                            <div
                              className={`bubble-message ${
                                isEditorOn ? "xl:px-2" : "xl:px-10"
                              }`}
                            >
                              {["user", "assistant", "ai", "human"].includes(
                                message?.role || message?.type
                              ) && (
                                <div
                                  className="chat-message-content"
                                  dangerouslySetInnerHTML={createMarkup(
                                    message?.content || message?.data?.content
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`chat_avatar-${
                            message?.role === "user" ||
                            message?.type === "human"
                              ? "user p-1"
                              : "ai items-start"
                          }`}
                        >
                          {(message?.role === "user" ||
                            message?.type === "human") && (
                            <img src={UserIcon} alt="Tú" />
                          )}
                          {(message?.role === "assistant" ||
                            message?.type === "ai") && (
                            <img src={ShaiaCreate} alt="Sha.ia" />
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {(loading || temporalLoadingRef.current) && (
                    <div className="chat_conversation-thread-ai">
                      <div className="bubble-wrapper">
                        <div className="chat_bubble-ai">
                          <p className="bubble-message chat-typing">
                            {t("chat_mensaje_escribiendo")}
                            {!showExtendedLoadingMessage && (
                              <>
                                <span>.</span> <span>.</span> <span>.</span>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="chat_avatar-ai items-start">
                        <img src={ShaiaCreate} alt="Sha.ia" />
                      </div>
                    </div>
                  )}
                  {(loading || temporalLoadingRef.current) &&
                    showExtendedLoadingMessage && (
                      <div className="chat_conversation-thread-ai">
                        <div className="bubble-wrapper">
                          <div className="chat_bubble-ai">
                            <p className="bubble-message chat-typing">
                              {t("chat_mensaje_espera")}
                              <span>.</span> <span>.</span> <span>.</span>
                            </p>
                          </div>
                        </div>
                        <div className="chat_avatar-ai items-start">
                          <img src={ShaiaCreate} alt="Sha.ia" />
                        </div>
                      </div>
                    )}
                </>
              )}
              {/* (Simulación) Respuesta Shaia a petición de PDF */}
              {step >= 6 && (
                <div className="chat_conversation-thread-ai">
                  <div className="bubble-wrapper">
                    <div className="chat_bubble-ai">
                      <div className="bubble-message">
                        <p>
                          {t("interaccion_chat_Shaia_burbuja_PDF_texto1")}
                          <br />
                          <br />
                          {t("interaccion_chat_Shaia_burbuja_PDF_texto2")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="chat_avatar-ai items-start !bg-Grey-500/20">
                    <img src={ShaiaCreate} alt="Sha.ia" />
                  </div>
                </div>
              )}
            </div>
            {/**Modal**/}
            <ConfirmationModal
              isOpen={isModalOpen}
              onClose={closeModal}
              onConfirm={confirmRemoveFile}
            />
          </div>
          {/* Input message */}
          {step >= 5 && !isEditorOn && (
            <div className="flex flex-col gap-4 sm-max:gap-2 w-full">
              <div
                className={`w-full min-h-16 max-h-25 bg-DarkBlue-700/90 rounded-4 sm-max:rounded-4 py-2 px-4 flex flex-row gap-2 flex-grow`}
              >
                <textarea
                  className="max-h-20 bg-white/13 text-Grey-500 font-semibold rounded-1 shadow flex-col justify-center items-center inline-flex placeholder:text-Grey-800 w-full p-2 text-4"
                  placeholder={`${t("abp_chat_placeholder")} ...`}
                  value={newMessage}
                  disabled={loading}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <Button
                  label={
                    loading || temporalLoadingRef.current ? (
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-Grey-200 animate-spin dark:text-Grey-600 fill-DarkBlue-300"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      t("abp_boton_enviar")
                    )
                  }
                  variant="primary"
                  disabled={loading}
                  onClick={sendMessage}
                  customClassName="w-30"
                />
              </div>
              {false && (
                <div className="w-full overflow-x-auto sm-max:mt-2">
                  <div className="flex flex-row gap-2 items-center justify-end min-w-max">
                    <p className="h-full sm-max:flex-1 sm-max:min-w-40 flex items-center justify-center text-LightBlue-500 font-bold m-0">
                      Respuestas rápidas:
                    </p>
                    {quickReplies.map((reply, index) => (
                      <button
                        key={index}
                        className="sm-max:flex-1 sm-max:min-w-40 py-1 px-2 rounded-full border border-solid border-LightBlue-500 bg-LightBlue-500/50 h-8 text-3.5 leading-3.5 text-Grey-500"
                        disabled={loading}
                        onClick={() => handleQuickReply(reply)}
                      >
                        {reply}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {isEditorOn && (
            <div
              className={`w-full max-h-16 bg-DarkBlue-700/90 rounded-4 sm-max:rounded-4 py-2 px-4 flex flex-row gap-2 flex-grow`}
            >
              <div className="w-full flex flex-row gap-2 items-center justify-end min-w-max">
                <button
                  className="sm-max:flex-1 sm-max:min-w-40 py-1 px-4 rounded-full border border-solid border-LightBlue-500 bg-LightBlue-500/50 h-8 text-3.5 leading-3.5 text-Grey-500"
                  disabled={loading}
                  onClick={() => exportAsPDF()}
                >
                  {t("interaccion_chat_Shaia_burbuja_boton_PDF_texto_clase")}
                </button>
              </div>
            </div>
          )}
          <div className="md:hidden mt-6 w-full flex items-center justify-center">
            <Button
              label="Cerrar"
              variant="primary-outline"
              onClick={hiddenChat}
            />
          </div>
        </div>
        {/* Mobile Shaia message */}
        {isVisible && (
          <div className="flex flex-col items-center justify-center mt-2 mb-4 md:hidden fixed left-0 right-0 mx-auto px-2 bottom-12">
            <div className="bg-DarkBlue-700/90 backdrop-blur-md backdrop-saturate-200 p-4 flex flex-row gap-4 rounded-4">
              <div className="chat_avatar-ai items-start !bg-Grey-500/20">
                <img src={ShaiaCreate} alt="Sha.ia" />
              </div>
              <div className="w-full">
                <p className="text-Grey-500 m-0 leading-4.5">
                  <strong>
                    {t("interaccion_chat_Shaia_mobile_burbuja_texto1")}
                  </strong>
                  <br />
                  {t("interaccion_chat_Shaia_mobile_burbuja_texto2")}
                </p>
              </div>
            </div>
            <span className="w-0 h-0 border-t-8 border-DarkBlue-700/90 border-l-8 border-l-transparent border-r-8 border-r-transparent"></span>
          </div>
        )}
      </div>
      {/* Bottom navbar */}
      {isMobile && (
        <div className="fixed z-sticky bottom-0 left-0 w-screen h-16 bg-DarkBlue-700 px-4 flex flex-row gap-4 items-center justify-between rounded-tl-4 rounded-tr-4">
          <Icon
            icon={ArrowBack}
            iconText={t("regresar")}
            customClassName="gap-1 py-0 w-19"
            onClick={trackBackEvent}
            disabledToolTip={true}
          />
          <Icon
            icon={ChatIcon}
            iconText={"Chat"}
            customClassName="gap-1 py-0 w-19"
            onClick={showChat}
            disabledToolTip={true}
          />
          {!gptSuccess && (
            <Icon
              icon={History}
              iconText={"Historial"}
              customClassName="gap-1 w-19"
              onClick={toggleClass}
              disabledToolTip={true}
            />
          )}
        </div>
      )}
      <Popup
        isOpen={libraryModal}
        onClose={handleLibraryClose}
        customClassName="!max-w-[95%] !h-[95%] !w-full"
      >
        <ClassProfieLibrary
          data={sessionsByClassProfile}
          onClose={onCloseLibrary}
          onDownload={onDownloadLibraryPDF}
          description={t("biblioteca_description")}
        />
      </Popup>
    </>
  );
};

export default DesignCESAClass;
