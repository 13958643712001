import classNames from "classnames";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from 'react-i18next';

const FileClassCesaUpload = ({
  setFile,
  updateStep,
  fileInputRef,
  disabled,
  handleCatedra,
  handleLibrary,
  hasCatedraContent,
  customClassName
}) => {
  const { control, setValue } = useFormContext();
  const {t} = useTranslation()
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setValue("file", [file]);
      updateStep(4);
    }
  };

  return (
    <div
      className={classNames("flex flex-row sm-max:flex-col gap-4 xl:px-10", customClassName, {
        "pointer-events-none": disabled,
        "opacity-50": disabled,
      })}
    >
      <Controller
        name="file"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <label className="button button-primary relative text-center !text-3.5">
            {t("chat_cargar_desde_mi_dispositivo")}
            <input
              className="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
              type="file"
              ref={fileInputRef}
              onChange={(e) => {
                field.onChange(e.target.files);
                handleFileChange(e);
              }}
            />
          </label>
        )}
      />
      <button
        type="button"
        className="button button-tertiary !text-3.5"
        onClick={() => handleLibrary()}
      >
        {t("chat_boton_buscar_biblioteca")}
      </button>
      {/* {hasCatedraContent && (
        <button
          type="button"
          className="button button-primary !text-3.5"
          onClick={() => handleCatedra()}
        >
          Usar Guia de Catedra de mi Perfil de curso
        </button>
      )} */}
    </div>
  );
};

export default FileClassCesaUpload;
