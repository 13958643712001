import { create } from 'zustand';

const useSnackbarStore = create((set) => ({
    isVisible: false,
    message: 'Example',
    type: 'success',
    duration: 6000,
    show: (message, type = 'success', duration=6000) => set({ isVisible: true, message, type, duration }),
    hide: () => set({ isVisible: false }),
    setDuration: () => set({ isVisible: false })
}));

export default useSnackbarStore;