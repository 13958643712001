import React, { useState } from "react";
import CloudUpload from "../../assets/icons/cloud_upload.svg";
import Trash from "../../assets/icons/trash.svg";
import UploadFile from "../../assets/icons/upload_file.svg";
import { useTranslation } from "react-i18next";
import Button from "../button/button";

const FileUploader = ({ onFileSelect, onClose }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { t } = useTranslation();

  // Simulando una carga de archivo con progreso y actualización del estado.
  const [uploadProgress, setUploadProgress] = useState({});
  // Reemplazar esto con lógica lógica de carga.
  const simulateFileUpload = (file) => {
    const fileSize = file.size;
    let progress = 0;
    const interval = setInterval(() => {
      progress += fileSize / 10; // Asumiendo que la carga incrementa 10% cada intervalo
      if (progress < fileSize) {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: (progress / fileSize) * 100, // Porcentaje de progreso.
          progress : (progress / fileSize) * 100
        }));
      } else {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: 100,
          progress: 100
        }));
        clearInterval(interval);
      }
    }, 500); // Actualiza cada medio segundo
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necesario para permitir el drop
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    const allFiles = selectedFiles.concat(newFiles); // Concatena los nuevos archivos con los existentes
    setSelectedFiles(allFiles);
    if (typeof onFileSelect === "function") {
      onFileSelect(allFiles);
    }
    newFiles.forEach((file) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: 0, // Iniciar progreso en 0%
      }));
      simulateFileUpload(file); // Llamar a la función que simula la carga
    });
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.target.files);
    const allFiles = selectedFiles.concat(newFiles); // Concatena los nuevos archivos con los existentes
    
    if (typeof onFileSelect === "function") {
      setSelectedFiles(allFiles);
    }
    newFiles.forEach((file) => {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: 0, // Iniciar progreso en 0%
      }));
      simulateFileUpload(file); // Llamar a la función que simula la carga
    });
  };

  const handleFileRemove = (fileToRemove) => {
    setSelectedFiles(selectedFiles.filter((file) => file !== fileToRemove));
  };

    const close = (e) => {
      e.preventDefault()
      onFileSelect(selectedFiles);
      setSelectedFiles([])
      onClose()
    }
  return (
    <div className="flex flex-col gap-4 p-4 items-center justify-center">
      <div
        className="p-4 border-2 border-dashed border-LightBlue-500/50 rounded-md cursor-pointer flex flex-col items-center justify-center gap-1"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          className="hidden"
          id="file-upload"
          multiple
          onChange={handleFileChange}
        />
        <label
          htmlFor="file-upload"
          className="flex flex-col items-center justify-center cursor-pointer"
        >
          <span className="w-8 h-6">
            <img src={CloudUpload} alt="empty" className="max-w-full" />
          </span>
          <span className="input-label text-center">
            {t("carga_archivo_elegir_archivo")}
          </span>
          <span className="input-instruction text-center">
            {t("carga_archivo_formato")}
          </span>
        </label>
        <label
          htmlFor="file-upload"
          className="button-tertiary-rounded !h-auto p-4"
        >
          {t("carga_archivo_buscar")}
        </label>
      </div>
      <ul className="w-full flex flex-col gap-1">
        {selectedFiles.map((file, index) => (
          <li key={index} className="flex justify-between items-start p-4 gap-2 bg-DarkBlue-800 rounded-2 w-full">
            <div className='flex flex-col flex-1 gap-2 max-w-[100%-32px]'>
              <div className="flex items-center">
                <span className="w-8 min-w-8 h-8 mr-2"><img src={UploadFile} alt="Archivo" className="max-w-full" /></span>
                <div className="flex flex-col gap-2">
                  <span className="ml-2 text-Grey-500 text-4 leading-4.5 text-ellipsis overflow-hidden">{file.name}</span>
                  {/* Agregar lógica para mostrar el tamaño del archivo */}
                  <span className="ml-2 text-Grey-500/30 text-3 leading-3">
                    {file.size} bytes |{" "}
                    {uploadProgress[file.name] < 100
                      ? t("carga_archivo_cargando")
                      : t("carga_archivo_completado")}
                  </span>
                </div>
              </div>
              {uploadProgress[file.name] < 100 && (
                <div className="w-full mt-2">
                  <div className="w-full bg-Grey-200 rounded-full overflow-hidden dark:bg-Grey-700">
                    <div
                      className="bg-LightBlue-600 h-1.5 rounded-full"
                      style={{ width: `${uploadProgress[file.name] || 0}%` }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={() => handleFileRemove(file)}
              className="w-6 flex items-center justify-center"
            >
              <span className="w-6 h-6">
                <img src={Trash} alt="Eliminar" className="max-w-full" />
              </span>
            </button>
          </li>
        ))}
      </ul>
      {
        selectedFiles.length ? (
           <Button
            onClick={close}
            label={t("boton_carga_archivos_modal")}
            variant="primary"
            disabled={ uploadProgress?.progress < 100}
            customClassName="mt-4"
          />
        ) : null
      }
    </div>
  );
};

export default FileUploader;
