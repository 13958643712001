import client from "../config/axios";

export const authRegister = async (data) => {
  const response = await client.post("/auth/register", { ...data });
  return response.data;
};

export const authLogin = async (data) => {
  const response = await client.post("/auth/login", { ...data });
  return response.data;
};

export const institutionRegister = async (data) => {
  const response = await client.post("/auth/institution-register", { ...data });
  return response.data;
};

export const getInstitutionByLicense = async (code) => {
  const response = await client.get(`/institution/license/${code}`);
  return response.data;
};

export const authLoginSocialMedia = async (data) => {
  const response = await client.post("/auth/login-social-media", { ...data });
  return response.data;
};

export const updateProfile = async ({ id, payload }) => {
  const response = await client.post(`/auth/profile/${id}`, { ...payload });
  return response.data;
};

export const checkUser = async () => {
  const response = await client.get("/auth/check");
  return response.data;
};

export const forgotPassword = async (email) => {
  const response = await client.get(`/auth/forgot-password/${email}`);
  return response.data;
};

export const updatePassword = async ({password, token}) => {
  const data = { token, password };
  const response = await client.post("/auth/update-password", {
    ...data,
  });
  return response.data;
};

export const updateRegister = async (data) => {
  const response = await client.post("/auth/update-register", { ...data });
  return response.data;
};

export const updateInstitutionPersonRegister = async (data) => {
  const response = await client.post("/auth/update-institution-register", { ...data });
  return response.data;
};

export const confirmEmail = async (email) => {
  const response = await client.get(`/auth/confirm-email/${email}`);
  return response.data;
};