import client from "../config/axios";

export const getAllProfileClass = async ({ page = 1, name = "" }) => {
  const response = await client.get("/class-profile", {
    params: { page, name },
  });
  return response.data;
};

export const storeClassProfile = async (data) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const response = await client.post("/class-profile", data, config);
  return response.data;
};

export const updateClassProfile = async ({id, body}) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  };
  const response = await client.patch(`/class-profile/${id}`,body, config);
  return response.data;
}

export const deleteClassProfile = async (id) => {
  const response = await client.delete(`/class-profile/${id}`);
  return response.data;
};

export const getAllProfileClass2 = async ({ email }) => {
  const response = await client.get(`/class-profile/teacher/${email}`);
  return response.data;
};

export const getClassProfileById = async ({ id }) => {
  const response = await client.get(`/class-profile/${id}`);
  return response.data;
};

export const getAvailableAssetsByUser = async ({ email }) => {
  const response = await client.get(`/class-profile/available-assets/${email}`);
  return response.data;
};

export const getAvailableClassProfileToIndexDocuments = async ({ email }) => {
  const response = await client.get(`/class-profile/available-to-index-documents/${email}`);
  return response.data;
};

export const getAvailableClassProfiles = async ({ email, level }) => {
  const response = await client.get(`/class-profile/available-courses/${email}`,{
    params: {
      level
    }
  });
  return response.data;
};

export const getsessionByClassProfile = async ({ email, classProfileId, superPower }) => {
  const response = await client.get("/class-profile/session/by-course",{
    params: {
      email,
      classProfileId,
      superPower
    }
  });
  return response.data;
};

export const getsessionsByClassProfile = async ({ email, classProfileId, superPower }) => {
  const response = await client.get("/class-profile/sessions/by-course",{
    params: {
      email,
      classProfileId,
      superPower
    }
  });
  return response.data;
};